exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Properties_propertiesForListView__2XCdU {\n  display: -webkit-flex;\n  display: flex;\n  position: absolute;\n  position: initial;\n  padding-top: 10px; }\n  .Properties_propertiesForListView__2XCdU div {\n    width: 35px;\n    height: 20px;\n    text-align: center; }\n    .Properties_propertiesForListView__2XCdU div div {\n      border: none;\n      margin-top: -10px;\n      background-size: contain;\n      background-position: center;\n      background-repeat: no-repeat; }\n\n.Properties_propertiesLabelListView1__56Rlq {\n  position: relative;\n  bottom: -15px;\n  font-size: 0.5rem;\n  color: black;\n  white-space: nowrap; }\n\n.Properties_propertiesLabelListView2__32daJ {\n  position: relative;\n  top: -5px;\n  font-size: 0.5rem;\n  color: black;\n  white-space: nowrap; }\n", ""]);

// exports
exports.locals = {
	"propertiesForListView": "Properties_propertiesForListView__2XCdU",
	"propertiesLabelListView1": "Properties_propertiesLabelListView1__56Rlq",
	"propertiesLabelListView2": "Properties_propertiesLabelListView2__32daJ"
};