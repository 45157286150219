import { SSE_CONNECTION_INIT,
  SSE_CONNECTION_SUCCESS,
  SSE_CONNECTION_ERROR,
  SSE_CONNECTION_CLOSED } from '../actions/eventSource';

const initialState = {
  connected: false,
  readyState: null,
  evtSource: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SSE_CONNECTION_INIT:
      return Object.assign({}, state, {
        connected: false,
        evtSource: action.evtSource,
      });

    case SSE_CONNECTION_SUCCESS:
      return Object.assign({}, state, {
        connected: true,
      });

    case SSE_CONNECTION_ERROR:
      return Object.assign({}, state, {
        connected: false,
      });

    case SSE_CONNECTION_CLOSED:
      return Object.assign({}, state, {
        connected: false,
        //evtSource: null,
      });

    default:
      return state;
  }
}