import { getListOfOrdersForDayForRestaurant, updateOrderStatusProperty, countOrdersInPoSForSpecificDate } from '../api/order.js';

export const ORDERS_REQUEST = 'ORDERS_REQUEST';
export const ORDERS_SUCCESS = 'ORDERS_SUCCESS';
export const ORDERS_FAILURE = 'ORDERS_FAILURE';
export const COUNT_ORDERS_IN_POS_REQUEST = 'COUNT_ORDERS_IN_POS_REQUEST';
export const COUNT_ORDERS_IN_POS_SUCCESS = 'COUNT_ORDERS_IN_POS_SUCCESS';
export const COUNT_ORDERS_IN_POS_FAILURE = 'COUNT_ORDERS_IN_POS_FAILURE';
export const UPDATE_ORDER_STATUS_REQUEST = 'UPDATE_ORDER_STATUS_REQUEST';
export const UPDATE_ORDER_STATUS_SUCCESS = 'UPDATE_ORDER_STATUS_SUCCESS';
export const UPDATE_ORDER_STATUS_FAILURE = 'UPDATE_ORDER_STATUS_FAILURE';

function requestOrders() {
  return {
    type: ORDERS_REQUEST,
    isFetching: true
  };
}

function receiveOrders(restaurantWithOrders) {
  return {
    type: ORDERS_SUCCESS,
    isFetching: false,
    restaurantWithOrders
  };
}

function requestCountOrdersInPoS() {
  return {
    type: COUNT_ORDERS_IN_POS_REQUEST,
    isFetching: true
  };
}

function countOrdersInPoSSuccess(totalNumberOfOrdersInPoS) {
  return {
    type: COUNT_ORDERS_IN_POS_SUCCESS,
    isFetching: false,
    totalNumberOfOrdersInPoS
  };
}

function countOrdersInPoSStatusError(message) {
  return {
    type: COUNT_ORDERS_IN_POS_FAILURE,
    isFetching: false,
    message,
  };
}

function ordersError(message) {
  return {
    type: ORDERS_FAILURE,
    isFetching: false,
    message,
  };
}

function requestUpdateOrderStatus() {
  return {
    type: UPDATE_ORDER_STATUS_REQUEST,
    isFetching: true
  };
}

function receiveUpdateOrderStatus(updatedOrder) {
  return {
    type: UPDATE_ORDER_STATUS_SUCCESS,
    isFetching: false,
    updatedOrder
  };
}

function updateOrderStatusError(message) {
  return {
    type: UPDATE_ORDER_STATUS_FAILURE,
    isFetching: false,
    message,
  };
}


// Get a list of orders for a specific restaurant on a specific day
export function getOrdersFromServer(restaurantId, day, restaurantTimezone) {
  return (dispatch) => {
    dispatch(requestOrders());
    const token = localStorage.getItem('id_token');
    getListOfOrdersForDayForRestaurant(token, restaurantId, day, restaurantTimezone)
        .then(restaurantWithOrders => {
          //console.log('in getOrdersFromServer restaurantWithOrders  => ' + JSON.stringify(restaurantWithOrders));

          if(restaurantWithOrders.errors) return dispatch(ordersError(restaurantWithOrders.errors[0].message));

          dispatch(receiveOrders(restaurantWithOrders));
        })
        .catch(error => {
          //console.log('An error occurred inLoginUser: ', error.response.data.message);
          if (error.response && error.response.data) {
            return dispatch(ordersError(error.response.data.message));
          }
          return dispatch(ordersError(error.toString()));
        })
  };
}


// Counts number of orders in PoS for a specific restaurant on a specific day
export function getTotalNumberOfOrdersInPoSFromServer(restaurantId, day, restaurantTimezone) {
  return (dispatch) => {
    dispatch(requestCountOrdersInPoS());
    const token = localStorage.getItem('id_token');
    countOrdersInPoSForSpecificDate(token, restaurantId, day, restaurantTimezone)
        .then(totalNumberOfOrdersInPoS => {
          //console.log('in getOrdersFromServer restaurantWithOrders  => ' + JSON.stringify(restaurantWithOrders));

          if(totalNumberOfOrdersInPoS.errors) return dispatch(countOrdersInPoSStatusError(totalNumberOfOrdersInPoS.errors[0].message));

          dispatch(countOrdersInPoSSuccess(totalNumberOfOrdersInPoS));
        })
        .catch(error => {
          //console.log('An error occurred inLoginUser: ', error.response.data.message);
          if (error.response && error.response.data) {
            return dispatch(ordersError(error.response.data.message));
          }
          return dispatch(ordersError(error.toString()));
        })
  };
}

// Updates a menu item visibility in a menu for a restaurant
export function updateOrderStatus(order, previousOrderStatus) {
  return (dispatch) => {
    dispatch(requestUpdateOrderStatus());
    const token = localStorage.getItem('id_token');
    updateOrderStatusProperty(token, order, previousOrderStatus)
        .then(updatedOrder => {
          //console.log('in updateOrderStatus updatedOrder  => ' + JSON.stringify(updatedOrder));
          //localStorage.setItem('arrayOfMenus', arrayOfMenus);
          // Dispatch the success action with fake user
          dispatch(receiveUpdateOrderStatus(updatedOrder));
        })
        .catch(error => {
          //console.log('An error occurred inLoginUser: ', error.response.data.message);
          if (error.response && error.response.data) {
            return dispatch(updateOrderStatusError(error.response.data.message));
          }
          return dispatch(updateOrderStatusError(error.toString()));
        })
  };
}
