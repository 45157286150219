//import React from 'react';

//import s from './ToastMessenger.module.scss';
/* eslint-disable */
import 'imports-loader?$=jquery,this=>window!messenger/build/js/messenger';
/* eslint-enable */

const Messenger = window.Messenger;

/* eslint-disable */
function initializationMessengerCode() {
  (function () {
    let $,
      FlatMessage,
      spinner_template,
      __hasProp = {}.hasOwnProperty,
      __extends = function (child, parent) {
        for (const key in parent) {
          if (__hasProp.call(parent, key)) child[key] = parent[key];
        }

        function ctor() {
          this.constructor = child;
        }

        ctor.prototype = parent.prototype;
        child.prototype = new ctor();
        child.__super__ = parent.prototype;
        return child;
      };

    $ = jQuery;

    spinner_template = '<div class="messenger-spinner">\n    <span class="messenger-spinner-side messenger-spinner-side-left">\n        <span class="messenger-spinner-fill"></span>\n    </span>\n    <span class="messenger-spinner-side messenger-spinner-side-right">\n        <span class="messenger-spinner-fill"></span>\n    </span>\n</div>';

    FlatMessage = (function (_super) {
      __extends(FlatMessage, _super);

      function FlatMessage() {
        return FlatMessage.__super__.constructor.apply(this, arguments);
      }

      FlatMessage.prototype.template = function (opts) {
        let $message;
        $message = FlatMessage.__super__.template.apply(this, arguments);
        $message.append($(spinner_template));
        return $message;
      };

      return FlatMessage;
    }(Messenger.Message));

    Messenger.themes.air = {
      Message: FlatMessage,
    };
  }).call(window);
}
/* eslint-enable */

export function showSuccessMessage(message) {
  initializationMessengerCode();
  Messenger.options = {
    extraClasses: 'messenger-fixed messenger-on-top messenger-on-center',
    theme: 'air',
  };
  Messenger().post({
    message: message,
    type: 'success'
  });
}

export function showErrorMessage(message) {
  initializationMessengerCode();
  Messenger.options = {
    extraClasses: 'messenger-fixed messenger-on-top messenger-on-center',
    theme: 'air',
  };
  Messenger().post({
    message: message,
    type: 'error'
  });
}
