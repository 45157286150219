import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import ModalMenuOption from '../MobileMenuOption/MobileMenuOption';

import {
  Button
} from 'reactstrap';

import closeImg from '../../../../images/cancel.svg';
import backImg from '../../../../images/back.svg';

import s from './MobileModal.module.scss';

class MobileModal extends Component {
  state = {
    data: this.props.data,
    isPages: typeof this.props.data.data[0] !== 'string',
    isPageOpened: false,
    activePageId: 0,
    activeOptions: {},
  }

  toggleOptionActive(field, selectedIndex, selectedValue) {
    const newActiveOption = {
      ...this.state.activeOptions,
      [field]: {selectedIndex, selectedValue},
    };

    //console.log('newActiveOption => ', newActiveOption);

    this.setState({ activeOptions: newActiveOption });

    // field is the filtername and selectedValue in the currentOption
    this.props.onSelectedValue(field, selectedValue);
  }

  componentDidUpdate(prevProps, prevState) {

    // if we received new data set then we update the data state
    if (this.props.data !== prevState.data) {
      this.setState({
        data: this.props.data
      });
    }

  }

  resetFilter(event, label) {
    event.stopPropagation();
    //console.log('reset filter');
    const activeOptionsTemp = this.state.activeOptions;
    //console.log('activeOptionsTemp before => ', activeOptionsTemp);
    // delete the filter property to reset to no filter value selected
    delete activeOptionsTemp[label];
    //console.log('activeOptionsTemp after => ', activeOptionsTemp);
    this.setState({ activeOptions: activeOptionsTemp });
    // field is the filtername and selectedValue in the currentOption
    this.props.onSelectedValue(label, null);
  }

  handleBackClick = () => {
    this.setState({ activePageId: null, isPageOpened: false });
  }

  handleCloseClick = () => {
    this.setState({ activePageId: null, isPageOpened: false });

    this.props.close();
  }

  openPage(event, index, label) {
    const {activeOptions} = this.state;
    //console.log('openPage activeOptions => ', activeOptions);
    // if there is already a filter value selected we stop the page opening
    // and we remove the filter from the page so we can select a new filter value
    if(activeOptions[label] && activeOptions[label].selectedIndex !== 0){
      event.stopPropagation();
    } else {
      this.setState({ activePageId: index, isPageOpened: true });
    }
  }

  render() {
    const { active } = this.props;
    const { activePageId, isPages, isPageOpened, data: { data, title }, activeOptions } = this.state;
    const openedPage = isPageOpened && data[activePageId];
    const renderedTitle = openedPage ? openedPage.label : title;
    return (
      <div className={cx(s.mobileModal, { [s.mobileModalActive]: active })}>
        <div className={s.mobileModalTitle}>
          <button onClick={openedPage ? this.handleBackClick : this.handleCloseClick}>
            <img className={cx({ back: openedPage })} src={openedPage ? backImg : closeImg} alt="close" />
          </button>
          <h5>{renderedTitle}</h5>
        </div>
        <ul className={s.mobileModalBody}>
          {/* eslint-disable */}
          {isPages
            ? !isPageOpened
              ? data.map(({ label, id }, index) => 
                <li onClick={(event) => this.openPage(event, index, label)} key={id}>
                  {label}&nbsp;&nbsp;&nbsp;
                  {
                    activeOptions[label] && activeOptions[label].selectedIndex !== 0 ? 
                    <Button color="default" className="btn-rounded-f mt-lg btn btn-primary" 
                                  style={{marginTop: "0px"}} onClick={(event) => this.resetFilter(event, label)}>
                                    {activeOptions[label].selectedValue}&nbsp;&nbsp;
                                    <i className="fa fa-close" />
                                  </Button> : null
                  }
                </li>)
              : openedPage.options.map((option, index) => <ModalMenuOption
                active={activeOptions[renderedTitle] ? (activeOptions[renderedTitle].selectedIndex === index) : false}
                onClick={() => this.toggleOptionActive(renderedTitle, index, option)}
                key={index}
              >
                {option}
              </ModalMenuOption>)
            : data.map((option, index) => <ModalMenuOption
              active={activeOptions[renderedTitle] ? (activeOptions[renderedTitle].selectedIndex === index) : false}
              onClick={() => this.toggleOptionActive(renderedTitle, index, option)}
              key={index}
            >
              {option}
            </ModalMenuOption>)}
          {/* eslint-enable */}
        </ul>
      </div >
    );
  }
}

MobileModal.propTypes = {
  active: PropTypes.bool,
  close: PropTypes.func.isRequired,
  data: PropTypes.any.isRequired,
  onSelectedValue: PropTypes.func.isRequired,
};

MobileModal.defaultProps = {
  active: false,
};

export default MobileModal;
