exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ProgressIndicator_spinner__c7_ls {\n  position: relative;\n  z-index: 1000; }\n\n.ProgressIndicator_spinner__c7_ls span {\n  margin: 0;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  margin-right: -50%;\n  -webkit-transform: translate(-50%, -50%);\n          transform: translate(-50%, -50%); }\n\n.ProgressIndicator_alacarteSpinnerLogo__3ojgU {\n  position: relative;\n  right: 54px;\n  bottom: 20px;\n  width: 40px; }\n", ""]);

// exports
exports.locals = {
	"spinner": "ProgressIndicator_spinner__c7_ls",
	"alacarteSpinnerLogo": "ProgressIndicator_alacarteSpinnerLogo__3ojgU"
};