exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/**\n * Customized version of bootstrap using variables from _variables.scss.\n * This file is loaded via separate loader thus allowing to use original bootstrap classes (e.g. .btn-default) through out the app.\n */\n/*\n * Typography\n * ======================================================================== */\n/**\n * Custom application mixins available through out the app\n */\n.MobileMenuOption_option__NTJMs span {\n  margin: 0 20px 0 -10px;\n  transition: 0.3s;\n  width: 30px;\n  height: 30px;\n  border-radius: 50%;\n  border: 2px solid #f8f9fa;\n  display: -webkit-flex;\n  display: flex;\n  -webkit-align-items: center;\n          align-items: center;\n  -webkit-justify-content: center;\n          justify-content: center; }\n  .MobileMenuOption_option__NTJMs span img {\n    transition: 0.3s;\n    width: 15px;\n    opacity: 0; }\n\n.MobileMenuOption_active__28Bmz span img {\n  width: 15px;\n  opacity: 1; }\n", ""]);

// exports
exports.locals = {
	"option": "MobileMenuOption_option__NTJMs",
	"active": "MobileMenuOption_active__28Bmz"
};