exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Foodtypes_foodtypes__3hutS {\n  display: -webkit-flex;\n  display: flex;\n  margin-top: 10px; }\n  .Foodtypes_foodtypes__3hutS div {\n    border: none;\n    width: 40px;\n    height: 40px;\n    margin-right: 15px;\n    background-size: contain;\n    background-position: center;\n    background-repeat: no-repeat; }\n\n.Foodtypes_title__3LHf2 {\n  font-size: 1.25rem;\n  font-weight: 400;\n  color: #a3aeb7;\n  margin-right: 10px;\n  margin-top: 7px; }\n\n.Foodtypes_foodtypesForListView__1yJcT {\n  display: -webkit-flex;\n  display: flex;\n  position: absolute;\n  position: initial;\n  padding-top: 10px; }\n  .Foodtypes_foodtypesForListView__1yJcT div {\n    width: 35px;\n    height: 20px;\n    text-align: center; }\n    .Foodtypes_foodtypesForListView__1yJcT div div {\n      border: none;\n      margin-top: -10px;\n      background-size: contain;\n      background-position: center;\n      background-repeat: no-repeat; }\n\n.Foodtypes_foodtypesLabelListView__24in1 {\n  position: relative;\n  bottom: -35px;\n  font-size: 0.5rem;\n  color: black;\n  white-space: nowrap; }\n", ""]);

// exports
exports.locals = {
	"foodtypes": "Foodtypes_foodtypes__3hutS",
	"title": "Foodtypes_title__3LHf2",
	"foodtypesForListView": "Foodtypes_foodtypesForListView__1yJcT",
	"foodtypesLabelListView": "Foodtypes_foodtypesLabelListView__24in1"
};