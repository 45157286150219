import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router';
import { HashRouter } from 'react-router-dom';
// import { initializeSocket } from '../actions/websocket';
import PropTypes from 'prop-types';

/* eslint-disable */
import ErrorPage from '../pages/error';
/* eslint-enable */

import LayoutComponent from '../components/Layout';
import LoginComponent from '../pages/login';
import '../styles/theme.scss';
import { checkTokenExpiration } from '../actions/user';


const PrivateRoute = ({ component, isAuthenticated, ...rest }) => {
  return ( // eslint-disable-line
    <Route
      {...rest} render={props => (
      // we check if the token is stored locally and if it is a valid token (can call api and get 200 response)
      localStorage.getItem('id_token') && isAuthenticated ? (
        React.createElement(component, props)
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location }, // eslint-disable-line
          }}
        />
      )
    )}
    />
  );
};

class App extends React.PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    socket: PropTypes.object
  };

  componentDidMount() {
    const token = localStorage.getItem('id_token');
    if(token) {
      const { dispatch } = this.props;
      dispatch(checkTokenExpiration());
    }
  }

  render() {
    return (
      <HashRouter>
        <Switch>
          <Route path="/connect/auth0/redirect" exact component={LoginComponent} />
          <Route path="/" exact render={() => <Redirect to="/app/main" />} />
          <Route path="/app" exact render={() => <Redirect to="/app/main" />} />
          <PrivateRoute path="/app" component={LayoutComponent} isAuthenticated={this.props.isAuthenticated} />
          <Route path="/login" exact component={LoginComponent} />
          <Route path="/error" exact component={ErrorPage} />
          <Redirect from="*" to="/app/orders" />
        </Switch>
      </HashRouter>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  //socket: state.socket,
})

export default connect(mapStateToProps)(App);
