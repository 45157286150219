import React from 'react';
import PropTypes from 'prop-types';
import s from './Properties.module.scss';

class Properties extends React.Component {
    static propTypes = {
        listOfFoodtypes: PropTypes.array.isRequired,
        listOfAllergens: PropTypes.array.isRequired,
        locale: PropTypes.string.isRequired,
    };
  
    constructor(props) {
      super(props);

      this._isMounted = false;
    }

    componentDidMount() {
      this._isMounted = true;
    }
  
    componentWillUnmount() {
      this._isMounted = false;
    }

    render() {
        return(
          <div className={s.propertiesForListView}>
              {
                this.props.listOfAllergens.map((allergen, index) => {
                  return (
                    <div key={'allergen-' + allergen.id + '-' + index}>
                      <div 
                        id={"allergen-" + allergen.id}
                        style={{ 
                          backgroundImage: `url(${allergen.icon && allergen.icon.formats && allergen.icon.formats.thumbnail ?
                            allergen.icon.formats.thumbnail.url : allergen.icon.url})`
                          }}
                      />
                        <span className={s.propertiesLabelListView2}>
                          {allergen.name_i18n[this.props.locale]}
                        </span>
                    </div>
                  );
                })
              }
              {
                this.props.listOfFoodtypes.map((foodtype, index) => {
                  return (
                    <div key={'foodtype-' + foodtype.id + '-' + index}>
                      <div 
                        style={{ backgroundImage: `url(${foodtype.icon.url})` }} 
                        id={"foodtype-" + foodtype.id}
                      >
                        <span className={s.propertiesLabelListView1}>
                          {foodtype.name_i18n[this.props.locale]}
                        </span>
                      </div>
                    </div>
                  );
                })
              }
            </div>
        );
    }
}

export default Properties;
