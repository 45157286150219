// ConnectedIntlProvider.js
// this is our customer intl provider that is connected to redux store for dynamic language switch

import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

// This function will map the current redux state to the props for the component that it is "connected" to.
// When the state of the redux store changes, this function will be called, if the props that come out of
// this function are different, then the component that is wrapped is re-rendered.
function mapStateToProps(state) {
    const { activeLocaleItem, activeLocaleMessages } = state.locale;
    // this will take the 2 first chars in a locale code with 5 chars like en_US then we take en
    const localeCode2Chars =  activeLocaleItem ? activeLocaleItem.localeCode.toLowerCase().split('_')[0] : null;
    return { 
        locale: activeLocaleItem ? localeCode2Chars : "en", 
        messages: activeLocaleMessages,
        key: activeLocaleItem ? localeCode2Chars : "en"
    };
}

export default connect(mapStateToProps)(IntlProvider);