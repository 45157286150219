import React from 'react';
import PropTypes from 'prop-types';

import EasyPieChart from 'easy-pie-chart';
import {FormattedMessage} from 'react-intl';

import s from './PrepTimeClock.module.scss';

class PrepTimeClock extends React.Component {
    static propTypes = {
        timeToDisplay: PropTypes.number.isRequired,
        idForComponent: PropTypes.string.isRequired
    };

    constructor(props) {
      super(props);

      this._isMounted = false;
  
      this.state = {
        timeToDisplay: this.props.timeToDisplay,
        idForComponent: this.props.idForComponent
      }

    }

    componentDidMount() {
        this._isMounted = true;

        let scaleColorCode = "#008000";

        if (this.state.timeToDisplay > 10)
          scaleColorCode = "#FF8C00";
        else if(this.state.timeToDisplay > 20)
            scaleColorCode = "#FF0000";

        var element = document.getElementById(this.state.idForComponent);
        new EasyPieChart(element, {
            // your options goes here
            barColor: scaleColorCode,
            trackColor: false,
            scaleColor: '#6c757d',
            lineCap: 'square',
            lineWidth: 4,
            size: 87,
            animate: 1000
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
  

    render() {
        return(
          <div className="text-center" style={{marginBottom: "6px"}}>
            <div className={s.easyPieChartSM} 
                 id={this.state.idForComponent} 
                 data-percent={Math.round((this.state.timeToDisplay * 100) / 60)}>
                <span className="fw-semi-bold">
                  {this.state.timeToDisplay} <FormattedMessage id="PriceTable.BootstrapTable.prepTimeFormatter.cell.preparationtime.minutes.label"
                                              defaultMessage="min" />
                </span>
            </div>
          </div>
        );
    }
}

export default PrepTimeClock;
