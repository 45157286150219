import { getCollectionsForRestaurant } from '../api/restaurantcollection.js';

export const RESTAURANTCOLLECTIONS_REQUEST = 'RESTAURANTCOLLECTIONS_REQUEST';
export const RESTAURANTCOLLECTIONS_SUCCESS = 'RESTAURANTCOLLECTIONS_SUCCESS';
export const RESTAURANTCOLLECTIONS_FAILURE = 'RESTAURANTCOLLECTIONS_FAILURE';

function requestCollectionsForRestaurant() {
  return {
    type: RESTAURANTCOLLECTIONS_REQUEST,
    isFetching: true
  };
}

function receiveCollectionsForRestaurant(listOfRestaurantCollections) {
  return {
    type: RESTAURANTCOLLECTIONS_SUCCESS,
    isFetching: false,
    listOfRestaurantCollections
  };
}

function collectionsForRestaurantError(message) {
  return {
    type: RESTAURANTCOLLECTIONS_FAILURE,
    isFetching: false,
    message,
  };
}


// Logs the user out
export function actionGetCollectionForRestaurantFromServer(restaurantId) {
  return (dispatch) => {
    dispatch(requestCollectionsForRestaurant());
    const token = localStorage.getItem('id_token');
    getCollectionsForRestaurant(token, restaurantId)
        .then(listOfCollectionsForRestaurant => {
          //console.log('in actionGetCollectionForRestaurantFromServer listOfCollectionsForRestaurant  => ' + JSON.stringify(listOfCollectionsForRestaurant));
          if(listOfCollectionsForRestaurant.errors) return dispatch(collectionsForRestaurantError(listOfCollectionsForRestaurant.errors[0].message));

          dispatch(receiveCollectionsForRestaurant(listOfCollectionsForRestaurant));
        })
        .catch(error => {
          //console.log('An error occurred inLoginUser: ', error.response.data.message);
          if (error.response && error.response.data) {
            return dispatch(collectionsForRestaurantError(error.response.data.message));
          }
          return dispatch(collectionsForRestaurantError(error.toString()));
        })
  };
}