import React from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Label,
  Badge
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PrepTimeClock from '../PrepTimeClock/PrepTimeClock';

import {
  BootstrapTable,
  TableHeaderColumn,
} from 'react-bootstrap-table';

import {FormattedMessage, injectIntl} from 'react-intl';

//import { reactBootstrapTableData } from './data';
import Widget from '../../../../components/Widget';
import s from './PriceTable.modules.scss';
import PopupInfoMenuItemSize from '../PopupInfoMenuItemSize/PopupInfoMenuItemSize';
import { updateMenuItemSizeVisibilityForDineIn, updateMenuItemSizeVisibilityForDineOut } from '../../../../actions/menuitems';

class PriceTable extends React.Component {

  static propTypes = {
    menuitemSizes: PropTypes.array.isRequired,
    orderedMenuItemId: PropTypes.string.isRequired,
    currency: PropTypes.object.isRequired,
    isShowPrice: PropTypes.bool,
    locale: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      menuitemSizes: this.props.menuitemSizes,
      currencyForMenuitemSize: this.props.currency,
      locale: this.props.locale,
      showModalPopup: false,
      clickedMenuItemSize: {}
    };

    this.displayModalPopup = this.displayModalPopup.bind(this);
    this.sizeNameFormatter = this.sizeNameFormatter.bind(this);
    this.hideModalPopup = this.hideModalPopup.bind(this);
    this.priceFormatter = this.priceFormatter.bind(this);
  }

  componentDidUpdate(prevProps) {
    // console.log('this.props.updatedMenuItemSize => ', this.props.updatedMenuItemSize)
    // console.log('newProps.updatedMenuItemSize => ', prevProps.updatedMenuItemSize)
    // we check if the user updated any of the dine in or take away flags of one of the menu item sizes
    if (this.props.updatedMenuItemSize && prevProps.updatedMenuItemSize &&
      this.props.updatedMenuItemSize.id === prevProps.updatedMenuItemSize.id) {
      const currentMenuItemSizeAvailableForDineIn = this.props.updatedMenuItemSize.isAvailableForDineIn;
      const currentMenuItemSizeAvailableForDineOut = this.props.updatedMenuItemSize.isAvailableForDineOut;
      const prevMenuItemSizeAvailableForDineIn = prevProps.updatedMenuItemSize.isAvailableForDineIn;
      const prevMenuItemSizeAvailableForDineOut = prevProps.updatedMenuItemSize.isAvailableForDineOut;
      if(currentMenuItemSizeAvailableForDineIn !== prevMenuItemSizeAvailableForDineIn ||
        currentMenuItemSizeAvailableForDineOut !== prevMenuItemSizeAvailableForDineOut) {
          // console.log('received a menu item size update on the same menu item size!')
          // this.refreshMenuItemDetailsFromServer(true);
          const newListOfMenuItemSizes = this.state.menuitemSizes.map((menuItemSize) => {
            if(menuItemSize.id.toString() === this.props.updatedMenuItemSize.id.toString())
              return this.props.updatedMenuItemSize
            else
              return menuItemSize
          });
          this.setState({
            menuitemSizes: newListOfMenuItemSizes
          });
        }
    } else {
      // another menu item size has been updated
      if (this.props.updatedMenuItemSize && prevProps.updatedMenuItemSize &&
        this.props.updatedMenuItemSize.id !== prevProps.updatedMenuItemSize.id) {
          // console.log('received a menu item size update for a new menu item size!')
          const newListOfMenuItemSizes = this.state.menuitemSizes.map((menuItemSize) => {
            if(menuItemSize.id.toString() === this.props.updatedMenuItemSize.id.toString())
              return this.props.updatedMenuItemSize
            else
              return menuItemSize
          });
          this.setState({
            menuitemSizes: newListOfMenuItemSizes
          });
        } else {
          // first change on menuitemsizes
          if (this.props.updatedMenuItemSize && !prevProps.updatedMenuItemSize) {
            // console.log('received a menu item size update for the first time!');
            const newListOfMenuItemSizes = this.state.menuitemSizes.map((menuItemSize) => {
              if(menuItemSize.id.toString() === this.props.updatedMenuItemSize.id.toString())
                return this.props.updatedMenuItemSize
              else
                return menuItemSize
            });
            this.setState({
              menuitemSizes: newListOfMenuItemSizes
            })
            // this.refreshMenuItemDetailsFromServer(true);
          }
        }
    }
  }

  displayModalPopup(menuItemSize) {
    //console.log('menuItemSize => ', menuItemSize);

    this.setState({
      showModalPopup: true,
      clickedMenuItemSize: menuItemSize
    });
  }

  hideModalPopup() {
    this.setState({
      showModalPopup: false,
      clickedMenuItemSize: {}
    });
  }

  sizeNameFormatter(cell, row) {
    return (
      <div className="buttonLinkToDetailsPopup" onClick={() => this.displayModalPopup(row)}>
        {row.name_i18n[this.state.locale]} <i className="no-display-md fa fa-external-link" aria-hidden="true"></i>
      </div>
    );
  }

  priceFormatter(cell) {
    let price = cell
    if (price > 0) {
      if (this.state.currencyForMenuitemSize.numberOfDecimals <= 2) {
        price = parseFloat(price.toFixed(this.state.currencyForMenuitemSize.numberOfDecimals))
      } else {
        price = price.toFixed(this.state.currencyForMenuitemSize.numberOfDecimals)
      }
    }
    return (
      this.props.isShowPrice ?
      <Badge color="primary">
        {this.state.currencyForMenuitemSize.symbol} {price}
      </Badge>
      : <Badge color="secondary">
          N/A
        </Badge>
    );
  };

  availableForDineInFormatter(cell, row) {
    // console.log('row id => ', row.id)
    // console.log('is dinein => ', cell);

    const {dispatch, orderedMenuItemId} = this.props;

    const inputForSwitcher = <Input type="checkbox" className="ios" disabled checked={cell} />;

    row.orderedMenuItemId = orderedMenuItemId;

    return (
        <Label
          onClick={() => {
            // console.log('my dine in => ', row)
            dispatch(updateMenuItemSizeVisibilityForDineIn(row))
          }}
          className="switch">
          {inputForSwitcher}
          <i />
        </Label>
    );
  }

  availableForDineOutFormatter(cell, row) {
    // console.log('row id => ', row.id)
    // console.log('is dineout => ', cell);

    const {dispatch, orderedMenuItemId} = this.props;

    const inputForSwitcher = <Input type="checkbox" className="ios" disabled checked={cell} />;

    row.orderedMenuItemId = orderedMenuItemId
    
    return (
        <Label 
          onClick={() => {
            // console.log('my dine out => ', row)
            dispatch(updateMenuItemSizeVisibilityForDineOut(row))
          }} 
          className="switch">
          {inputForSwitcher}
          <i />
        </Label>
    );
  }

  render() {
    const options = {
      sizePerPage: 10,
      paginationSize: 3,
    };

    const {menuitemSizes, 
          currencyForMenuitemSize, 
          showModalPopup,
          clickedMenuItemSize} = this.state;

    //console.log('showModalPopup => ', showModalPopup)

    /*function infoFormatter(cell) {
      return (
        <div>
          <small>
            Type:&nbsp;<span className="fw-semi-bold">{cell.type}</span>
          </small>
          <br />
          <small>
            Dimensions:&nbsp;<span className="fw-semi-bold">{cell.dimensions}</span>
          </small>
        </div>
      );
    }

    function descriptionFormatter(cell) {
      return (
        <button className="btn-link">
          {cell}
        </button>
      );
    }

    function progressFormatter(cell) {
      return (
        <Progress style={{ height: '15px' }} color={cell.type} value={cell.progress} />
      );
    }

    function progressSortFunc(a, b, order) {
      if (order === 'asc') {
        return a.status.progress - b.status.progress;
      }
      return b.status.progress - a.status.progress;
    }

    function dateSortFunc(a, b, order) {
      if (order === 'asc') {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      }
      return new Date(b.date).getTime() - new Date(a.date).getTime();
    }*/

    function prepTimeFormatter(cell, row) {
      return (
        <div>
          <PrepTimeClock idForComponent={"priceTableRow-"+ row.id} timeToDisplay={cell} />
        </div>
      );
    }

    function preselectedFormatter(cell) {
      //console.log(cell);

      let inputForSwitcher = <Input type="checkbox" className="ios" disabled defaultChecked />;

      if(!cell)
        inputForSwitcher = <Input type="checkbox" className="ios"  disabled />;

      return (
          <Label className="switch">
            {inputForSwitcher}
            <i />
          </Label>
      );
    }

    return (
      <div>
        <Widget title={
                        <h4>
                          <span className="fw-semi-bold">
                            <FormattedMessage id="PriceTable.widget.title.available.dish.sizes"
                                              defaultMessage="Available dish sizes" />
                          </span>
                        </h4>
                      } 
                collapse>
          <BootstrapTable data={menuitemSizes}
                          version="4" 
                          options={options} 
                          tableContainerClass={`table-striped table-hover ${s.bootstrapTable}`}
                          onClickCell={this.displayModalPopup}>
            <TableHeaderColumn className="d-none" columnClassName="d-none" dataField="id" isKey>
              <span className="fs-sm">ID</span>
            </TableHeaderColumn>
            <TableHeaderColumn dataField="name" 
                               className="colTwentyPercentWidth"
                               columnClassName="colTwentyPercentWidth"
                               dataFormat={this.sizeNameFormatter.bind(this)}>
              <span className="fs-sm">
                <FormattedMessage id="PriceTable.BootstrapTable.TableHeaderColumn.size.label"
                                                defaultMessage="Size" />
              </span>
            </TableHeaderColumn>
            <TableHeaderColumn dataField="price" 
                               className="colFifteenPercentWidth"
                               columnClassName="colFifteenPercentWidth"
                               dataFormat={this.priceFormatter.bind(this)}>
              <span className="fs-sm">
                <FormattedMessage id="PriceTable.BootstrapTable.TableHeaderColumn.price.label"
                                                  defaultMessage="Price" />
              </span>
            </TableHeaderColumn>
            <TableHeaderColumn dataField="calories"
                               className="d-none d-lg-table-cell colFifteenPercentWidth"
                               columnClassName="d-none d-lg-table-cell colFifteenPercentWidth">
              <span className="fs-sm">
                <FormattedMessage id="PriceTable.BootstrapTable.TableHeaderColumn.calories.label"
                                                    defaultMessage="Calories" />
              </span>
            </TableHeaderColumn>
            <TableHeaderColumn dataField="preparationTimeInMinutes" 
                               className="d-none d-lg-table-cell colTwentyPercentWidth"
                               columnClassName="d-none d-lg-table-cell colTwentyPercentWidth"
                               dataFormat={prepTimeFormatter}>
              <span className="fs-sm">
                <FormattedMessage id="PriceTable.BootstrapTable.TableHeaderColumn.preparationtime.label"
                                                    defaultMessage="Prep. Time" />
              </span>
            </TableHeaderColumn>
            <TableHeaderColumn dataField="posReferenceId"
                               className="d-none d-md-table-cell colFifteenPercentWidth"
                               columnClassName="d-none d-md-table-cell colFifteenPercentWidth">
              <span className="fs-sm">
                <FormattedMessage id="PriceTable.BootstrapTable.TableHeaderColumn.posreferenceid.label"
                                                    defaultMessage="POS Ref. ID" />
              </span>
            </TableHeaderColumn>
            <TableHeaderColumn dataField="isPreselected"
                               className="d-none d-md-table-cell colFifteenPercentWidth"
                               columnClassName="d-none d-md-table-cell colFifteenPercentWidth"
                               dataFormat={preselectedFormatter}>
              <span className="fs-sm">
                <FormattedMessage id="PriceTable.BootstrapTable.TableHeaderColumn.ispreselected.label"
                                                    defaultMessage="Preselected?" />
              </span>
            </TableHeaderColumn>
            <TableHeaderColumn dataField="isAvailableForDineIn"
                               className="d-none d-md-table-cell colFifteenPercentWidth"
                               columnClassName="d-none d-md-table-cell colFifteenPercentWidth"
                               dataFormat={this.availableForDineInFormatter.bind(this)}>
              <span className="fs-sm">
                <FormattedMessage id="PriceTable.BootstrapTable.TableHeaderColumn.isavailablefordinein.label"
                                                    defaultMessage="Dine in?" />
              </span>
            </TableHeaderColumn>
            <TableHeaderColumn dataField="isAvailableForDineOut"
                               className="d-none d-md-table-cell colFifteenPercentWidth"
                               columnClassName="d-none d-md-table-cell colFifteenPercentWidth"
                               dataFormat={this.availableForDineOutFormatter.bind(this)}>
              <span className="fs-sm">
                <FormattedMessage id="PriceTable.BootstrapTable.TableHeaderColumn.isavailablefordineout.label"
                                                    defaultMessage="Take away?" />
              </span>
            </TableHeaderColumn>
            {/*<TableHeaderColumn dataField="name" className="width-100" columnClassName="width-100" dataSort>
              <span className="fs-sm">Name</span>
            </TableHeaderColumn>
            <TableHeaderColumn className="d-none d-md-table-cell" columnClassName="d-none d-md-table-cell" dataField="info" dataFormat={infoFormatter}>
              <span className="fs-sm">Info</span>
            </TableHeaderColumn>
            <TableHeaderColumn className="d-none d-md-table-cell" columnClassName="d-none d-md-table-cell" dataField="description" dataFormat={descriptionFormatter}>
              <span className="fs-sm">Description</span>
            </TableHeaderColumn>
            <TableHeaderColumn className="d-none d-md-table-cell" columnClassName="d-none d-md-table-cell" dataField="date" dataSort sortFunc={dateSortFunc}>
              <span className="fs-sm">Date</span>
            </TableHeaderColumn>
            <TableHeaderColumn className="width-150" columnClassName="width-150" dataField="status" dataSort dataFormat={progressFormatter} sortFunc={progressSortFunc}>
              <span className="fs-sm">Status</span>
            </TableHeaderColumn>*/}
          </BootstrapTable>
        </Widget>
        {
          showModalPopup ?
            <PopupInfoMenuItemSize 
                isModalHideDishOpen={showModalPopup} 
                menuItemSize={clickedMenuItemSize}
                currencyForMenuItemSize={currencyForMenuitemSize}
                locale={this.state.locale}
                isShowPrice={this.props.isShowPrice}
                onModalClosed={this.hideModalPopup} /> : null
        }
      </div>
    );
  }

}

PriceTable.defaultProps = {
  isShowPrice: true
};

function mapStateToProps(store) {
  return {
    isFetchingMenuItemSizeDineIn: store.menuitem.isFetchingMenuItemSizeDineIn,
    isFetchingMenuItemSizeDineOut: store.menuitem.isFetchingMenuItemSizeDineOut,
    updatedMenuItemSize: store.menuitem.updatedMenuItemSize
  };
}

export default injectIntl(withRouter(connect(mapStateToProps)(PriceTable)));
