import axios from 'axios';
//import runtimeEnv from '@mars/heroku-js-runtime-env';

export async function apiLoginLocalAuthProvider (username, password) {
    // endpoint path
    let apiEndpointPath = '/auth/local';
    // init runtime env var
    //const env = runtimeEnv();
    // Request API.
    return await axios
    .post( (/*env.REACT_APP_STRAPI_PROTOCOL || */process.env.REACT_APP_STRAPI_PROTOCOL || 'http') +
    '://' + (/*env.REACT_APP_STRAPI_HOST || */process.env.REACT_APP_STRAPI_HOST || 'localhost') +
     ":" + (/*env.REACT_APP_STRAPI_PORT || */process.env.REACT_APP_STRAPI_PORT || '1337') + apiEndpointPath, {
        identifier: username,
        password: password
        }
    )
    .then(async (response) => {
        // as we are not getting the delivery methods of the restaurant and the languages (seems like a bug with mysql)
        // endpoint path
        apiEndpointPath = '/graphql';
        // init runtime env var
        //const env = runtimeEnv();
        let restaurantId = response.data.user.restaurants[0].slug
        const restaurantSelectedByUser = JSON.parse(localStorage.getItem('selectedRestaurant'));
        if (restaurantSelectedByUser) {
            restaurantId = restaurantSelectedByUser.slug
            response.data.user.restaurants = response.data.user.restaurants.filter(restaurant => restaurant.id === restaurantSelectedByUser.id)
        }
        // console.log('apiLoginLocalAuthProvider restaurantId => ', restaurantId);
        // Request API.
        const token = response.data.jwt
        const responseForRestaurant = await axios
        .post( (/*env.REACT_APP_STRAPI_PROTOCOL || */process.env.REACT_APP_STRAPI_PROTOCOL || 'http') +
        '://' + (/*env.REACT_APP_STRAPI_HOST || */process.env.REACT_APP_STRAPI_HOST || 'localhost') +
        ":" + (/*env.REACT_APP_STRAPI_PORT || */process.env.REACT_APP_STRAPI_PORT || '1337') + apiEndpointPath + '?restaurantId=' + restaurantId  + "&nocaching=1", 
            {
                "query": "query GetListOfMenusForRestaurant {\n  restaurantDetails(slug:  \"" + restaurantId + "\"){\n  languagepacks {\n  id\n  }\n  city {\n id  momentJsTimezoneCode \n} deliverymethods {\n code\n  isDineIn\n  isWithOnlinePayment\n  description_i18n\n  isCollectAtLocation  shortCode \n}\n  }\n}"
            }
            ,{
                headers: { 
                    'Authorization':`Bearer ${token}`
                    }
                }
            )
        const restaurantObject = responseForRestaurant.data.data.restaurantDetails
        // console.log('rest obj => ', restaurantObject)
        // Handle success.
        // console.log('apiLoginLocalAuthProvider response.data => ', response.data);
        response.data.user.restaurants[0].deliverymethods = restaurantObject.deliverymethods
        response.data.user.restaurants[0].languagepacks = restaurantObject.languagepacks
        response.data.user.restaurants[0].city = restaurantObject.city
        return response.data;
    });
}

export async function apiLoginAuth0Provider (accessToken) {
    // endpoint path
    let apiEndpointPath = `/auth/auth0/callback?access_token=${accessToken}`;
    // init runtime env var
    //const env = runtimeEnv();
    // Request API.
    return await axios
    .get( (process.env.REACT_APP_STRAPI_PROTOCOL || 'http') +
    '://' + (process.env.REACT_APP_STRAPI_HOST || 'localhost') +
     ":" + (process.env.REACT_APP_STRAPI_PORT || '1337') + apiEndpointPath
     )
    .then(async (response) => {
        // console.log('response auth0 login => ', response)
        // as we are not getting the delivery methods of the restaurant and the languages (seems like a bug with mysql)
        // endpoint path
        apiEndpointPath = '/graphql';
        // init runtime env var
        //const env = runtimeEnv();
        const [defaultRestaurant] = response.data.user.restaurants
        if (defaultRestaurant) {
            let restaurantId = defaultRestaurant.slug
            // select the first restaurant in the list of managed restaurant by the user
            const restaurantCurrentlySelectedByUser = JSON.parse(localStorage.getItem('selectedRestaurant'));
            if (restaurantCurrentlySelectedByUser && restaurantCurrentlySelectedByUser.id !== defaultRestaurant) {
                localStorage.setItem('selectedRestaurant', JSON.stringify(restaurantCurrentlySelectedByUser));
            } else {
                localStorage.setItem('selectedRestaurant', JSON.stringify(defaultRestaurant));
            }
            const restaurantSelectedByUser = JSON.parse(localStorage.getItem('selectedRestaurant'));
            if (restaurantSelectedByUser) {
                restaurantId = restaurantSelectedByUser.slug
                response.data.user.restaurants = response.data.user.restaurants.filter(restaurant => restaurant.id === restaurantSelectedByUser.id)
            }
            // console.log('apiLoginLocalAuthProvider restaurantId => ', restaurantId);
            // Request API.
            const token = response.data.jwt
            const responseForRestaurant = await axios
            .post( (/*env.REACT_APP_STRAPI_PROTOCOL || */process.env.REACT_APP_STRAPI_PROTOCOL || 'http') +
            '://' + (/*env.REACT_APP_STRAPI_HOST || */process.env.REACT_APP_STRAPI_HOST || 'localhost') +
            ":" + (/*env.REACT_APP_STRAPI_PORT || */process.env.REACT_APP_STRAPI_PORT || '1337') + apiEndpointPath + '?restaurantId=' + restaurantId  + "&nocaching=1", 
                {
                    "query": "query GetListOfMenusForRestaurant {\n  restaurantDetails(slug:  \"" + restaurantId + "\"){\n  languagepacks {\n  id\n  }\n  city {\n id  momentJsTimezoneCode \n} deliverymethods {\n code\n  isDineIn\n  isWithOnlinePayment\n  description_i18n\n  isCollectAtLocation  shortCode \n}\n  }\n}"
                }
                ,{
                    headers: { 
                        'Authorization':`Bearer ${token}`
                        }
                    }
                )
            const restaurantObject = responseForRestaurant.data.data.restaurantDetails
            // console.log('rest obj => ', restaurantObject)
            // Handle success.
            // console.log('apiLoginLocalAuthProvider response.data => ', response.data);
            response.data.user.restaurants[0].deliverymethods = restaurantObject.deliverymethods
            response.data.user.restaurants[0].languagepacks = restaurantObject.languagepacks
            response.data.user.restaurants[0].city = restaurantObject.city
            return response.data;
        }
        else { 
            return {}
        }
    });
}

export async function reloadOutlet (restaurantSelectedByUser) {
  const restaurantId = restaurantSelectedByUser.slug
  const apiEndpointPath = '/graphql';
  // console.log('apiLoginLocalAuthProvider restaurantId => ', restaurantId);
  // Request API.
  const token = localStorage.getItem("id_token");
  const responseForRestaurant = await axios
  .post( (/*env.REACT_APP_STRAPI_PROTOCOL || */process.env.REACT_APP_STRAPI_PROTOCOL || 'http') +
  '://' + (/*env.REACT_APP_STRAPI_HOST || */process.env.REACT_APP_STRAPI_HOST || 'localhost') +
  ":" + (/*env.REACT_APP_STRAPI_PORT || */process.env.REACT_APP_STRAPI_PORT || '1337') + apiEndpointPath + '?restaurantId=' + restaurantId  + "&nocaching=1", 
      {
          "query": "query GetRestaurantDetails {\n  restaurantDetails(slug:  \"" + restaurantId + "\"){\n  languagepacks {\n  id\n  }\n  city {\n id  momentJsTimezoneCode \n} deliverymethods {\n code\n  isDineIn\n  isWithOnlinePayment\n  description_i18n\n  isCollectAtLocation  shortCode \n}\n  }\n}"
      }
      ,{
          headers: { 
              'Authorization':`Bearer ${token}`
              }
          }
      )
  // console.log('responseForRestaurant =>', responseForRestaurant)
  const restaurantObject = responseForRestaurant.data.data.restaurantDetails
  // console.log('rest obj => ', restaurantObject)
  // Handle success.
  // console.log('apiLoginLocalAuthProvider response.data => ', response.data);
  let response = {
    data: {
      user: {
        restaurants: [
          {
            deliverymethods: restaurantObject.deliverymethods,
            languagepacks: restaurantObject.languagepacks,
            city: restaurantObject.city
          }
        ]
      }
    }
  }
  return response.data;
}

export async function apiUserMe (token) {
    // endpoint path
    const apiEndpointPath = '/users/me';
    // init runtime env var
    //const env = runtimeEnv();
    // Request API.
    return await axios
    .get( (/*env.REACT_APP_STRAPI_PROTOCOL || */process.env.REACT_APP_STRAPI_PROTOCOL || 'http') +
    '://' + (/*env.REACT_APP_STRAPI_HOST || */process.env.REACT_APP_STRAPI_HOST || 'localhost') +
     ":" + (/*env.REACT_APP_STRAPI_PORT || */process.env.REACT_APP_STRAPI_PORT || '1337') + apiEndpointPath, {
               headers: { 
                'Authorization':`Bearer ${token}`}
            }
        )
    .then(response => {
        // Handle success.
        // console.log('apiUserMe response.data => ', response.data);
        return response.data;
    });
}

export async function apiUpdateUserPwd (userEmailAddress) {
    var options = {
    method: 'POST',
    url: 'https://' + process.env.REACT_APP_AUTH0_DOMAIN + '/dbconnections/change_password',
    headers: {'content-type': 'application/json'},
    data: {
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
        email: userEmailAddress,
        connection: 'Username-Password-Authentication'
      }
    };

    const response = await axios.request(options)
    return response.data
}