exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/**\n * Customized version of bootstrap using variables from _variables.scss.\n * This file is loaded via separate loader thus allowing to use original bootstrap classes (e.g. .btn-default) through out the app.\n */\n/*\n * Typography\n * ======================================================================== */\n/**\n * Custom application mixins available through out the app\n */\n.OrderCard_root__30d7K .orderNumber {\n  color: black;\n  font-weight: 600;\n  font-size: 13px; }\n\n.OrderCard_foodCategoryHeader__1gZ_L {\n  height: 30px;\n  font-size: large;\n  font-weight: 600;\n  margin-bottom: 15px; }\n", ""]);

// exports
exports.locals = {
	"root": "OrderCard_root__30d7K",
	"foodCategoryHeader": "OrderCard_foodCategoryHeader__1gZ_L"
};