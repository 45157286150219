import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem
} from 'reactstrap';

import { FormattedMessage, injectIntl } from 'react-intl'; 

import s from './DataManagement.module.scss';

class Prices extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this._isMounted = false;

    this.state = {
      idToken: localStorage.getItem('id_token'),
      locale: JSON.parse(localStorage.getItem('activeLocaleItem')).alt,
      isStagingMode: window.location.href.indexOf("-dev") > -1 || window.location.href.indexOf("staging-") > -1 || window.location.href.indexOf("http://localhost") > -1,
      restaurantId: JSON.parse(localStorage.getItem('userInfo')).restaurants[0].id
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  
  render() {

    const urlToLoad = `https://alacarte.retool.com/embedded/public/09f4d76e-9a9e-44ed-b03e-49f4c74a4eb0?_environment=${this.state.isStagingMode ? 'staging' : 'production'}#jwtToken=${this.state.idToken}&locale=${this.state.locale}&restaurantId=${this.state.restaurantId}`

    return (
      <div className={s.root}>
        <Breadcrumb>
    <BreadcrumbItem>{this.props.managedRestaurant ? this.props.managedRestaurant.name : '...'}</BreadcrumbItem>
          <BreadcrumbItem active><FormattedMessage id="DataManagement.Breadcrumbitem.DataManagement" defaultMessage="Data Management" /></BreadcrumbItem>
        </Breadcrumb>
        <h1 className="page-title">
          <span className="fw-semi-bold">
            <FormattedMessage id="DataManagement.PageTitle.DataManagement" defaultMessage="Data Management" />
          </span>
        </h1>
        <iframe 
          title="Alacarte Data Management"
          src={urlToLoad}
          width="1500px"
          height="1700px"
          frameBorder="0"
          scrolling="no">
        </iframe>
      </div>
    );
  } // end of render
} // end of class


function mapStateToProps(store) {
  return {
    managedRestaurant: store.auth.managedRestaurant
  };
}

export default injectIntl(withRouter(connect(mapStateToProps)(Prices)));
