import axios from 'axios';
//import runtimeEnv from '@mars/heroku-js-runtime-env';

export async function getListOfMenusFromServerForRestaurant (token, restaurantId) {
    // endpoint path
    const apiEndpointPath = '/graphql';
    const graphqlQuery = `
    query GetListOfMenusForRestaurant {
        restaurantDetails(slug: "${restaurantId}") {
            name
            city {
                name
            }
            languagepacks {
                id
            }
            menus(sort: "displayOrder:asc") {
                id
                userLabel
                startTimeInHours
                startTimeInMinutes
                availabilityTimeslots
                endTimeInHours
                endTimeInMinutes
                userLabel_i18n
                availabilityDescription_i18n
                isDisplayAvailabilityTimes
                isAvailabilityGoingOverNextDay
                isAlwaysVisible
                weekdaysAvailableOn {
                    code
                }
                deliverymethods {
                    code
                    description_i18n
                }
                themePicture {
                    url
                }
                isActive
            }
        }
    }
    
    `
    // init runtime env var
    //const env = runtimeEnv();
    // Request API.
    return await axios
    .post( (/*env.REACT_APP_STRAPI_PROTOCOL || */process.env.REACT_APP_STRAPI_PROTOCOL || 'http') +
    '://' + (/*env.REACT_APP_STRAPI_HOST || */process.env.REACT_APP_STRAPI_HOST || 'localhost') +
     ":" + (/*env.REACT_APP_STRAPI_PORT || */process.env.REACT_APP_STRAPI_PORT || '1337') + apiEndpointPath + '?restaurantId=' + restaurantId + "&nocaching=1", 
           {
            "query": graphqlQuery
           }
           ,{
               headers: { 
                'Authorization':`Bearer ${token}`
                }
            }
        )
    .then(response => {
        // Handle success.
        //console('menu axios response', response);
        if(response.data.errors) return response.data;

        return response.data.data.restaurantDetails;
    });
}

export async function updateMenuIsActiveProperty (token, menu) {
    // endpoint path
    const apiEndpointPath = '/menus';
    // init runtime env var
    //const env = runtimeEnv();
    // Request API.
    return await axios
    .put( (/*env.REACT_APP_STRAPI_PROTOCOL || */process.env.REACT_APP_STRAPI_PROTOCOL || 'http') +
    '://' + (/*env.REACT_APP_STRAPI_HOST || */process.env.REACT_APP_STRAPI_HOST || 'localhost') +
     ":" + (/*env.REACT_APP_STRAPI_PORT || */process.env.REACT_APP_STRAPI_PORT || '1337') + apiEndpointPath + "/" + menu.id, 
           {
            "isActive": !menu.isActive
           }
           ,{
               headers: { 
                'Authorization':`Bearer ${token}`
                }
            }
        )
    .then(response => {
        // Handle success.
        //console('menu axios response', response);
        return response.data;
    });
}