import { getListOfMenuItemsFromServerForRestaurantMenu,
         updateMenuItemIsActiveProperty, getMenuItemDetailsViaMenuCategory,
         getMenuItemDetailsViaMenuGroup, getMenuItemDetailsViaMenuitemsGroup,
         updateMenuItemSizeIsAvailableForDineInProperty, updateMenuItemSizeIsAvailableForDineOutProperty } from '../api/menuitem.js';

export const MENUITEMS_REQUEST = 'MENUITEMS_REQUEST';
export const MENUITEMS_SUCCESS = 'MENUITEMS_SUCCESS';
export const MENUITEMS_FAILURE = 'MENUITEMS_FAILURE';

export const MENUITEM_IS_ACTIVE_UPDATE_REQUEST = 'MENUITEM_IS_ACTIVE_UPDATE_REQUEST';
export const MENUITEM_IS_ACTIVE_UPDATE_SUCCESS = 'MENUITEM_IS_ACTIVE_UPDATE_SUCCESS';
export const MENUITEM_IS_ACTIVE_UPDATE_FAILURE = 'MENUITEM_IS_ACTIVE_UPDATE_FAILURE';

export const MENUITEMSIZE_IS_AVAILABLE_FOR_DINE_IN_UPDATE_REQUEST = 'MENUITEMSIZE_IS_AVAILABLE_FOR_DINE_IN_UPDATE_REQUEST';
export const MENUITEMSIZE_IS_AVAILABLE_FOR_DINE_IN_UPDATE_SUCCESS = 'MENUITEMSIZE_IS_AVAILABLE_FOR_DINE_IN_UPDATE_SUCCESS';
export const MENUITEMSIZE_IS_AVAILABLE_FOR_DINE_IN_UPDATE_FAILURE = 'MENUITEMSIZE_IS_AVAILABLE_FOR_DINE_IN_UPDATE_FAILURE';

export const MENUITEMSIZE_IS_AVAILABLE_FOR_DINE_OUT_UPDATE_REQUEST = 'MENUITEMSIZE_IS_AVAILABLE_FOR_DINE_OUT_UPDATE_REQUEST';
export const MENUITEMSIZE_IS_AVAILABLE_FOR_DINE_OUT_UPDATE_SUCCESS = 'MENUITEMSIZE_IS_AVAILABLE_FOR_DINE_OUT_UPDATE_SUCCESS';
export const MENUITEMSIZE_IS_AVAILABLE_FOR_DINE_OUT_UPDATE_FAILURE = 'MENUITEMSIZE_IS_AVAILABLE_FOR_DINE_OUT_UPDATE_FAILURE';

export const MENUITEM_DETAILS_REQUEST = 'MENUITEM_DETAILS_REQUEST';
export const MENUITEM_DETAILS_SUCCESS = 'MENUITEM_DETAILS_SUCCESS';
export const MENUITEM_DETAILS_FAILURE = 'MENUITEM_DETAILS_FAILURE';

function requestMenuItems() {
  return {
    type: MENUITEMS_REQUEST,
    isFetching: true
  };
}

function receiveMenuItems(restaurantMenuItems) {
  return {
    type: MENUITEMS_SUCCESS,
    isFetching: false,
    restaurantMenuItems
  };
}

function menuItemsError(message) {
  return {
    type: MENUITEMS_FAILURE,
    isFetching: false,
    message,
  };
}

function requestUpdateIsActiveMenuItem() {
  return {
    type: MENUITEM_IS_ACTIVE_UPDATE_REQUEST,
    isFetching: true
  };
}

function receiveUpdateIsActiveMenuItem(updatedMenuItem) {
  return {
    type: MENUITEM_IS_ACTIVE_UPDATE_SUCCESS,
    isFetching: false,
    updatedMenuItem
  };
}

function updateIsActiveMenuItemError(message) {
  return {
    type: MENUITEM_IS_ACTIVE_UPDATE_FAILURE,
    isFetching: false,
    message,
  };
}

function requestUpdateIsAvailableForDineInMenuItemSize() {
  return {
    type: MENUITEMSIZE_IS_AVAILABLE_FOR_DINE_IN_UPDATE_REQUEST,
    isFetchingMenuItemSizeDineIn: true
  };
}

function receiveUpdateIsAvailableForDineInMenuItemSize(updatedMenuItemSize) {
  return {
    type: MENUITEMSIZE_IS_AVAILABLE_FOR_DINE_IN_UPDATE_SUCCESS,
    isFetchingMenuItemSizeDineIn: false,
    updatedMenuItemSize
  };
}

function updateIsAvailableForDineInMenuItemSizeError(message) {
  return {
    type: MENUITEMSIZE_IS_AVAILABLE_FOR_DINE_IN_UPDATE_FAILURE,
    isFetchingMenuItemSizeDineIn: false,
    message,
  };
}

function requestUpdateIsAvailableForDineOutMenuItemSize() {
  return {
    type: MENUITEMSIZE_IS_AVAILABLE_FOR_DINE_OUT_UPDATE_REQUEST,
    isFetchingMenuItemSizeDineOut: true
  };
}

function receiveUpdateIsAvailableForDineOutMenuItemSize(updatedMenuItemSize) {
  return {
    type: MENUITEMSIZE_IS_AVAILABLE_FOR_DINE_OUT_UPDATE_SUCCESS,
    isFetchingMenuItemSizeDineOut: false,
    updatedMenuItemSize
  };
}

function updateIsAvailableForDineOutMenuItemSizeError(message) {
  return {
    type: MENUITEMSIZE_IS_AVAILABLE_FOR_DINE_OUT_UPDATE_FAILURE,
    isFetchingMenuItemSizeDineOut: false,
    message,
  };
}

function requestMenuItemDetails() {
  return {
    type: MENUITEM_DETAILS_REQUEST,
    isFetching: true
  };
}

function receiveMenuItemDetails(restaurantMenuItemDetails) {
  return {
    type: MENUITEM_DETAILS_SUCCESS,
    isFetching: false,
    restaurantMenuItemDetails
  };
}

function menuItemDetailsError(message) {
  return {
    type: MENUITEM_DETAILS_FAILURE,
    isFetching: false,
    message,
  };
}

// Get a list of menus items within a specific menu
export function getMenuItemsFromServer(restaurantId, menuId) {
  return (dispatch) => {
    dispatch(requestMenuItems());
    const token = localStorage.getItem('id_token');
    getListOfMenuItemsFromServerForRestaurantMenu(token, restaurantId, menuId)
        .then(restaurantWithMenuItems => {
          // console.log('in getMenuItemsFromServer restaurantWithMenuItems  => ' + JSON.stringify(restaurantWithMenuItems));
          //localStorage.setItem('arrayOfMenus', arrayOfMenus);
          // Dispatch the success action with fake user
          if(restaurantWithMenuItems.errors) return dispatch(menuItemsError(restaurantWithMenuItems.errors[0].message));

          dispatch(receiveMenuItems(restaurantWithMenuItems));
        })
        .catch(error => {
          //console.log('An error occurred inLoginUser: ', error.response.data.message);
          if (error.response && error.response.data) {
            return dispatch(menuItemsError(error.response.data.message));
          }
          return dispatch(menuItemsError(error.toString()));
        })
  };
}

// Get a menu item details for a specific menu, menu category and menu group when applicable
export function getMenuItemDetailsFromServer(restaurantId, menuId, menuCategoryId, menuGroupid = null, menuitemsGroupid = null, orderedMenuItemId) {
  return (dispatch) => {
    dispatch(requestMenuItemDetails());
    const token = localStorage.getItem('id_token');
    // menu group mode
    if(menuGroupid && !menuitemsGroupid) {
      getMenuItemDetailsViaMenuGroup(token, restaurantId, menuId, menuCategoryId, menuGroupid, orderedMenuItemId)
      .then(restaurantWithMenuItemDetails => {
        //console.log('in getMenuItemDetailsFromServer menugroup mode restaurantWithMenuItemDetails  => ' + JSON.stringify(restaurantWithMenuItemDetails));
        //localStorage.setItem('arrayOfMenus', arrayOfMenus);
        // Dispatch the success action with fake user
        if(restaurantWithMenuItemDetails.errors) return dispatch(menuItemDetailsError(restaurantWithMenuItemDetails.errors[0].message));

        dispatch(receiveMenuItemDetails(restaurantWithMenuItemDetails));
      })
      .catch(error => {
        //console.log('An error occurred inLoginUser: ', error.response.data.message);
        if (error.response && error.response.data) {
          return dispatch(menuItemDetailsError(error.response.data.message));
        }
        return dispatch(menuItemDetailsError(error.toString()));
      })
    } else {
      // menu item group mode
      if(menuGroupid && menuitemsGroupid) {
        getMenuItemDetailsViaMenuitemsGroup(token, restaurantId, menuId, menuCategoryId, menuGroupid, menuitemsGroupid, orderedMenuItemId)
        .then(restaurantWithMenuItemDetails => {
          //console.log('in getMenuItemDetailsFromServer menugroup mode restaurantWithMenuItemDetails  => ' + JSON.stringify(restaurantWithMenuItemDetails));
          //localStorage.setItem('arrayOfMenus', arrayOfMenus);
          // Dispatch the success action with fake user
          if(restaurantWithMenuItemDetails.errors) return dispatch(menuItemDetailsError(restaurantWithMenuItemDetails.errors[0].message));
  
          dispatch(receiveMenuItemDetails(restaurantWithMenuItemDetails));
        })
        .catch(error => {
          //console.log('An error occurred inLoginUser: ', error.response.data.message);
          if (error.response && error.response.data) {
            return dispatch(menuItemDetailsError(error.response.data.message));
          }
          return dispatch(menuItemDetailsError(error.toString()));
        })
      } else {
        // normal menu mode
        getMenuItemDetailsViaMenuCategory(token, restaurantId, menuId, menuCategoryId, orderedMenuItemId)
            .then(restaurantWithMenuItemDetails => {
              //console.log('in getMenuItemDetailsFromServer menucategory mode restaurantWithMenuItemDetails  => ' + JSON.stringify(restaurantWithMenuItemDetails));
              //localStorage.setItem('arrayOfMenus', arrayOfMenus);
              // Dispatch the success action with fake user
              if(restaurantWithMenuItemDetails.errors) return dispatch(menuItemDetailsError(restaurantWithMenuItemDetails.errors[0].message));

              dispatch(receiveMenuItemDetails(restaurantWithMenuItemDetails));
            })
            .catch(error => {
              //console.log('An error occurred inLoginUser: ', error.response.data.message);
              if (error.response && error.response.data) {
                return dispatch(menuItemDetailsError(error.response.data.message));
              }
              return dispatch(menuItemDetailsError(error.toString()));
            })
        }
      }
  };
}

// Updates a menu item visibility in a menu for a restaurant
export function updateMenuItemVisibility(menuItem) {
  return (dispatch) => {
    dispatch(requestUpdateIsActiveMenuItem());
    const token = localStorage.getItem('id_token');
    updateMenuItemIsActiveProperty(token, menuItem)
        .then(updatedMenuItem => {
          //console.log('in getMenuItemsFromServer restaurantWithMenuItems  => ' + JSON.stringify(restaurantWithMenuItems));
          //localStorage.setItem('arrayOfMenus', arrayOfMenus);
          // Dispatch the success action with fake user
          dispatch(receiveUpdateIsActiveMenuItem(updatedMenuItem));
        })
        .catch(error => {
          //console.log('An error occurred inLoginUser: ', error.response.data.message);
          if (error.response && error.response.data) {
            return dispatch(updateIsActiveMenuItemError(error.response.data.message));
          }
          return dispatch(updateIsActiveMenuItemError(error.toString()));
        })
  };
}

// Updates a menu item size visibility for dine in
export function updateMenuItemSizeVisibilityForDineIn(menuItemSize) {
  return (dispatch) => {
    dispatch(requestUpdateIsAvailableForDineInMenuItemSize());
    const token = localStorage.getItem('id_token');
    updateMenuItemSizeIsAvailableForDineInProperty(token, menuItemSize)
        .then(updatedMenuItemSize => {
          //console.log('in getMenuItemsFromServer restaurantWithMenuItems  => ' + JSON.stringify(restaurantWithMenuItems));
          //localStorage.setItem('arrayOfMenus', arrayOfMenus);
          // Dispatch the success action with fake user
          dispatch(receiveUpdateIsAvailableForDineInMenuItemSize(updatedMenuItemSize));
        })
        .catch(error => {
          //console.log('An error occurred inLoginUser: ', error.response.data.message);
          if (error.response && error.response.data) {
            return dispatch(updateIsAvailableForDineInMenuItemSizeError(error.response.data.message));
          }
          return dispatch(updateIsAvailableForDineInMenuItemSizeError(error.toString()));
        })
  };
}

// Updates a menu item size visibility for dine out
export function updateMenuItemSizeVisibilityForDineOut(menuItemSize) {
  return (dispatch) => {
    dispatch(requestUpdateIsAvailableForDineOutMenuItemSize());
    const token = localStorage.getItem('id_token');
    updateMenuItemSizeIsAvailableForDineOutProperty(token, menuItemSize)
        .then(updatedMenuItemSize => {
          //console.log('in getMenuItemsFromServer restaurantWithMenuItems  => ' + JSON.stringify(restaurantWithMenuItems));
          //localStorage.setItem('arrayOfMenus', arrayOfMenus);
          // Dispatch the success action with fake user
          dispatch(receiveUpdateIsAvailableForDineOutMenuItemSize(updatedMenuItemSize));
        })
        .catch(error => {
          //console.log('An error occurred inLoginUser: ', error.response.data.message);
          if (error.response && error.response.data) {
            return dispatch(updateIsAvailableForDineOutMenuItemSizeError(error.response.data.message));
          }
          return dispatch(updateIsAvailableForDineOutMenuItemSizeError(error.toString()));
        })
  };
}