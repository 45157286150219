exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/**\n * Customized version of bootstrap using variables from _variables.scss.\n * This file is loaded via separate loader thus allowing to use original bootstrap classes (e.g. .btn-default) through out the app.\n */\n/*\n * Typography\n * ======================================================================== */\n/**\n * Custom application mixins available through out the app\n */\n.ErrorPage_errorPage__mtT78 {\n  padding-top: 5%;\n  height: 100vh; }\n\n.ErrorPage_errorContainer__3ihk2 {\n  width: 365px;\n  text-align: center;\n  width: auto; }\n\n.ErrorPage_errorBtn__3wX6C {\n  padding-left: 35px;\n  padding-right: 35px; }\n\n.ErrorPage_errorCode__1ELO5 {\n  margin: 20px;\n  font-size: 80px;\n  font-weight: 400;\n  color: #3c484f; }\n  @media (min-width: 768px) {\n    .ErrorPage_errorCode__1ELO5 {\n      font-size: 180px; } }\n\n.ErrorPage_errorInfo__1ORMr {\n  font-size: 20px;\n  color: #3c484f; }\n\n.ErrorPage_errorHelp__17LI4 {\n  font-size: 14px; }\n\n.ErrorPage_pageFooter__1agcP {\n  position: absolute;\n  bottom: 30px;\n  left: 0;\n  right: 0;\n  width: 100%;\n  font-size: 0.9rem;\n  color: #798892;\n  text-align: center; }\n", ""]);

// exports
exports.locals = {
	"errorPage": "ErrorPage_errorPage__mtT78",
	"errorContainer": "ErrorPage_errorContainer__3ihk2",
	"errorBtn": "ErrorPage_errorBtn__3wX6C",
	"errorCode": "ErrorPage_errorCode__1ELO5",
	"errorInfo": "ErrorPage_errorInfo__1ORMr",
	"errorHelp": "ErrorPage_errorHelp__17LI4",
	"pageFooter": "ErrorPage_pageFooter__1agcP"
};