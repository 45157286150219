exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/**\n * Customized version of bootstrap using variables from _variables.scss.\n * This file is loaded via separate loader thus allowing to use original bootstrap classes (e.g. .btn-default) through out the app.\n */\n/*\n * Typography\n * ======================================================================== */\n/**\n * Custom application mixins available through out the app\n */\n.General_general__3FgJl {\n  display: -webkit-flex;\n  display: flex;\n  -webkit-flex-direction: column;\n          flex-direction: column;\n  margin: 5px 0 25px; }\n\n.General_title__37y_I {\n  margin-top: 20px;\n  font-weight: 600;\n  text-transform: uppercase;\n  font-size: 1.25rem;\n  color: #3c484f; }\n\n.General_price__3I6Hu {\n  font-weight: 600;\n  font-size: 1.25rem;\n  margin-bottom: 25px;\n  color: #3c484f; }\n\n.General_ratingWrapper__FbD9s {\n  display: -webkit-flex;\n  display: flex; }\n  @media (max-width: 767.98px) {\n    .General_ratingWrapper__FbD9s {\n      -webkit-justify-content: flex-end;\n              justify-content: flex-end; } }\n\n.General_dataWrapper__2kfuK {\n  display: -webkit-flex;\n  display: flex;\n  -webkit-flex-direction: column;\n          flex-direction: column; }\n  @media (max-width: 767.98px) {\n    .General_dataWrapper__2kfuK {\n      /*& :global .title {\n      margin-bottom: 25px;\n      margin-top: 5px;\n    }*/\n      /*& :global .subtitle {\n      margin-bottom: 30;\n    }*/ } }\n", ""]);

// exports
exports.locals = {
	"general": "General_general__3FgJl",
	"title": "General_title__37y_I",
	"price": "General_price__3I6Hu",
	"ratingWrapper": "General_ratingWrapper__FbD9s",
	"dataWrapper": "General_dataWrapper__2kfuK"
};