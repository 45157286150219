import { RESTAURANTCOLLECTIONS_REQUEST , 
         RESTAURANTCOLLECTIONS_SUCCESS, 
         RESTAURANTCOLLECTIONS_FAILURE } from '../actions/restaurantcollection';

// The menu reducer.
export default function restaurantCollection(state = {
  isFetching: false,
  listOfRestaurantCollections: undefined,
  errorMessage: '',
}, action) {
  switch (action.type) {
    case RESTAURANTCOLLECTIONS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        errorMessage: ''
      });
    case RESTAURANTCOLLECTIONS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        listOfRestaurantCollections: action.listOfRestaurantCollections,
        errorMessage: '',
      });
    case RESTAURANTCOLLECTIONS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.message,
      });
    default:
      return state;
  }
}
