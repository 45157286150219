import { MENUS_REQUEST , MENUS_SUCCESS, MENUS_FAILURE,
          MENU_IS_ACTIVE_UPDATE_REQUEST, MENU_IS_ACTIVE_UPDATE_SUCCESS, MENU_IS_ACTIVE_UPDATE_FAILURE 
  } from '../actions/menu';

// The menu reducer.
export default function menu(state = {
  isFetching: false,
  menus: [],
  errorMessage: '',
  updatedMenu: {},
}, action) {
  switch (action.type) {
    case MENUS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        errorMessage: ''
      });
    case MENUS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        restaurantWithMenus: action.restaurantWithMenus,
        errorMessage: '',
      });
    case MENUS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.message,
      });
    case MENU_IS_ACTIVE_UPDATE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        errorMessage: ''
      });
    case MENU_IS_ACTIVE_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        updatedMenu: action.updatedMenu,
        errorMessage: '',
      });
    case MENU_IS_ACTIVE_UPDATE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: '',
      });
    default:
      return state;
  }
}
