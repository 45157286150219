import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
// import { Container, Alert, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem/*FormGroup, Input, Label*/ } from 'reactstrap';
// import Widget from '../../components/Widget';
// import s from './Login.module.scss';
import { loginUser, loginUserWithAuth0, logoutUser } from '../../actions/user';
// import alacarteBlackLogo from '../../images/alacarte-black-logo.png';
// import LocaleSelector from '../../components/LocaleSelector';
import { /* FormattedMessage, */ injectIntl } from 'react-intl';
import axios from 'axios';
import queryString from 'query-string'

// const zeroPad = (num, places) => String(num).padStart(places, '0')

class Login extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      login: '',
      password: '',
      currentRestaurant: null,
      listOfRestaurants: [],
      dropdownOpen: false
    };

    this.doLogin = this.doLogin.bind(this);
    this.changeLogin = this.changeLogin.bind(this);
    this.onBlurLoginInput = this.onBlurLoginInput.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.changeRestaurant = this.changeRestaurant.bind(this);
    this.getListOfRestaurantsForUsername = this.getListOfRestaurantsForUsername.bind(this)
  }

  componentDidMount() {
    if (this.state.login !== '') {
      const listOfRestaurants = this.getListOfRestaurantsForUsername(this.state.login);
      this.setState({ listOfRestaurants: listOfRestaurants.slice(0) });
    }
    const params = queryString.parse(this.props.location.search);
    if (params.access_token) {
      this.props.dispatch(loginUserWithAuth0(params.access_token));
    }
  }

  toggleDropdown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  changeRestaurant(currentRestaurant, selectedIndex) {
    // console.log('currentRestauraunt => ', currentRestaurant)
    if (currentRestaurant) {
      localStorage.setItem('selectedRestaurant', JSON.stringify(currentRestaurant));
      this.setState({ currentRestaurant: Object.assign({}, currentRestaurant) });
    }
  }

  async getListOfRestaurantsForUsername (userEmail) {
    // endpoint path
    const apiEndpointPath = '/restaurantsofcollaborator/' + userEmail;
    // init runtime env var
    //const env = runtimeEnv();
    // Request API.
    const response = await axios
    .get( (/*env.REACT_APP_STRAPI_PROTOCOL || */process.env.REACT_APP_STRAPI_PROTOCOL || 'http') +
    '://' + (/*env.REACT_APP_STRAPI_HOST || */process.env.REACT_APP_STRAPI_HOST || 'localhost') +
     ":" + (/*env.REACT_APP_STRAPI_PORT || */process.env.REACT_APP_STRAPI_PORT || '1337') + apiEndpointPath);

     return response.data
  }

  async changeLogin(event) {
    const username = event.target.value;
    this.setState({ 
      login: username
    });
  }

  async onBlurLoginInput (event) {
    if (this.state.login.length > 1) {
      const listOfRestaurants = await this.getListOfRestaurantsForUsername(this.state.login);
      let defaultCurrentRestaurant = null
      if (listOfRestaurants.length > 1) {
        defaultCurrentRestaurant = listOfRestaurants[0];
        localStorage.setItem('selectedRestaurant', JSON.stringify(defaultCurrentRestaurant));
      } else {
        localStorage.removeItem('selectedRestaurant');
      }
  
      // console.log('listOfRestaurants => ', listOfRestaurants)
      this.setState({ 
        listOfRestaurants:  listOfRestaurants ? listOfRestaurants.slice(0): [],
        currentRestaurant: defaultCurrentRestaurant
      });
    } else {
      this.setState({ 
        listOfRestaurants:  [],
        currentRestaurant: {}
      });
    }
  }

  changePassword(event) {
    this.setState({ password: event.target.value });
  }

  doLogin(e) {
    e.preventDefault();
    // const previouslyLoggedInUser = localStorage.getItem('lastLoggedInUsername');
    // check if previously logged in user is the same as currently logging in user
    // in this case we will redirect him to previously opened page if not then
    // we redirect the new user to the app main page
    const selectedRestaurant = localStorage.getItem('selectedRestaurant')
    if (selectedRestaurant) {
      const isCurrentLoggingUserIsPreviousUser = /* this.state.login === previouslyLoggedInUser && */ !JSON.parse(selectedRestaurant);
      this.props.dispatch(loginUser({ login: this.state.login, password: this.state.password }));
      this.setState({isCurrentLoggingUserIsPreviousUser});
    }
  }

  render() {
    // const { intl } = this.props;
    // const { currentRestaurant, listOfRestaurants } = this.state;
    // cant access login page while logged in
    // if we are on the redirect page from strapi to frontend then we are trying to recover strapi user info from auth0 user profile info
    if (this.props.isAuthenticated) { // eslint-disable-line
      const userInfoStringified = localStorage.getItem('userInfo');
      // console.log('userInfoStringified => ', userInfoStringified)
      if (userInfoStringified) {
        const userInfo = JSON.parse(userInfoStringified)
        // console.log('userInfo => ', userInfo)
        if (userInfo && userInfo.restaurants && userInfo.restaurants.length === 0) {
          setTimeout(() => {
            this.props.dispatch(logoutUser());
          }, 5000);
          return <div>Your account has been activated and the Alacarte team is connecting it to your outlet. Please try again in 30 minutes.</div>
        } else {
          const { from }  = this.state.isCurrentLoggingUserIsPreviousUser ? 
              (this.props.location.state && this.props.location.state.from ? this.props.location.state : { from: { pathname: '/app' } } ) 
              : { from: { pathname: '/app' } }; // eslint-disable-line
          return (
            <Redirect to={from} />
          );
        }
      } else {
        setTimeout(() => {
          this.props.dispatch(logoutUser());
        }, 5000);
        return <div>Your account has been activated and the Alacarte team is connecting it to your outlet. Please try again in 30 minutes.</div>
      }
    } else {
      const params = queryString.parse(this.props.location.search);
      // console.log('params => ', params)
      if (params.access_token) {
        return <div>Authentication in progress</div>
      } else {
        // if we are on the login page and not authenticated then redirect to auth0 loging page
        const endpoint = (process.env.REACT_APP_STRAPI_HOST ? `https://` + process.env.REACT_APP_STRAPI_HOST : 'http://localhost') +
        ":" + (process.env.REACT_APP_STRAPI_PORT || '1337') +
        '/connect/auth0'
        window.location.replace(endpoint)
        return <div>Redirect in progress</div>
      }
    }

    /* return (
      <div className={s.root}>
        <div className={s.header}>
          <LocaleSelector isSSEModeEnabled = { false }/>
        </div>
        <Container>
          <h5 className={`${s.logo}`}>
            <img className={`${s.logoImage}`} src={alacarteBlackLogo} alt="Logo" />
          </h5>
          <Widget className={`${s.widget} mx-auto`} 
            title={<h3 className="mt-0">
                      <FormattedMessage id="Login.title" defaultMessage="Login" />
                    </h3>}>
            <form className="mt" onSubmit={this.doLogin}>
              {
                this.props.errorMessage && ( // eslint-disable-line
                  <Alert
                    className="alert-transparent"
                    color="danger">
                      <span style={{color: 'black'}}>
                        <b>{this.props.errorMessage}</b>
                      </span>
                  </Alert>
                )
              }
              <div className="form-group">
                <input
                  id='loginInput'
                  className="form-control no-border" 
                  value={this.state.login} onChange={this.changeLogin} 
                  onBlur={this.onBlurLoginInput}
                  type="text" 
                  required name="username"
                  placeholder={intl.formatMessage({ id: "Login.form.input.username.placeholder", defaultMessage: 'Username'})} />
              </div>
              <div className="form-group">
                <input className="form-control no-border" value={this.state.password} onChange={this.changePassword} type="password" required name="password" 
                  placeholder={intl.formatMessage({ id: "Login.form.input.password.placeholder", defaultMessage: 'Password'})} />
              </div>
              {
                this.state.listOfRestaurants.length > 1 ? (
                  <div className="form-group">
                    <UncontrolledButtonDropdown>
                      <DropdownToggle
                        caret color="default"
                        className="dropdown-toggle-split"
                      >
                        {zeroPad(currentRestaurant.id, 3) + " - " + currentRestaurant.name}&nbsp;&nbsp;
                      </DropdownToggle>
                      <DropdownMenu>
                        {listOfRestaurants.map((restaurant, index) =>
                          <DropdownItem key={restaurant.id} onClick={() => this.changeRestaurant(restaurant, index)}>
                            {zeroPad(restaurant.id, 3) + " - " + restaurant.name}
                          </DropdownItem>,
                        )}
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </div>
                ) : null
              }
              <div className="form-group">
                  <div className="clearfix">
                    <div className="btn-toolbar float-right">
                      <button type="submit" href="/app" className="btn btn-inverse btn-sm">
                        {this.props.isFetching ? 
                          <FormattedMessage id="Login.form.submit.button.label.loading" defaultMessage="Loading..." /> : 
                          <FormattedMessage id="Login.form.submit.button.label.login" defaultMessage="Login" />}
                      </button>
                    </div>
                  </div>
              </div>
            </form>
          </Widget>
        </Container>
        <footer className={s.footer}>
          2019 &copy; Alacarte - <FormattedMessage id="Login.footer.application.label" defaultMessage="Restaurant Management Admin Panel." />
        </footer>
      </div>
    );*/
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage
  };
}

export default injectIntl(withRouter(connect(mapStateToProps)(Login)));

