import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import createAuth0Client from '@auth0/auth0-spa-js';
import {
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import { GAEvent } from '../../components/Tracking';
//import MyWindowPortal from './MyWindowPortal';

import { FormattedMessage, injectIntl } from 'react-intl';
import { factory } from "@gooddata/api-client-bear";

//placeholder={intl.formatMessage({ id: "Login.form.input.username.placeholder", defaultMessage: 'Username'})}
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

class Dashboard extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this._isMounted = false;

    this.state = {
      gooddataUserLogged: false,
      showWindowPortal: false,
      isPopupBlocked: false
    };
    this.login = this.login.bind(this)
    this.checkIfUserIsLoggedIn = this.checkIfUserIsLoggedIn.bind(this)
    this.checkIfUserIsLoggedInToAuth0 = this.checkIfUserIsLoggedInToAuth0.bind(this)
  }

  async login() {
    this.setState(state => ({
      ...state,
      showWindowPortal: !state.showWindowPortal,
    }));
  }

  async componentDidMount() {
    /* if (!localStorage.getItem('gd_sso_initiated')) {
      localStorage.setItem('gd_sso_initiated', 'false')
    } */
    this._isMounted = true;
    this.checkIfUserIsLoggedIn()
    // this.checkIfUserIsLoggedInToAuth0()
    GAEvent("ADMINPANEL", "Open dashboard", "DASHBOARD_PAGE_OPENED");
  }


  componentWillUnmount() {
    this._isMounted = false;
  }

  async checkIfUserIsLoggedInToAuth0() {
    const auth0AlacarteAnalyticsClient = await createAuth0Client({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      client_id: process.env.REACT_APP_AUTH0_DASHBOARD_CLIENT_ID
    });
    // console.log('logout auth0 => ', auth0)
    if (auth0AlacarteAnalyticsClient) {
      console.log('user is logged in to Auth0')
    }
  }

  async checkIfUserIsLoggedIn() {
    const domain = process.env.REACT_APP_GOODDATA_DOMAIN;
    const sdk = factory({ domain });
    try {
      sleep(2000).then(async () => {
        await sdk.user.isLoggedIn()
        .then(async (isLogged) => {
          // console.log('goodata user isLogged =>', isLogged)
          //let showWindowPortal = this.state.showWindowPortal
          if(!isLogged /*&& localStorage.getItem('gd_sso_initiated') === 'false'*//*&& !this.state.showWindowPortal*/) {
            sdk.user.initiateSamlSso(window.location.href)
            // localStorage.setItem('gd_sso_initiated', 'true')
            // showWindowPortal = !this.state.showWindowPortal
          } /* else {
            localStorage.setItem('gd_sso_initiated', 'false')
          } */
          this.setState({
            gooddataUserLogged: isLogged,
            //showWindowPortal
          })
        })
        .catch((error) => console.log('error testing if gooddate user logged in => ', error))
      })
    } catch (e) {
      console.log('error gooddata logging in user =>', e)
    }
  }
  
  render() {

    return (
      <div style={{height: "900px"}}>
        <Breadcrumb>
          <BreadcrumbItem>{this.props.managedRestaurant ? this.props.managedRestaurant.name : '...'}</BreadcrumbItem>
          <BreadcrumbItem active><FormattedMessage id="MyDashboard.Breadcrumbitem.MyDashboard.Label" defaultMessage="My Dashboard" /></BreadcrumbItem>
        </Breadcrumb>
        {/* !this.state.gooddataUserLogged ? <button onClick={() => this.login()}>Login</button> : null */}
        { /*this.state.showWindowPortal && (
            <MyWindowPortal 
              urlToOpen={process.env.REACT_APP_AUTH0_IDP_LOGIN_URL_FOR_SSO} 
              onClose={(isPopupBlocked)=> {
                if (isPopupBlocked) {
                  this.setState({isPopupBlocked})
                } else {
                  this.checkIfUserIsLoggedIn();
                }
              }}>
            </MyWindowPortal>
          )*/
        }
        {
          this.state.gooddataUserLogged ?
          // true ?
          <iframe 
            title="Alacarte dashboard"
            src={process.env.REACT_APP_GOODDATA_DASHBOARD_EMBED_URL}
            width="100%" height="100%" frameBorder="0">
          </iframe> : 
          this.state.isPopupBlocked ? 
            <div>You are using a popup blocker, please do allow for popups on this page and then refresh the page. It is recommended not to use private/incognito mode in order to work on Alacarte. Thank you.</div> 
            /*: !this.state.gooddataUserLogged && localStorage.getItem('gd_sso_initiated') === 'false'? 
              <div>There was an issue loading the dashboard, please do reload the page and if the issue persist please do logout and login again.</div>*/
            : <div>Loading...</div>
        }
      </div>
    );
  } // end of render
} // end of class

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
    managedRestaurant: state.auth.managedRestaurant
  };
}

export default injectIntl(withRouter(connect(mapStateToProps)(Dashboard)));
