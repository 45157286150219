import React from 'react';
import PropTypes from 'prop-types';
import Widget from '../../../../components/Widget';
import './AddongroupsTable.modules.scss';
import {FormattedMessage} from 'react-intl';
import {
  Row,
  Col,
  Input,
  Label
} from 'reactstrap';

import AddongroupItemsTable from '../AddongroupItemsTable/AddongroupItemsTable';

class AddongroupsTable extends React.Component {

  static propTypes = {
    addongroups: PropTypes.array.isRequired,
    currency: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      addongroups: this.props.addongroups,
      currency: this.props.currency,
      locale: this.props.locale
    };
  }

  render() {

    const {addongroups, currency, locale} = this.state;

    return (
      <div>
        {
          addongroups.map((addongroup, index) => {

            let inputForSwitcher = <Input type="checkbox" className="ios" disabled defaultChecked />;

            if(!addongroup.isMandatory)
              inputForSwitcher = <Input type="checkbox" className="ios"  disabled />;

            const inputForIsMandatorySwitcher = (
                <Label className="switch" key={"switchIsMandatory-"+addongroup.id}>
                  {inputForSwitcher}
                  <i /> <span className="minmaxdescription">{addongroup.isMandatory? "(min: " + addongroup.minNumberWhenMandatory + " - max: " + addongroup.maxNumberWhenMandatory + ")": null}</span>
                </Label>
            );

            if(!addongroup.isAllowAddonItemMultipleTimes)
              inputForSwitcher = <Input type="checkbox" className="ios"  disabled />;
            else
              inputForSwitcher = <Input type="checkbox" className="ios" disabled defaultChecked />;

            const inputForIsAoiAllowedMultipleTimesSwitcher = (
                <Label className="switch" key={"switchMiAllowedMultipleTimes-"+addongroup.id}>
                  {inputForSwitcher}
                  <i />
                </Label>
            );

            if(!addongroup.isMultipleSelectionAllowed)
              inputForSwitcher = <Input type="checkbox" className="ios"  disabled />;
            else
              inputForSwitcher = <Input type="checkbox" className="ios" disabled defaultChecked />;

            const inputForIsMultipleSelectionAllowed = (
              <Label className="switch" key={"switchMultipleSelectionAllowed-"+addongroup.id}>
                  {inputForSwitcher}
                  <i />
                </Label>
            );

            return (
              <Widget key={"addongrouptablecell-" + addongroup.id} 
                      title={
                              <h4>
                                <span className="fw-semi-bold">
                                   {addongroup.name_i18n[locale]}
                                </span>
                              </h4>
                            } 
                      collapse>
                      <Row id="addongroupRowDetails" key={"addongrouptablecellrow-" + addongroup.id}>
                        <Col md={4} sm={12}>
                            <FormattedMessage id="AddongroupsTable.isMandatory.label"
                                                              defaultMessage="Mandatory?"/>
                            {inputForIsMandatorySwitcher}
                        </Col>
                        <Col md={4} sm={12}>
                          <FormattedMessage id="AddongroupsTable.isAllowAddonItemMultipleTimes.label"
                                                                defaultMessage="Add-on item allowed multiple times?"/>
                              {inputForIsAoiAllowedMultipleTimesSwitcher}
                        </Col>
                        <Col md={4} sm={12}>
                          <FormattedMessage id="AddongroupsTable.isMultipleSelectionAllowed.label"
                                                                defaultMessage="Multiple selection allowed?"/>
                              {inputForIsMultipleSelectionAllowed}
                        </Col>
                      </Row>
                      <AddongroupItemsTable addongroupitems={addongroup.addonitems}
                                            currency={currency}
                                            locale={locale}/>
                </Widget>
              );
          })
        }
      </div>
    );
  }

}

export default AddongroupsTable;
