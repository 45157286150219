// import AlertSound from "../assets/audiofiles/alert.mp3";

export const SOCKET_CONNECTION_INIT = 'SOCKET_CONNECTION_INIT';
export const SOCKET_CONNECTION_SUCCESS = 'SOCKET_CONNECTION_SUCCESS';
export const SOCKET_CONNECTION_ERROR = 'SOCKET_CONNECTION_ERROR';
export const SOCKET_CONNECTION_CLOSED = 'SOCKET_CONNECTION_CLOSED';
export const SOCKET_ON_MESSAGE_RECEIVED = 'SOCKET_ON_MESSAGE_RECEIVED';

let wsClient = null;

export function initializeSocket(restaurantSlug) {
    return (dispatch) => {
      // set the socket.io endpoint
      //const env = runtimeEnv();
      /* const  endpoint = (process.env.REACT_APP_STRAPI_PROTOCOL || 'http') +
      '://' + (process.env.REACT_APP_STRAPI_HOST || 'localhost') +
       ":" + (process.env.REACT_APP_STRAPI_PORT || '1337'); */
      const urlToWebsocketServer = `${process.env.REACT_APP_ALACARTE_WSS_URL}?restaurant-slug=${restaurantSlug}&mode=ap`;

      wsClient = new WebSocket(urlToWebsocketServer);

      const setupHeartbeat = (socket) => {
        // console.log(new Date(), 'wss heartbeat')
        if (!socket) return
        if (socket.readyState !== 1) return
        socket.send(JSON.stringify({'message': 'ping'}));
        // console.log('sent heartbeat message to server')
        setTimeout(() => {
          setupHeartbeat(socket)
        }, 30000)
      };

      wsClient.addEventListener("open", () => {
        setupHeartbeat(wsClient)
        dispatch(socketConnectionSuccess());
      });
      // listen server side error
      wsClient.addEventListener("error", error => {
        console.log(
          "error message from server => ",
          "Closing socket..."
        );
        wsClient.close();
        dispatch(socketConnectionError());  
        // throw new Error(error)
      });

      wsClient.addEventListener("message", async data => {
        // console.log('data gastrofix websocket => ', data)
        const receivedMessage = JSON.parse(data.data);
        const { action, response } = receivedMessage;
        const { v4: uuidv4 } = require('uuid');
        switch (action) {
          case 'Default':
            console.log("received websocket data => ", response);
            break;
          case 'Ping':
            console.log("received websocket data => ", response);
            break;
          case 'Order-Updated': {
            receivedMessage.uuid = uuidv4();
            dispatch(socketOnMessageReceived(receivedMessage));
            break;
          }
          case 'Order-Created': {
            // const alertAudio = new Audio(AlertSound)
            // alertAudio.play()
            try {
              const audioToPlay = document.getElementById('newOrderAlertSound')
              var promise = audioToPlay.play()
              audioToPlay.muted = false
              if (promise !== undefined) {
                  promise.catch(error => {
                      // Auto-play was prevented
                      // Show a UI element to let the user manually start playback
                  }).then(() => {
                      // Auto-play started
                  });
              }
              receivedMessage.uuid = uuidv4();
              dispatch(socketOnMessageReceived(receivedMessage));
            } catch(e) {
              console.log(e)
            }
            break;
          }
          default:
            break;
        }
        
      });

      // we use this flag to check if the user forced to close the websocket connection manually
      // like for example a logout action
      wsClient.isForcedToClose = false;

      wsClient.addEventListener('close', (reason) => {
        dispatch(socketConnectionClosed()); 
        if (!wsClient.isForcedToClose) {
          // we check first is it is not an intentional close event from the user
          console.log(new Date(), `[----- ADMINPANEL WEBSOCKET DISCONNECTED from ALACARTE reconnect will be attempted in 1 second -----> ${reason} ]`)
          setTimeout(() => {
            // console.log('restarting connection to alacarte websocket server for ', restaurantSlug);
            dispatch(initializeSocket(restaurantSlug))
          }, 1000)
        } else {
          console.log(new Date(), `[----- ADMINPANEL WEBSOCKET FORCED from ALACARTE WSS service]`);
          wsClient = null;
        }
      })
      
      dispatch(socketConnectionInit(wsClient));
    }
  }
  
  function socketConnectionInit(socket) {
    return {
      type: SOCKET_CONNECTION_INIT,
      socket,
    };
  }
  
  function socketConnectionSuccess() {
    return {
      type: SOCKET_CONNECTION_SUCCESS,
    };
  }
  
  function socketConnectionError() {
    return {
      type: SOCKET_CONNECTION_ERROR,
    };
  }

  function socketOnMessageReceived(messageObject) {
    return {
      type: SOCKET_ON_MESSAGE_RECEIVED,
      messageObject
    };
  }
  
  function socketConnectionClosed() {
    return {
      type: SOCKET_CONNECTION_CLOSED,
    };
  }