import { CHANGE_LOCALE_ACTIVE_ITEM } from '../actions/locale';

export default function runtime(state = {
  activeLocaleItem: null,
  nonActiveLocales: null,
  activeLocaleMessages: null
}, action) {
  switch (action.type) {
    case CHANGE_LOCALE_ACTIVE_ITEM:
      return {
        ...state,
        activeLocaleItem: action.activeLocaleItem,
        activeLocaleMessages: action.messages,
        nonActiveLocales: action.nonActiveLocales
      };
    default:
      return state;
  }
}
