import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, withRouter, Redirect } from 'react-router';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
//import $ from 'jquery';
import Hammer from 'rc-hammerjs';

import ErrorPage from '../../pages/error';

//import DashboardAnalytics from '../../pages/analytics';
import Profile from '../../pages/profile';
import Menus from '../../pages/menus';
import Orders from '../../pages/orders';
import Menuitems from '../../pages/menuitems';
import MenuItem from '../../pages/menuitem';
import DataManagement from '../../pages/datamanagement';
import DashboardComponent from '../../pages/dashboard';

import Header from '../Header';
import Sidebar from '../Sidebar';
//import Helper from '../Helper';
import { openSidebar, closeSidebar, changeActiveSidebarItem, toggleSidebar } from '../../actions/navigation';
import s from './Layout.module.scss';
import { FormattedMessage } from 'react-intl'; 

class Layout extends React.Component {
  static propTypes = {
    sidebarStatic: PropTypes.bool,
    sidebarOpened: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    sidebarStatic: false,
    sidebarOpened: false,
  };
  constructor(props) {
    super(props);

    this.handleSwipe = this.handleSwipe.bind(this);
  }

  componentDidMount() {
    const staticSidebar = JSON.parse(localStorage.getItem('staticSidebar'));
    if (staticSidebar && window.innerWidth > 768) {
      this.props.dispatch(toggleSidebar());
    } else if (this.props.sidebarOpened) {
      setTimeout(() => {
        this.props.dispatch(closeSidebar());
        this.props.dispatch(changeActiveSidebarItem(null));
      }, 2500);
    }

    this.handleResize();
    window.addEventListener('resize', this.handleResize.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize.bind(this));
  }

  handleResize() {
    if (window.innerWidth <= 768 && this.props.sidebarStatic) {
      this.props.dispatch(toggleSidebar());
    }
  }

  handleSwipe(e) {
    if ('ontouchstart' in window) {
      if (e.direction === 4) {
        this.props.dispatch(openSidebar());
        return;
      }

      if (e.direction === 2 && this.props.sidebarOpened) {
        this.props.dispatch(closeSidebar());
        return;
      }
    }
  }

  render() {
    return (
      <div
        className={[
          s.root,
          this.props.sidebarStatic ? s.sidebarStatic : '',
          !this.props.sidebarOpened ? s.sidebarClose : '',
        ].join(' ')}
      >
        <Sidebar />
        <div className={s.wrap}>
          <Header />
          {/*<Helper />*/}
          <Hammer onSwipe={this.handleSwipe}>
            <main className={s.content}>
              <TransitionGroup>
                <CSSTransition
                  key={this.props.location.pathname}
                  classNames="fade"
                  timeout={200}
                >
                  <Switch>
                    <Route path="/app/main" exact render={() => <Redirect to="/app/orders" />} />
                    {/*<Route path="/app/main" exact render={() => <Redirect to="/app/dashboard" />} />*/}
                    {/*<Route path="/app/main/analytics" exact component={DashboardAnalytics} />*/}
                    <Route path="/app/dashboard" exact component={DashboardComponent} />
                    <Route path="/app/profile" exact component={Profile} />
                    <Route path="/app/menus" exact component={Menus} />
                    <Route path="/app/orders" exact component={Orders} />
                    <Route path="/app/datamanagement" exact component={DataManagement} />
                    <Route path="/app/menus/:menuid" exact component={Menuitems} />
                    <Route path="/app/restaurant/:restaurantid/menu/:menuid/menucategory/:menucategoryid/menuitem/:menuitemid" exact component={MenuItem} />
                    <Route path="/app/restaurant/:restaurantid/menu/:menuid/menucategory/:menucategoryid/menugroup/:menugroupid/menuitem/:menuitemid" exact component={MenuItem} />
                    <Route path="/app/restaurant/:restaurantid/menu/:menuid/menucategory/:menucategoryid/menugroup/:menugroupid/menuitemsgroup/:menuitemsgroupid/menuitem/:menuitemid" exact component={MenuItem} />
                    <Route component={ErrorPage} />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
              <footer className={s.contentFooter}>
                2019 &copy; Alacarte - <FormattedMessage id="Layout.footer.application.label" defaultMessage="Restaurant Management Admin Panel." />
              </footer>
            </main>
          </Hammer>
        </div>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    sidebarStatic: store.navigation.sidebarStatic,
  };
}

export default withRouter(connect(mapStateToProps)(Layout));
