import {
  LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT_SUCCESS, USERME_REQUEST, USERME_SUCCESS, USERME_FAILURE,
  UPDATE_USER_PWD_FAILURE, UPDATE_USER_PWD_SUCCESS, UPDATE_USER_PWD_REQUEST
} from '../actions/user';

const idToken = localStorage.getItem('id_token');
// The auth reducer. The starting state sets authentication
// based on a token being in local storage. In a real app,
// we would also want a util to check if the token is expired.
export default function auth(state = {
  isFetching: false,
  isAuthenticated: !!idToken,
  errorMessage: ''
}, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false,
        user: action.creds,
        errorMessage: ''
      });
    case LOGIN_SUCCESS:
      //console.log('in reducer action.userInfo class => ', toString.call(action.userInfo));
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: true,
        id_token: action.id_token,
        userInfo: toString.call(action.userInfo) === "[object String]" ? JSON.parse(action.userInfo) : action.userInfo,
        errorMessage: '',
      });
    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        errorMessage: action.message,
      });
    case USERME_REQUEST:
    return Object.assign({}, state, {
      isFetching: true,
      errorMessage: ''
      });
    case USERME_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: '',
        managedRestaurant: action.managedRestaurant
      });
    case USERME_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.message,
      });
    case UPDATE_USER_PWD_REQUEST:
    return Object.assign({}, state, {
      isFetching: true,
      errorMessage: ''
      });
    case UPDATE_USER_PWD_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: '',
      });
    case UPDATE_USER_PWD_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.message,
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isAuthenticated: false,
        errorMessage: ''
      });
    default:
      return state;
  }
}
