import { MENUITEMS_REQUEST , MENUITEMS_SUCCESS, MENUITEMS_FAILURE,
         MENUITEM_IS_ACTIVE_UPDATE_REQUEST, MENUITEM_IS_ACTIVE_UPDATE_SUCCESS, MENUITEM_IS_ACTIVE_UPDATE_FAILURE,
         MENUITEM_DETAILS_REQUEST , MENUITEM_DETAILS_SUCCESS, MENUITEM_DETAILS_FAILURE,
         MENUITEMSIZE_IS_AVAILABLE_FOR_DINE_OUT_UPDATE_REQUEST, 
         MENUITEMSIZE_IS_AVAILABLE_FOR_DINE_OUT_UPDATE_SUCCESS, 
         MENUITEMSIZE_IS_AVAILABLE_FOR_DINE_OUT_UPDATE_FAILURE,
         MENUITEMSIZE_IS_AVAILABLE_FOR_DINE_IN_UPDATE_REQUEST, 
         MENUITEMSIZE_IS_AVAILABLE_FOR_DINE_IN_UPDATE_SUCCESS, 
         MENUITEMSIZE_IS_AVAILABLE_FOR_DINE_IN_UPDATE_FAILURE} 
         from '../actions/menuitems';

// The menu reducer.
export default function menuitem(state = {
  isFetching: false,
  restaurantMenuItems: {},
  updatedMenuItem: {},
  menuitemDetails: {},
  errorMessage: ''
}, action) {
  switch (action.type) {
    case MENUITEMS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        errorMessage: ''
      });
    case MENUITEMS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        restaurantMenuItems: action.restaurantMenuItems,
        errorMessage: '',
      });
    case MENUITEMS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.message,
      });
    case MENUITEM_IS_ACTIVE_UPDATE_REQUEST:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        errorMessage: ''
      });
    case MENUITEM_IS_ACTIVE_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        updatedMenuItem: action.updatedMenuItem,
        errorMessage: '',
      });
    case MENUITEM_IS_ACTIVE_UPDATE_FAILURE:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        errorMessage: action.message,
      });
    case MENUITEMSIZE_IS_AVAILABLE_FOR_DINE_OUT_UPDATE_REQUEST:
      return Object.assign({}, state, {
        isFetchingMenuItemSizeDineOut: action.isFetchingMenuItemSizeDineOut,
        errorMessage: ''
      });
    case MENUITEMSIZE_IS_AVAILABLE_FOR_DINE_OUT_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        isFetchingMenuItemSizeDineOut: action.isFetchingMenuItemSizeDineOut,
        updatedMenuItemSize: action.updatedMenuItemSize,
        errorMessage: '',
      });
    case MENUITEMSIZE_IS_AVAILABLE_FOR_DINE_OUT_UPDATE_FAILURE:
      return Object.assign({}, state, {
        isFetchingMenuItemSizeDineOut: action.isFetchingMenuItemSizeDineOut,
        errorMessage: action.message,
      });
    case MENUITEMSIZE_IS_AVAILABLE_FOR_DINE_IN_UPDATE_REQUEST:
      return Object.assign({}, state, {
        isFetchingMenuItemSizeDineIn: action.isFetchingMenuItemSizeDineIn,
        errorMessage: ''
      });
    case MENUITEMSIZE_IS_AVAILABLE_FOR_DINE_IN_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        isFetchingMenuItemSizeDineIn: action.isFetchingMenuItemSizeDineIn,
        updatedMenuItemSize: action.updatedMenuItemSize,
        errorMessage: '',
      });
    case MENUITEMSIZE_IS_AVAILABLE_FOR_DINE_IN_UPDATE_FAILURE:
      return Object.assign({}, state, {
        isFetchingMenuItemSizeDineIn: action.isFetchingMenuItemSizeDineIn,
        errorMessage: action.message,
      });
    case MENUITEM_DETAILS_REQUEST:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        errorMessage: ''
      });
    case MENUITEM_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        restaurantMenuItemDetails: action.restaurantMenuItemDetails,
        errorMessage: '',
      });
    case MENUITEM_DETAILS_FAILURE:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        errorMessage: action.message,
      });
    default:
      return state;
  }
}
