import React from 'react';
import PropTypes from 'prop-types';

import General from '../General/General';
//import Selects from '../Selects/Selects';
//import Bag from '../Bag/Bag';

/*import mastercard from '../../../../images/payments/mastercard.svg';
import visa from '../../../../images/payments/visa.svg';
import aexpress from '../../../../images/payments/aexpress.svg';
import paypal from '../../../../images/payments/paypal.svg';*/

import CarouselMenuItem from '../../../../components/CarouselMenuItem/CarouselMenuItem';
import chefHat from '../../../../images/chef/chef-hat.svg';

import { FormattedMessage } from 'react-intl'; 

import Allergens from '../Allergens/Allergens';

import {
  Tooltip,
} from 'reactstrap';

import s from './Banner.module.scss';
//import { all } from 'q';
class Banner extends React.Component {
  static propTypes = {
    menuItem: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this._isMounted = false;

    this.state = {
      tooltipsRecommendedByTheChef: []
    }

    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
      this._isMounted = false;
  }

  toggleTooltip(id, field) {
    const newState = [...this.state[field]];
    newState.fill(false);

    if (!this.state[field][id]) {
      newState[id] = true;
    }

    this._isMounted && this.setState({
      [field]: newState,
    });
  }
  
  render() {

  const {menuItem, locale} = this.props;
  
  return (
    <div className={s.productDetailsBanner}>
      <div className={s.productPhoto}>
            {menuItem ?
            <div className={`${s.picture} card`}>
              <CarouselMenuItem menuItem={menuItem} height={"427px"} isLightboxEnabled={true} />
              {
                menuItem.isRecommendedByTheChef ? 
                <div>
                  <div className={s.label + ' bg-danger'} id="ribbon-recommendedByTheChef-0">
                    <img width="15px" src={chefHat} alt="" />
                  </div>
                  <Tooltip placement="top" 
                    isOpen={this.state.tooltipsRecommendedByTheChef[0]} 
                    toggle={() => this.toggleTooltip(0,"tooltipsRecommendedByTheChef")} 
                      target="ribbon-recommendedByTheChef-0">
                        <FormattedMessage id="MenuItem.details.page.banner.ribbon.recommandedByTheChef" defaultMessage="Recommended by the Chef" />
                  </Tooltip>
                </div>
                : null
              }
            </div>
            : null}
      </div>
      <div className={s.productInfo}>
        <General 
         /*rating={4.8}*/
         title={menuItem ? menuItem.name_i18n[locale] : null}
         subtitle={menuItem && menuItem.foodcategory ? menuItem.foodcategory.name_i18n[locale] : null}
         /*price={76}*/
         listOfFoodTypes={menuItem.foodtypes}
         locale={locale}
         isRealPicture={menuItem ? menuItem.isRealPictures : null}
          />
          <span className={s.productDescription} dangerouslySetInnerHTML={{__html: menuItem.description_i18n[locale]}}></span>
        {/*<button className={`btn-link ${s.productGuide}`}>Size Guide</button>*/}
        {/*<Bag />*/}
        {
          menuItem ?
            (
              menuItem.allergens.length > 0 ?
                  <Allergens listOfAllergens={menuItem.allergens} locale={locale} islistview={false} />
                :
                null
            )
          :null
        }
        {/*<div className={s.payments}>
          <div style={{ backgroundImage: `url(${visa})` }} />
          <div style={{ backgroundImage: `url(${mastercard})` }} />
          <div style={{ backgroundImage: `url(${aexpress})` }} />
          <div style={{ backgroundImage: `url(${paypal})` }} />
      </div>*/}
        {/*<span className={s.delivery}>FREE Delivery & Returns</span>
        <Selects sizes={[1, 2, 3, 4, 5]} quantity={[1, 2, 3, 4, 5, 6, 7]} />*/}
      </div>
    </div >
    );
  }
}

export default Banner;
