import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  ButtonGroup,
  Button
} from 'reactstrap';

import s from './DaysAvailabilityBar.module.scss';
import { GAEvent } from "../../../components/Tracking/Tracking";

import { FormattedMessage, injectIntl } from 'react-intl'; 

class DaysAvailabilityBar extends Component {
  static propTypes = {
    menu: PropTypes.object.isRequired,
    onClick: PropTypes.func
  }

  constructor(props) {
    super(props);

    this.state = {
      listOfDays: {}
    };

    this.dayClicked = this.dayClicked.bind(this);
  }

  componentDidMount() {
    const { weekdaysAvailableOn  } = this.props.menu;
    // console.log('weekdaysAvailableOn => ', weekdaysAvailableOn)
    const tempListOfDays = {
      monday: {
        isActive: weekdaysAvailableOn.filter(weekday => weekday.code === 'monday').length > 0
      },
      tuesday: {
        isActive: weekdaysAvailableOn.filter(weekday => weekday.code === 'tuesday').length > 0
      },
      wednesday: {
        isActive: weekdaysAvailableOn.filter(weekday => weekday.code === 'wednesday').length > 0
      },
      thursday: {
        isActive: weekdaysAvailableOn.filter(weekday => weekday.code === 'thursday').length > 0
      },
      friday: {
        isActive: weekdaysAvailableOn.filter(weekday => weekday.code === 'friday').length > 0
      },
      saturday: {
        isActive: weekdaysAvailableOn.filter(weekday => weekday.code === 'saturday').length > 0
      },
      sunday: {
        isActive: weekdaysAvailableOn.filter(weekday => weekday.code === 'sunday').length > 0
      },
    }
    // console.log('tempListOfDays => ', tempListOfDays)
    this.setState({
      listOfDays: tempListOfDays
    })
  }

  dayClicked(dayCode) {
    console.log('day clicked => ', this.props.menu.id, dayCode);
  }

  render() {
    const { listOfDays } = this.state;
    const dayOnStyle = {
      background: "greenyellow",
      fontWeight: 500
    };
    const dayOffStyle = {
      background: "rgb(255 127 127)",
      fontWeight: 500
    }
    return (
      // crimson greenyellow
      <div className={s.barOfButtons}>
        <ButtonGroup>
          <Button onClick={() => {
            this.dayClicked('monday');
            GAEvent("ADMINPANEL", "Click on set menu day availability", "MENUS_SET_MENU_DAY_AVAILABILITY");
          }} style={listOfDays.monday && listOfDays.monday.isActive ? dayOnStyle : dayOffStyle}>
              <FormattedMessage id="DaysAvailabilityBar.Monday.Button.Label" defaultMessage="M" />
            </Button>
          <Button onClick={() => {
            this.dayClicked('tuesday');
            GAEvent("ADMINPANEL", "Click on set menu day availability", "MENUS_SET_MENU_DAY_AVAILABILITY");
          }} style={listOfDays.tuesday && listOfDays.tuesday.isActive ? dayOnStyle : dayOffStyle}>
             <FormattedMessage id="DaysAvailabilityBar.Tuesday.Button.Label" defaultMessage="T" />
            </Button>
          <Button onClick={() => {
            this.dayClicked('wednesday');
            GAEvent("ADMINPANEL", "Click on set menu day availability", "MENUS_SET_MENU_DAY_AVAILABILITY");
          }} style={listOfDays.wednesday && listOfDays.wednesday.isActive ? dayOnStyle : dayOffStyle}>
              <FormattedMessage id="DaysAvailabilityBar.Wednesday.Button.Label" defaultMessage="W" />
            </Button>
          <Button onClick={() => {
            this.dayClicked('thursday');
            GAEvent("ADMINPANEL", "Click on set menu day availability", "MENUS_SET_MENU_DAY_AVAILABILITY");
          }} style={listOfDays.thursday && listOfDays.thursday.isActive ? dayOnStyle : dayOffStyle}>
            <FormattedMessage id="DaysAvailabilityBar.Thursday.Button.Label" defaultMessage="T" />
          </Button>
          <Button onClick={() => {
            this.dayClicked('friday');
            GAEvent("ADMINPANEL", "Click on set menu day availability", "MENUS_SET_MENU_DAY_AVAILABILITY");
          }} style={listOfDays.friday && listOfDays.friday.isActive ? dayOnStyle : dayOffStyle}>
            <FormattedMessage id="DaysAvailabilityBar.Friday.Button.Label" defaultMessage="F" />
          </Button>
          <Button onClick={() => {
            this.dayClicked('saturday');
            GAEvent("ADMINPANEL", "Click on set menu day availability", "MENUS_SET_MENU_DAY_AVAILABILITY");
          }} style={listOfDays.saturday && listOfDays.saturday.isActive ? dayOnStyle : dayOffStyle}>
             <FormattedMessage id="DaysAvailabilityBar.Saturday.Button.Label" defaultMessage="S" />
          </Button>
          <Button onClick={() => {
            this.dayClicked('sunday');
            GAEvent("ADMINPANEL", "Click on set menu day availability", "MENUS_SET_MENU_DAY_AVAILABILITY");
          }} style={listOfDays.sunday && listOfDays.sunday.isActive ? dayOnStyle : dayOffStyle}>
            <FormattedMessage id="DaysAvailabilityBar.Sunday.Button.Label" defaultMessage="S" />
          </Button>
        </ButtonGroup>
      </div>
    );
  }
}

export default injectIntl(DaysAvailabilityBar);

