import React from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Row,
  Tooltip,
  ButtonGroup,
  Button
} from 'reactstrap';

import { FormattedMessage } from 'react-intl'; 

import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
//import Section from './components/Section/Section';
import Banner from './components/Banner/Banner';
import PriceTable from './components/PriceTable/PriceTable';
import AddongroupsTable from './components/AddongroupsTable/AddongroupsTable';
//import Description from './components/Description/Description';
//import mock from './mock';
import { GAEvent } from '../../components/Tracking/Tracking';

import { getMenuItemDetailsFromServer } from '../../actions/menuitems';

import s from './MenuItem.module.scss';


import { /*FormattedMessage,*/ injectIntl } from 'react-intl'; 
import ProgressIndicator from '../../components/ProgressIndicator';

// flag to indicate a call to the server is being done
let isAFetchFromApiInProgress = false;

class MenuItem extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this._isMounted = false;

    this.state = {
      tooltipRefreshDataFromServer: []
    }

    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
    this.refreshMenuItemDetailsFromServer = this.refreshMenuItemDetailsFromServer.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }
  
  componentDidMount() {
    this._isMounted = true;
    // retrieve data from server if needed
    //console.log('did mount');
    this.refreshMenuItemDetailsFromServer();
  }

  componentWillUnmount() {
    this._isMounted = false;
    // put the flag back to false so when the component will be loaded again we will
    // be able to call the server
    isAFetchFromApiInProgress = false;
  }

  toggleTooltip(id, field) {
    const newState = [...this.state[field]];
    newState.fill(false);

    if (!this.state[field][id]) {
      newState[id] = true;
    }

    this._isMounted && this.setState({
      [field]: newState,
    });
  }
  
  refreshMenuItemDetailsFromServer(forceRefresh = false) {
    const {dispatch} = this.props;
    const restaurantId = this.props.match.params.restaurantid;
    const menuId = this.props.match.params.menuid;
    const menucategoryId = this.props.match.params.menucategoryid;
    const menugroupId = this.props.match.params.menugroupid;
    const menuitemsgroupId = this.props.match.params.menuitemsgroupid;
    const orderedMenuItemId = this.props.match.params.menuitemid;

    //console.log('this.props.restaurantMenuItemDetails => ', this.props.restaurantMenuItemDetails);
    if ((this.props.restaurantMenuItemDetails === undefined || forceRefresh) && !this.props.isFetching) {
      isAFetchFromApiInProgress = true;
      dispatch(getMenuItemDetailsFromServer(restaurantId, menuId, menucategoryId, menugroupId, menuitemsgroupId, orderedMenuItemId));
    }
  }

  componentDidUpdate(newProps) {
    const newOrderedMenuItemId = newProps.match.params.menuitemid;
    const newMenuGroupId = newProps.match.params.menugroupid;
    const restaurantIdInURL = newProps.match.params.restaurantid;
    const menuitemsgroupId = newProps.match.params.menuitemsgroupid;

    let currentOrderedMenuItemId;
    // we check if we are loading a new menu item to refresh the data from the server
    if(this.props.restaurantMenuItemDetails && this.props.restaurantMenuItemDetails.restaurant) {
      //console.log('this.props.restaurantMenuItemDetails in didUpdate => ', this.props.restaurantMenuItemDetails);
      const currentRestaurant =  this.props.restaurantMenuItemDetails.restaurant;
      const currentMenu = currentRestaurant.menus[0];
      const currentMenuCategory = currentMenu.menucategories[0];
      
      if(currentMenuCategory && currentMenuCategory.menugroups && newMenuGroupId) {
        // if we are in menu items group mode
          if(menuitemsgroupId) {
            // menu items group mode
            currentOrderedMenuItemId = currentMenuCategory.menugroups[0].menuitemgroups[0].orderedmenuitems[0].id;
          } else {
            // me group mode
            currentOrderedMenuItemId = currentMenuCategory.menugroups[0].orderedmenuitems[0].id;
          }
      } else {
        // in menu category mode
        if(currentMenuCategory && currentMenuCategory.orderedmenuitems)
          currentOrderedMenuItemId = currentMenuCategory.orderedmenuitems[0].id;
      }
      //console.log('newOrderedMenuItemId => ', newOrderedMenuItemId);
      //console.log('currentOrderedMenuItemId => ', currentOrderedMenuItemId);
      if(newOrderedMenuItemId !== currentOrderedMenuItemId &&
        !this.props.isFetching &&
        !newProps.isFetching &&
        !isAFetchFromApiInProgress) {
        //console.log('new menu item loaded!!');
        this.refreshMenuItemDetailsFromServer(true);
      }
    }

    // if we switched restaurants
    if (this.props.managedRestaurant && newProps.managedRestaurant &&
      this.props.managedRestaurant.id !== newProps.managedRestaurant.id) {
      this.refreshMenuItemDetailsFromServer(true);
    }

    //console.log('restaurantIdInURL didUpdate => ', restaurantIdInURL);
    // in case the restaurant id is not defined in the url
    if(restaurantIdInURL === "undefined" || restaurantIdInURL === undefined) {
      //console.log('wer are redirecting');
      this.props.history.push('/error');
    }

  }

  render() {
    const menuId = this.props.match.params.menuid;
    // const menucategoryId = this.props.match.params.menucategoryid;
    const menugroupId = this.props.match.params.menugroupid;
    const menuitemsgroupId = this.props.match.params.menuitemsgroupid;
    let currentRestaurant, currentMenu, currentMenuCategory, currentMenuGroup, currentMenuItem, currencyForRestaurant;
    //const isMenuGroupMode = menugroupId && !menuitemsgroupId;
    const isMenuItemsGroupMode = menugroupId && menuitemsgroupId;
    const {locale} = this.props.intl;

    if(this.props.restaurantMenuItemDetails &&
      this.props.restaurantMenuItemDetails.restaurant) {
      //console.log('this.props.restaurantMenuItemDetails in render => ', this.props.restaurantMenuItemDetails);
      currentRestaurant =  this.props.restaurantMenuItemDetails.restaurant;
      currentMenu = currentRestaurant.menus[0];
      currentMenuCategory = currentMenu.menucategories[0];
      currencyForRestaurant = currentRestaurant.city.country.currency;
      

      // if we are in menu group mode
      if(currentMenuCategory && currentMenuCategory.menugroups && menugroupId) {
        currentMenuGroup = currentMenuCategory.menugroups[0];
        // if in menuitem groups mode
        if (menuitemsgroupId) {
          currentMenuItem = currentMenuCategory.menugroups[0].menuitemgroups[0].orderedmenuitems[0].menuitem;
          currentMenuItem.orderedMenuItemId = currentMenuCategory.menugroups[0].menuitemgroups[0].orderedmenuitems[0].id;
        } else {
          // menu group mode
          currentMenuItem = currentMenuCategory.menugroups[0].orderedmenuitems[0].menuitem;
          currentMenuItem.orderedMenuItemId = currentMenuCategory.menugroups[0].orderedmenuitems[0].id;
        }
      } else {
        // in menu category mode
        if(currentMenuCategory && currentMenuCategory.orderedmenuitems)
          currentMenuItem = currentMenuCategory.orderedmenuitems[0].menuitem;
          currentMenuItem.orderedMenuItemId = currentMenuCategory.orderedmenuitems[0].id;
      }
    }
    //const {restaurant} = this.props.restaurantMenuItemDetails ? this.props.restaurantMenuItemDetails : null;
    //console.log('restaurant in render => ', restaurant);
    //const menuitemId = this.props.match.params.menuitemid;
    //console.log('currentMenuItem => ', currentMenuItem);

    return (
      <div className={s.productDetails}>
        {this.props.isFetching ? <ProgressIndicator />: null}
        <Row>
          <Col lg={9} md={9} sm={7} xs={9}>
            <Breadcrumb style={{marginTop: 7}}>
              <BreadcrumbItem>{currentRestaurant ? currentRestaurant.name : null}</BreadcrumbItem>
              <BreadcrumbItem>{<Link style={{color: 'black', textDecoration: 'underline'}} to="/app/menus">{currentMenu? currentMenu.userLabel_i18n[locale] : null}</Link>}</BreadcrumbItem>
              <BreadcrumbItem>
              {
                !this.props.isFetching ? <Link style={{color: 'black', textDecoration: 'underline'}} to={`/app/menus/${menuId}?${menugroupId ? 'menugroupid=' + menugroupId : ''/*'menucategoryid=' + menucategoryId*/ }&ts=${new Date().getTime()}`}>{currentMenuCategory ? (currentMenuGroup? currentMenuGroup.name_i18n[locale] : currentMenuCategory.name_i18n[locale]) : null}</Link> : "..." 
              }
              </BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col lg={3} md={3} sm={5} xs={3} className="text-right">
            <span>
              <ButtonGroup id="buttonRefreshDataFromServer">
                <Button onClick={() => {
                    this.refreshMenuItemDetailsFromServer(true);
                    GAEvent("ADMINPANEL", "Refresh data from server", "MENUITEM_DETAILS_PAGE_REFRESH_DATA_FROM_SERVER");
                  }
                } color="default"><i className="la la-refresh"></i></Button>
              </ButtonGroup>
              <Tooltip placement="top" isOpen={this.state.tooltipRefreshDataFromServer[0]} toggle={() => this.toggleTooltip(0, 'tooltipRefreshDataFromServer')} 
                target={"buttonRefreshDataFromServer"}>
                <FormattedMessage id="MenuItem.details.button.refresh.data.from.server" defaultMessage="Refresh dish information" />
              </Tooltip>
            </span>
          </Col>
        </Row>
        {currentMenuItem && !this.props.isFetching ? <Banner menuItem={currentMenuItem} locale={locale} /> : null}
        {/*<Section title="Product Description">
          <Description {...mock} />
          </Section>*/}
        {
        currentMenuItem && !this.props.isFetching ?
          (<PriceTable 
            menuitemSizes={currentMenuItem.menuitemsizes}
            orderedMenuItemId={currentMenuItem.orderedMenuItemId}
            isShowPrice= {!isMenuItemsGroupMode}
            currency={currencyForRestaurant} 
            locale={locale} />) : null
        }
        {currentMenuItem && !this.props.isFetching &&
           currentMenuItem.addongroups.length > 0 ? 
            <AddongroupsTable addongroups={currentMenuItem.addongroups}
             currency={currencyForRestaurant} locale={locale} /> : null}
      </div >
    );
  }
}

function mapStateToProps(store) {
  return {
    isFetching: store.menuitem.isFetching,
    errorMessage: store.menuitem.errorMessage,
    restaurantMenuItemDetails: store.menuitem.restaurantMenuItemDetails,
    userInfo: store.auth.userInfo,
    managedRestaurant: store.auth.managedRestaurant
  };
}

export default injectIntl(withRouter(connect(mapStateToProps)(MenuItem)));
