import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import {FormattedMessage} from 'react-intl';


import { updateMenuItemVisibility } from '../../../../actions/menuitems';

import './MenuitemgroupsGallery.modules.scss';
import RealPictureText from '../../../../components/RealPictureText/RealPictureText';
import CarouselMenuItem from '../../../../components/CarouselMenuItem/CarouselMenuItem';
import chefHat from '../../../../images/chef/chef-hat.svg';
import eye from '../../../../images/eyes/eye.svg';
import eyeSlash from '../../../../images/eyes/eye-slash.svg';

class MenuitemgroupsGallery extends React.Component {

  static propTypes = {
    menuitems: PropTypes.array.isRequired,
    restaurantId: PropTypes.string.isRequired,
    menuId: PropTypes.string.isRequired,
    menuCategoryIdForMenuGroup: PropTypes.string.isRequired,
    activeMenuCategoryId: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    menuitemsgroupId: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this._isMounted = false;

    this.state = {
      menuitems: this.props.menuitems,
      locale: this.props.locale,
      isModalHideDishOpen: false,
      isModalShowDishOpen: false,
      tooltipsShowHideDish: [],
      tooltipsRecommendedByTheChef: [],
      selectedDishToHideOrShow: null,
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.toggleModalHideDish = this.toggleModalHideDish.bind(this);
    this.toggleModalShowDish = this.toggleModalShowDish.bind(this);
    this.hideOrShowDishInMenu = this.hideOrShowDishInMenu.bind(this);
    this.showDishInMenu = this.showDishInMenu.bind(this);
    this.hideDishInMenu = this.hideDishInMenu.bind(this);
    this.componentDidUpdate = this.componentDidUpdate.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    // if we loaded a new set of menuitem groups
    if(prevProps.menuitems !== this.props.menuitems) {
      this.setState({
        menuitems: this.props.menuitems,
        locale: this.props.locale
      });
    }
  }


  hideOrShowDishInMenu(filteredMenuItems, index) {
    const menuItemToUpdate =  filteredMenuItems[index];
    //menuItemToUpdate.isActive = !menuItemToUpdate.isActive;
    if(menuItemToUpdate.isActive) {
      this._isMounted && this.setState({
        isModalHideDishOpen: true,
        isModalShowDishOpen: false,
        selectedDishToHideOrShow: menuItemToUpdate
      });
    } else {
      this._isMounted && this.setState({
        isModalShowDishOpen: true,
        isModalHideDishOpen: false,
        selectedDishToHideOrShow: menuItemToUpdate
      });
    }
  }

  showDishInMenu() {
    //console.log('showDishInMenu');
    const {dispatch} = this.props;
    const {selectedDishToHideOrShow} = this.state;
    //console.log('hiding ', selectedDishToHideOrShow);
    dispatch(updateMenuItemVisibility(selectedDishToHideOrShow));
    this._isMounted && this.setState({
      isModalShowDishOpen: false,
      isModalHideDishOpen: false,
      selectedDishToHideOrShow: null
    });
  }

  hideDishInMenu() {
    //console.log('hideDishInMenu');
    const {dispatch} = this.props;
    const {selectedDishToHideOrShow} = this.state;
    //console.log('hiding ', selectedDishToHideOrShow);
    dispatch(updateMenuItemVisibility(selectedDishToHideOrShow));
    this._isMounted && this.setState({
      isModalShowDishOpen: false,
      isModalHideDishOpen: false,
      selectedDishToHideOrShow: null
    });
  }

  toggleModalHideDish() {
    //console.log('toggleModalHideDish');
    this._isMounted && this.setState({
      isModalHideDishOpen: !this.state.isModalHideDishOpen
    })
  }

  toggleModalShowDish() {
    //console.log('toggleModalShowDish');
    this._isMounted && this.setState({
      isModalShowDishOpen: !this.state.isModalShowDishOpen
    })
  }

  toggleTooltip(id, field) {
    //console.log('toggleTooltip');
    const newState = [...this.state[field]];
    newState.fill(false);

    if (!this.state[field][id]) {
      newState[id] = true;
    }

    this._isMounted && this.setState({
      [field]: newState,
    });
  }

  render() {

    const {locale} = this.state;

    const menuItemsArray = this.state.menuitems;

    return (
      <div className="menuitemgroupsgalleryroot">
        <div className="menuitemgroupsgallery">
            {
              //###############################################################
              // for every Menu Item
              //###############################################################
              menuItemsArray && menuItemsArray.length > 0 ? 
              // we only display the menu items that are not part of a menu item group as a gallery
              // the others will be displayed in widget of menuitem groups
              menuItemsArray.map((menuItemObj, index) => {
                //console.log('menuItemObj => ', menuItemObj);
                const key = "menuitemgroupsgallery-" + menuItemObj.name + index;
                return (
                  <div key={key} className="menuitemgroupsgallerypicture card">
                    <CarouselMenuItem menuItem={menuItemObj} height={"320px"}/>
                    {
                      menuItemObj.isRecommendedByTheChef ? 
                      <div>
                        <div className='menuitemgroupsgallerylabel bg-danger' id={"menuitemgroupsgallery-ribbon-recommendedByTheChef-" + index}>
                          <img width="15px" src={chefHat} alt="" />
                        </div>
                        <Tooltip placement="top" isOpen={this.state.tooltipsRecommendedByTheChef[index]} toggle={() => this.toggleTooltip(index, 'tooltipsRecommendedByTheChef')} 
                        target={"menuitemgroupsgallery-ribbon-recommendedByTheChef-" + index}>
                          <FormattedMessage id="MenuitemgroupsGallery.MenuItem.ribbon.recommandedByTheChef" defaultMessage="Recommended by the Chef" />
                        </Tooltip>
                      </div>
                      : null
                    }
                    {/*<RealPictureRibbon isRealPicture = {menuItemObj.isRealPictures} />*/}
                    <Button id={"menuitemgroupsgallery-button-disable-enable-menuitem-" + index} className="menuitemgroupsgalleryeye" color="primary" onClick={() => this.hideOrShowDishInMenu(menuItemsArray, index)}>
                      <img src={menuItemObj.isActive ? eye : eyeSlash} alt=""/>
                    </Button>
                    <Tooltip placement="top" isOpen={this.state.tooltipsShowHideDish[index]} toggle={() => this.toggleTooltip(index, 'tooltipsShowHideDish')} 
                    target={"menuitemgroupsgallery-button-disable-enable-menuitem-" + index}>
                      <FormattedMessage id="MenuitemgroupsGallery.MenuItem.Card.Active.tooltip.show.hide.dish" defaultMessage="Show or hide dish" />
                    </Tooltip>
                    <div className="menuitemgroupsgallerydescription">
                      <h6 className="mt-0 mb-xs fw-semi-bold">{menuItemObj.name_i18n[locale]}</h6>
                      <RealPictureText isRealPicture = {menuItemObj.isRealPictures} />
                    </div>
                    <div className="menuitemgroupsgalleryfooter">
                      <hr style={{marginBottom: '7px'}}/>
                      <div className="w-100 d-flex justify-content-between align-items-center">
                        <span className="btn-gray">
                          &nbsp;
                          <FormattedMessage id="MenuitemgroupsGallery.MenuItem.Card.label.price.not.applicable" defaultMessage="Price is not applicable" />
                          &nbsp;
                        </span>
                        <Button color="info" href={`#/app/restaurant/${this.props.restaurantId}/menu/${this.props.menuId}/menucategory/${this.props.menuCategoryIdForMenuGroup}/menugroup/${this.props.activeMenuCategoryId}/menuitemsgroup/${this.props.menuitemsgroupId}/menuitem/${menuItemObj.orderedmenuitemId}`}>
                          <FormattedMessage id="MenuitemgroupsGallery.MenuItem.Card.button.details" defaultMessage="Details" />
                        </Button>
                      </div>
                    </div>
                  </div>
                );
              }): null // end of menucategory.map
            }
          </div>

            <Modal size="lg" isOpen={this.state.isModalHideDishOpen} toggle={this.toggleModalHideDish}>
              <ModalHeader toggle={this.toggleModalHideDish}>
                <FormattedMessage id="MenuitemgroupsGallery.Modal.hide.dish.header" defaultMessage="Hide dish confirmation" />
              </ModalHeader>
              <ModalBody className="bg-white">
                {this.state.selectedDishToHideOrShow? <b>{this.state.selectedDishToHideOrShow.name_i18n[locale]}</b>: null}<br /><br />
                <span style={{color:"red"}}>
                  <FormattedMessage id="MenuitemgroupsGallery.Modal.hide.dish.body" defaultMessage="This action will remove the above dish from the menu. This means that your customers won't be able to see it in the menu and therefore it won't be available for ordering." />
                </span>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={this.toggleModalHideDish}>
                  <FormattedMessage id="MenuitemgroupsGallery.Modal.hide.dish.footer.button.close" defaultMessage="Close" />
                </Button>
                <Button color="primary" onClick={this.hideDishInMenu}>
                  <FormattedMessage id="MenuitemgroupsGallery.Modal.hide.dish.footer.button.confirm" defaultMessage="Confirm" />
                </Button>
              </ModalFooter>
            </Modal>

            <Modal size="lg" isOpen={this.state.isModalShowDishOpen} toggle={this.toggleModalShowDish}>
              <ModalHeader toggle={this.toggleModalShowDish}>
                <FormattedMessage id="MenuitemgroupsGallery.Modal.show.dish.header" defaultMessage="Show dish confirmation" />
              </ModalHeader>
              <ModalBody className="bg-white">
                {this.state.selectedDishToHideOrShow? <b>{this.state.selectedDishToHideOrShow.name_i18n[locale]}</b>: null}<br /><br />
                <span style={{color:"blue"}}>
                  <FormattedMessage id="MenuitemgroupsGallery.Modal.show.dish.body" defaultMessage="This action will publish the above dish in your menu. This means that your customers will be able to see it in the menu and therefore it will be available for ordering." />
                </span>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={this.toggleModalShowDish}>
                  <FormattedMessage id="MenuitemgroupsGallery.Modal.show.dish.footer.button.close" defaultMessage="Close" />
                </Button>
                <Button color="primary" onClick={this.showDishInMenu}>
                  <FormattedMessage id="MenuitemgroupsGallery.Modal.show.dish.footer.button.confirm" defaultMessage="Confirm" />
                </Button>
              </ModalFooter>
            </Modal>
        </div>
    );
  }

}

function mapStateToProps(store) {
  return {
    errorMessage: store.menuitem.errorMessage,
    updatedMenuItem: store.menuitem.updatedMenuItem,
  };
}

export default withRouter(connect(mapStateToProps)(MenuitemgroupsGallery));
