exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/**\n * Customized version of bootstrap using variables from _variables.scss.\n * This file is loaded via separate loader thus allowing to use original bootstrap classes (e.g. .btn-default) through out the app.\n */\n/*\n * Typography\n * ======================================================================== */\n/**\n * Custom application mixins available through out the app\n */\n.LegendBar_legendbar__1p2de {\n  display: -webkit-flex;\n  display: flex;\n  position: absolute;\n  bottom: 10px;\n  position: relative;\n  padding-bottom: 10px; }\n  .LegendBar_legendbar__1p2de div {\n    border: #e9ecef solid 2px;\n    width: 45px;\n    height: 45px;\n    margin-right: 15px;\n    border-radius: 25px; }\n    .LegendBar_legendbar__1p2de div div {\n      border: none;\n      margin-left: 3px;\n      margin-top: -2px;\n      width: 35px;\n      background-size: contain;\n      background-position: center;\n      background-repeat: no-repeat; }\n  @media (max-width: 1199.98px) {\n    .LegendBar_legendbar__1p2de {\n      position: relative;\n      padding-bottom: 10px; } }\n  @media (max-width: 991.98px) {\n    .LegendBar_legendbar__1p2de {\n      position: relative;\n      padding-bottom: 10px; } }\n  @media (max-width: 767.98px) {\n    .LegendBar_legendbar__1p2de {\n      position: relative;\n      padding-bottom: 10px; } }\n  @media (max-width: 575.98px) {\n    .LegendBar_legendbar__1p2de {\n      display: none; } }\n\n.LegendBar_title__2TNca {\n  font-size: 1.25rem;\n  font-weight: 400;\n  color: black;\n  margin-right: 10px;\n  margin-top: 8px;\n  padding-right: 5px;\n  padding-left: 5px;\n  border-radius: 7px; }\n", ""]);

// exports
exports.locals = {
	"legendbar": "LegendBar_legendbar__1p2de",
	"title": "LegendBar_title__2TNca"
};