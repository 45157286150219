import React from 'react';
import {
  Badge,
  Row,
  Col,
  Table,
  ButtonToolbar,
  Button,
  /* CardTitle */
} from 'reactstrap';

import moment from 'moment';

import { FormattedMessage, injectIntl } from 'react-intl';

import Widget from '../../../../components/Widget';

import Barcode from 'react-barcode';

import s from './OrderCard.module.scss';

import { sendPrintOrderCommand, sendPoSSyncOrderCommand } from '../../../../api/order'

import GastrofixLogo from './assets/gastrofix.png'

const colors = [
  "#63b598", "#ce7d78", "#ea9e70", "#a48a9e", "#c6e1e8", "#648177" ,"#0d5ac1" ,
  "#f205e6" ,"#1c0365" ,"#14a9ad" ,"#4ca2f9" ,"#a4e43f" ,"#d298e2" ,"#6119d0",
  "#d2737d" ,"#c0a43c" ,"#f2510e" ,"#651be6" ,"#79806e" ,"#61da5e" ,"#cd2f00" ,
  "#9348af" ,"#01ac53" ,"#c5a4fb" ,"#996635","#b11573" ,"#4bb473" ,"#75d89e" ,
  "#2f3f94" ,"#2f7b99" ,"#da967d" ,"#34891f" ,"#b0d87b" ,"#ca4751" ,"#7e50a8" ,
  "#c4d647" ,"#e0eeb8" ,"#11dec1" ,"#289812" ,"#566ca0" ,"#ffdbe1" ,"#2f1179" ,
  "#935b6d" ,"#916988" ,"#513d98" ,"#aead3a", "#9e6d71", "#4b5bdc", "#0cd36d",
  "#250662", "#cb5bea", "#228916", "#ac3e1b", "#df514a", "#539397", "#880977",
  "#f697c1", "#ba96ce", "#679c9d", "#c6c42c", "#5d2c52", "#48b41b", "#e1cf3b",
  "#5be4f0", "#57c4d8", "#a4d17a", "#225b8", "#be608b", "#96b00c", "#088baf",
  "#f158bf", "#e145ba", "#ee91e3", "#05d371", "#5426e0", "#4834d0", "#802234",
  "#6749e8", "#0971f0", "#8fb413", "#b2b4f0", "#c3c89d", "#c9a941", "#41d158",
  "#fb21a3", "#51aed9", "#5bb32d", "#807fb", "#21538e", "#89d534", "#d36647",
  "#7fb411", "#0023b8", "#3b8c2a", "#986b53", "#f50422", "#983f7a", "#ea24a3",
  "#79352c", "#521250", "#c79ed2", "#d6dd92", "#e33e52", "#b2be57", "#fa06ec",
  "#1bb699", "#6b2e5f", "#64820f", "#1c271", "#21538e", "#89d534", "#d36647",
  "#7fb411", "#0023b8", "#3b8c2a", "#986b53", "#f50422", "#983f7a", "#ea24a3",
  "#79352c", "#521250", "#c79ed2", "#d6dd92", "#e33e52", "#b2be57", "#fa06ec",
  "#1bb699", "#6b2e5f", "#64820f", "#1c271", "#9cb64a", "#996c48", "#9ab9b7",
  "#06e052", "#e3a481", "#0eb621", "#fc458e", "#b2db15", "#aa226d", "#792ed8",
  "#73872a", "#520d3a", "#cefcb8", "#a5b3d9", "#7d1d85", "#c4fd57", "#f1ae16",
  "#8fe22a", "#ef6e3c", "#243eeb", "#1dc18", "#dd93fd", "#3f8473", "#e7dbce",
  "#421f79", "#7a3d93", "#635f6d", "#93f2d7", "#9b5c2a", "#15b9ee", "#0f5997",
  "#409188", "#911e20", "#1350ce", "#10e5b1", "#fff4d7", "#cb2582", "#ce00be",
  "#32d5d6", "#17232", "#608572", "#c79bc2", "#00f87c", "#77772a", "#6995ba",
  "#fc6b57", "#f07815", "#8fd883", "#060e27", "#96e591", "#21d52e", "#d00043",
  "#b47162", "#1ec227", "#4f0f6f", "#1d1d58", "#947002", "#bde052", "#e08c56",
  "#28fcfd", "#bb09b", "#36486a", "#d02e29", "#1ae6db", "#3e464c", "#a84a8f",
  "#911e7e", "#3f16d9", "#0f525f", "#ac7c0a", "#b4c086", "#c9d730", "#30cc49",
  "#3d6751", "#fb4c03", "#640fc1", "#62c03e", "#d3493a", "#88aa0b", "#406df9",
  "#615af0", "#4be47", "#2a3434", "#4a543f", "#79bca0", "#a8b8d4", "#00efd4",
  "#7ad236", "#7260d8", "#1deaa7", "#06f43a", "#823c59", "#e3d94c", "#dc1c06",
  "#f53b2a", "#b46238", "#2dfff6", "#a82b89", "#1a8011", "#436a9f", "#1a806a",
  "#4cf09d", "#c188a2", "#67eb4b", "#b308d3", "#fc7e41", "#af3101", "#ff065",
  "#71b1f4", "#a2f8a5", "#e23dd0", "#d3486d", "#00f7f9", "#474893", "#3cec35",
  "#1c65cb", "#5d1d0c", "#2d7d2a", "#ff3420", "#5cdd87", "#a259a4", "#e4ac44",
  "#1bede6", "#8798a4", "#d7790f", "#b2c24f", "#de73c2", "#d70a9c", "#25b67",
  "#88e9b8", "#c2b0e2", "#86e98f", "#ae90e2", "#1a806b", "#436a9e", "#0ec0ff",
  "#f812b3", "#b17fc9", "#8d6c2f", "#d3277a", "#2ca1ae", "#9685eb", "#8a96c6",
  "#dba2e6", "#76fc1b", "#608fa4", "#20f6ba", "#07d7f6", "#dce77a", "#77ecca"]

const countdownNumber = 21

class OrderCard extends React.Component {

  constructor(props) {
    super(props);

    this._isMounted = false;

    this.state = {
      seconds: countdownNumber
    };

  }

  componentDidMount() {
    this._isMounted = true;
  }

  /* static getDerivedStateFromProps(nextProps, prevState) {
    console.log('nextProps => ', nextProps)
    console.log('prevState => ', prevState)
    return {
      isSyncingWithPoS: false
    };
  }

  componentDidUpdate(prevProps) {
    console.log('menus newProps => ', prevProps);
    console.log('menus this.props => ', this.props);
  } */

  componentWillUnmount() {
    this._isMounted = false;
  }

  compareOrderItem (orderA, orderB) {
    if (orderA.foodcategory_i18n && orderB.foodcategory_i18n &&
      orderA.foodcategory_i18n.displayPriority &&
      orderB.foodcategory_i18n.displayPriority) {
      const orderAFoodCategoryDisplayPriority = orderA.foodcategory_i18n.displayPriority;
      const orderBFoodCategoryDisplayPriority = orderB.foodcategory_i18n.displayPriority;
      if (orderAFoodCategoryDisplayPriority > orderBFoodCategoryDisplayPriority) {
        // order A with higher prio than order B
        return 1;
      }
      if (orderBFoodCategoryDisplayPriority > orderAFoodCategoryDisplayPriority) {
        // order B with higher prio than order A
        return -1
      };
    }

    // equal
    return 0;
  }

  render() {
    //console.log(props);
    const {locale} = this.props.intl;
    const order = this.props;
    //const {dateForLoadingOrders} = props;
    const {laneId, isStartCollapsed, moveCardToLane, orderContent, printOrder, isShowBarCodes } = this.props;

    const arrayOfFoodCategories = {}

    const sortedOrderContent = orderContent ? orderContent.sort(this.compareOrderItem) : []
    // console.log('orderContent sorted by food category => ', sortedOrderContent)
    moment.locale(locale);
    moment.tz.setDefault(order.restaurantTimezone);
    //console.log('order card => ', order);
    return (
      <div className={s.root}>
      <Row style={isShowBarCodes ? {minWidth: 360} : {maxWidth: 360, minWidth: 360}}>
      <Col lg={12}>
        <Row>
          <Col>
            <Widget 
              style={
                order.type !== "dinein" ?
                  (
                    order.type === 'roomservice' ?
                    {backgroundColor: "#19f0c4"} :
                    order.type === 'deliverytolocation' ||
                      order.type === 'deliverytoseat' ||
                      order.type === 'delivertoofficewop'?
                    {backgroundColor: "lightgreen"} :
                    order.type === 'collectatkioskwop' ? 
                    {backgroundColor: "#FFAF33"} :
                    order.type.includes('massageatspa') ? 
                    {backgroundColor: "#33D1FF"} :
                    order.type === 'servicerequest' ? 
                    {backgroundColor: "#f09a4f"} :
                    order.type === 'collectatbarnp' ? 
                    {backgroundColor: "#02dbd4"} :
                    (
                      order.paymentInformation && order.paymentInformation.statusCode === "open" ?
                      {backgroundColor: "gold"} : {backgroundColor: "lightskyblue"}
                    )
                  )
                 : {}
                }
              title={
                <Row>
                    <Col xs={8} md={8} lg={8}>
                      <Badge style={
                        Number(order.table) ?
                        {backgroundColor: colors[order.table]}
                        :
                        {backgroundColor: '#3179ba'}
                      }>
                        <h6 style={{whiteSpace: "break-spaces"}}>
                          {/*if the table label is a number then we display table X otherwise only the name of the customer*/}
                          {
                            Number(order.table) && order.type === "dinein" ?
                              <span>
                                <FormattedMessage id="OrderCard.title.table.label" defaultMessage="Table" /> {order.table}
                              </span>
                              : (
                                Number(order.table) && order.type === "roomservice" ?
                                  <span>
                                    <FormattedMessage id="OrderCard.title.room.label" defaultMessage="Room" /> {order.table}
                                  </span> : order.table
                              )
                              
                          }
                          
                        </h6>
                      </Badge>
                        &nbsp;&nbsp;
                        <span className="orderNumber">
                          {
                          order.orderNumber ? `Order #${order.orderNumber} ` : 
                            <FormattedMessage id="OrderCard.title.archived.orders" defaultMessage="Archived orders will be visible the next day" />
                          }
                          {order.typeShortCode && order.type ? 
                            `[${(order.typeShortCode? order.typeShortCode.toUpperCase() : order.type.toUpperCase())}] ` : ''
                          }&nbsp;
                          {
                            !order.isDineInOrder && order.extraInformation &&
                            order.extraInformation.selectedCollectionTimeslot &&
                            (order.extraInformation.selectedCollectionTimeslot.id || order.extraInformation.selectedCollectionTimeslot.label) ? 
                              `[${order.extraInformation.selectedCollectionTimeslot.id === "takeaway" ? 
                                  order.extraInformation.selectedCollectionTimeslot.id : 
                                  order.extraInformation.selectedCollectionTimeslot.label}]` 
                            : null
                          }
                          {
                            order.paymentInformation && order.paymentInformation.statusCode === "paid" ? (
                              <span>&nbsp;[
                                <span style={{fontSize: 12}}>{order.totalPriceCurrency}</span>
                                <span style={{fontWeight: 700, fontSize: 12}}> {order.currencyNumberOfDecimals <= 2 ?  parseFloat(order.totalPrice.toFixed(order.currencyNumberOfDecimals)) : order.totalPrice.toFixed(order.currencyNumberOfDecimals)}</span>]
                              </span>
                            ) : null
                          }
                          {/*
                            order.paymentInformation && order.paymentInformation.statusCode === "open" ? (
                              <span>&nbsp;[
                                <span style={{fontSize: 12}}>{order.totalPriceCurrency}</span>
                                <span style={{fontWeight: 700, fontSize: 12}}> {order.currencyNumberOfDecimals <= 2 ?  parseFloat(order.totalPrice.toFixed(order.currencyNumberOfDecimals)) : order.totalPrice.toFixed(order.currencyNumberOfDecimals)}</span>]
                              </span> 
                            ) : null
                          */}
                          {
                            order.paymentInformation && order.paymentInformation.statusCode === "open" ?
                                order.paymentInformation.method === "cash" ? (
                                  <span>[
                                    <FormattedMessage
                                        id="OrderCard.paymentmethod.cash.label"
                                        defaultMessage="Cash"   
                                      />] &nbsp;
                                  </span>
                                  ) : 
                                    order.paymentInformation.method === "ticketresto" ? (
                                    <span>[
                                      <FormattedMessage
                                        id="OrderCard.paymentmethod.ticketresto.label"
                                        defaultMessage="Restaurant voucher"
                                      />] &nbsp;
                                    </span>
                                    ) 
                                  : order.paymentInformation.method === "roompost" ? (
                                    <span>[
                                      <FormattedMessage
                                        id="OrderCard.paymentmethod.roompost.label"
                                        defaultMessage="Room Post"
                                      />] &nbsp;
                                    </span>
                                    ) 
                                  : order.paymentInformation.method === "creditcard" ? (
                                    <span>[
                                      <FormattedMessage
                                        id="OrderCard.paymentmethod.creditcard.label"
                                        defaultMessage="Credit Card"
                                      />] &nbsp;
                                    </span>
                                    ) 
                                  :null
                            : null
                          }
                        </span>
                        {
                          // for dine in orders without online payment
                          order.isDineInOrder && !order.isWithOnlinePayment && order.laneId !== 'InPoS' ? (
                          <span>
                            <span style={{fontSize: 14}}>{order.totalPriceCurrency}</span>
                            <span style={{fontWeight: 700, fontSize: 14}}> {order.orderCurrencyNumberOfDecimals <= 2 ?  parseFloat(order.totalPrice.toFixed(order.currencyNumberOfDecimals)) : order.totalPrice.toFixed(order.currencyNumberOfDecimals)}</span>
                          </span>
                          ) : null
                        }
                        {
                          // for delivery to location orders
                          !order.isDineInOrder && !order.isWithOnlinePayment && order.isDeliverToLocation && order.laneId !== 'InPoS' ? (
                          <span>
                            <span style={{fontSize: 14}}>{order.totalPriceCurrency}</span>
                            <span style={{fontWeight: 700, fontSize: 14}}> {order.orderCurrencyNumberOfDecimals <= 2 ?  parseFloat(order.totalPrice.toFixed(order.currencyNumberOfDecimals)) : order.totalPrice.toFixed(order.currencyNumberOfDecimals)}</span>
                          </span>
                          ) : null
                        }
                        {
                          order.customerPhoneNumberForNotification ? (
                            <div>
                              <span style={{
                                fontSize: 13, 
                                backgroundColor: "cyan",
                                borderRadius: "12px",
                                fontWeight: 500
                              }}>
                                &nbsp;&nbsp;{order.customerPhoneNumberForNotification}&nbsp;&nbsp;
                              </span>
                            </div>
                          ) : null
                        }
                        {
                          order.customerFirstname && order.customerLastname ? (
                            <div>
                              <span style={{
                                fontSize: 13, 
                                backgroundColor: "cyan",
                                borderRadius: "12px",
                                fontWeight: 500
                              }}>
                                &nbsp;&nbsp;{order.customerFirstname}&nbsp;{order.customerLastname.toUpperCase()}&nbsp;&nbsp;
                              </span>
                            </div>
                          ) : null
                        }
                        {
                          order.groupCodeForOrder ? (
                            <div>
                              <span style={{
                                fontSize: 13, 
                                backgroundColor: "cyan",
                                borderRadius: "12px",
                                fontWeight: 500
                              }}>
                                &nbsp;&nbsp;Code: <b>{order.groupCodeForOrder.toUpperCase()}</b>&nbsp;&nbsp;
                              </span>
                            </div>
                          ) : null
                        }
                    </Col>
                    { 
                      // we only display this information if we are today
                      (
                        moment(order.createdOn).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ||
                        moment(order.createdOn).format('YYYY-MM-DD') === moment().subtract(1, 'days').format('YYYY-MM-DD')
                      ) &&
                      (order.laneId === 'Submitted' ||  
                        order.laneId === 'Accepted' ||
                        order.laneId === 'ReadyToServe' )?
                      <Col style={{paddingTop: '7px', fontSize: '10px', textAlign: 'right', paddingRight: '35px'}}>
                          { /*
                            console.log('order.acceptedOn => ', moment(order.createdOn).format('YYYY-MM-DDTHH:mm:ssZ'))
                            */
                          }
                          {
                            order.laneId === 'Accepted' && order.acceptedOn ? 
                            moment(order.acceptedOn).fromNow() :
                            (order.laneId === 'ReadyToServe' && order.readyToBeServedOn ?  
                              moment(order.readyToBeServedOn).fromNow() :
                              moment(order.createdOn).fromNow()
                            )
                          }
                          {
                            order.posSyncStatus && order.posSyncStatus !== 'notapplicable' ? (
                              order.posSyncStatus === 'successful' ? (
                                <div>
                                    <img 
                                      src={order.posName === 'gastrofix' ? GastrofixLogo : null} 
                                      style={{width: 20, height: 20}} 
                                      alt={order.posName} 
                                      title={order.posName}/>&nbsp;
                                    <i 
                                      className="fa fa-check" 
                                      style={{
                                        color: "green",
                                        fontSize: "20px",
                                        position: "relative",
                                        top: "4px"
                                      }} />
                                </div>
                              ) : (
                                <div>
                                    <img 
                                      src={order.posName === 'gastrofix' ? GastrofixLogo : null} 
                                      style={{width: 20, height: 20}} 
                                      alt={order.posName} 
                                      title={order.posName}/>&nbsp;
                                    <i 
                                      className="fa fa-times" 
                                      style={{
                                        color: "#ca0505",
                                        fontSize: "20px",
                                        position: "relative",
                                        top: "4px"
                                      }} />
                                </div>
                              )
                            ) : null
                          }
                      </Col> 
                      : 
                      <Col style={{paddingTop: '4px', textAlign: 'right', paddingRight: '35px'}}>
                        <span style={{fontSize: 12}}>{order.totalPriceCurrency}</span>
                        <span style={{fontWeight: 700, fontSize: 12}}> {order.totalPrice && order.orderCurrencyNumberOfDecimals <= 2 ?  parseFloat(order.totalPrice.toFixed(order.currencyNumberOfDecimals)) : order.totalPrice.toFixed(order.currencyNumberOfDecimals)}</span>
                        {
                          order.posSyncStatus && order.posSyncStatus !== 'notapplicable' ? (
                            order.posSyncStatus === 'successful' ? (
                              <div>
                                  <img 
                                    src={order.posName === 'gastrofix' ? GastrofixLogo : null} 
                                    style={{width: 20, height: 20}} 
                                    alt={order.posName} 
                                    title={order.posName}/>&nbsp;
                                  <i 
                                    className="fa fa-check" 
                                    style={{
                                      color: "green",
                                      fontSize: "20px",
                                      position: "relative",
                                      top: "4px"
                                    }} />
                              </div>
                            ) : (
                              <div>
                                  <img 
                                    src={order.posName === 'gastrofix' ? GastrofixLogo : null} 
                                    style={{width: 20, height: 20}} 
                                    alt={order.posName} 
                                    title={order.posName}/>&nbsp;
                                  <i 
                                    className="fa fa-times" 
                                    style={{
                                      color: "#ca0505",
                                      fontSize: "20px",
                                      position: "relative",
                                      top: "4px"
                                    }} />
                              </div>
                            )
                          ) : null
                        }
                      </Col>
                    }
                  </Row>
              }
              collapse={true}
              startcollapsed={isStartCollapsed ? "true" : "false"}
              sectionclickable="true">
              {
              order.orderNumber ?
                (
                <div className="widget">
                  <section>
                    <Table striped 
                      style={isShowBarCodes ? {marginBottom: 0, whiteSpace: 'normal'} :
                                              {marginBottom: 0, whiteSpace: 'normal', marginLeft: '-19px'}}>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th><FormattedMessage id="OrderCard.table.header.item.label" defaultMessage="Item" /></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          sortedOrderContent.map((menuitem, index) => {
                            const menuItemFoodCategory = menuitem.foodcategory_i18n ? menuitem.foodcategory_i18n[locale] : null;
                            // if the menu item is a menu group like combo menu
                            if(menuitem.menugroupid) {
                              // this is a menugroup order
                              const menugroup = menuitem;

                              const listOfMenuItemsForMenuGroup = menugroup.selectedMenuItems.map((menuitem, index)=> {
                                // this is a menu item order
                                const addons = menuitem.addonsgroups.map((addongroup, index) => {

                                  const addonitems = addongroup.selectedAddons.map((addonitem, index) => {

                                    const barcodesArrayAddonItems = [];

                                    const idForElementAddonItem = "ordercard-tbody-menugroup-" + menugroup.menugroupid + "-menuitem-" + menuitem.menuitemId + "-addongroup-addonitem" + addonitem.addonitemId + "-" + index;

                                    // if we have a addon item with a quantity bigger than one it would be great to display
                                    // as many barcodes as the quantity so the person moving the order to the pos
                                    // using a barcode scanner do not omit any addon item
                                    if(isShowBarCodes && addonitem.posReferenceId) {
                                      for(let i = 1; i <= 1; i++) {
                                        barcodesArrayAddonItems.push(
                                          <Barcode key={"barcode-for-menugroup-addonitems" + idForElementAddonItem + "-" + i} 
                                                  value={addonitem.posReferenceId}
                                                  height={70}
                                                  width={1} />
                                        )
                                      }
                                    }

                                    return (
                                        isShowBarCodes && addonitem.posReferenceId ?
                                        // barcode mode
                                        <span key={"ordercard-tbody-menugroup-" + menugroup.menugroupid + "-menuitem-" + menuitem.menuitemId + "-addongroup-addonitem" + addonitem.addonitemId + "-" + index}>
                                          &nbsp;<span style={{fontWeight: 600, display: 'grid'}}>{addonitem.name_i18n[locale]}</span>
                                          {barcodesArrayAddonItems}
                                          <br />
                                        </span>
                                        :
                                        // order dashboard mode
                                        <span key={"ordercard-tbody-menugroup-" + menugroup.menugroupid + "-menuitem-" + menuitem.menuitemId + "-addongroup-addonitem" + addonitem.addonitemId + "-" + index}>
                                          &nbsp;{index === 0 ? "" : "+"}
                                          &nbsp;<span style={{fontWeight: 600}}>{addonitem.name_i18n[locale]}</span>
                                        </span>
                                    );
                                  });

                                  return (
                                    <span key={"ordercard-tbody-menugroup-" + menugroup.menugroupid + "-menuitem-" + menuitem.menuitemId + "-addongroup-" + addongroup.addongroupsId + "-" + index}>
                                      (<u>{addongroup.name_i18n[locale]}</u>:{addonitems})
                                    </span>
                                  );
                                });

                                const noteFromCustomer = menuitem.note && menuitem.note !== "" ? (
                                  <code>
                                    <br />
                                    <FormattedMessage id="OrderCard.table.tbody.menuitem.note.note.from.customer" 
                                                      defaultMessage="Note from customer" />: {menuitem.note}
                                  </code>
                                ) : null;

                                return (
                                    <div key={"ordercard-tbody-menugroup-" + menugroup.menugroupid + "-menuitem-" + menuitem.menuitemId + "-" + index}
                                      style={{width: 270}}>
                                      <span style={{fontWeight: 700}}>{menuitem.size.quantity}</span>
                                      &nbsp;x&nbsp; 
                                      [<u>{menuitem.size.name_i18n[locale]}</u>]&nbsp;
                                      {menuitem.name_i18n[locale]} {addons}
                                      {noteFromCustomer}
                                    </div>
                                );
                              });

                              const idForElement = "ordercard-tbody-menugroup-barcode-" + menugroup.menugroupid + "-" + index;
                              //console.log('idForElement => ', idForElement);

                              const barcodesArray = [];

                              // if we have a menu item with a quantity bigger than one it would be great to display
                              // as many barcodes as the quantity so the person moving the order to the pos
                              // using a barcode scanner do not omit any menu item
                              if(isShowBarCodes && menugroup.posReferenceId) {
                                for(let i = 1; i <= menugroup.quantity; i++) {
                                  barcodesArray.push(
                                    <Barcode key={"barcode-for-menugroup-" + idForElement + "-" + i} 
                                            value={menugroup.posReferenceId}
                                            height={70}
                                            width={1} />
                                  )
                                }
                              }

                              return (
                                <tr key={"ordercard-tbody-menugroup-" + menugroup.menugroupid + "-" + index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    <div style={{width: isShowBarCodes && menugroup.posReferenceId ? '100%' : 270}}>
                                      <span style={{fontWeight: 700}}>{menugroup.quantity}</span>
                                      &nbsp;x&nbsp; 
                                      <span style={{fontWeight: 700, display: 'grid'}}>{menugroup.name_i18n[locale]}</span>
                                        {
                                          isShowBarCodes && menugroup.posReferenceId ?
                                            (
                                              <div>
                                              {barcodesArray}
                                              </div>
                                            )
                                          : null
                                        }
                                      <hr />
                                      {listOfMenuItemsForMenuGroup}
                                    </div>
                                  </td>
                                </tr>
                              );

                            } else {
                              // this is a menu item order
                              const addons = menuitem.addonsgroups.map((addongroup, index) => {

                                const addonitems = addongroup.selectedAddons.map((addonitem, index) => {

                                  const idForElementAddonItem = "ordercard-tbody-menuitem-" + menuitem.menuitemId + "-addongroup-addonitem" + addonitem.addonitemId + "-" + index;

                                  const barcodesArrayAddonItems = [];
                                  // if we have a addon item with a quantity bigger than one it would be great to display
                                  // as many barcodes as the quantity so the person moving the order to the pos
                                  // using a barcode scanner do not omit any addon item
                                  if(isShowBarCodes && addonitem.posReferenceId) {
                                    for(let i = 1; i <= 1; i++) {
                                      barcodesArrayAddonItems.push(
                                        <Barcode key={"barcode-for-menuitem-addonitems" + idForElementAddonItem + "-" + i} 
                                                  value={addonitem.posReferenceId}
                                                  height={70}
                                                  width={1} />
                                      );
                                    }
                                  }

                                  return (
                                    isShowBarCodes && addonitem.posReferenceId ?
                                    // barcode mode
                                    <span key={"ordercard-tbody-menuitem-" + menuitem.menuitemId + "-addongroup-addonitem" + addonitem.addonitemId + "-" + index}>
                                      &nbsp;<span style={{fontWeight: 600, display: 'grid'}}>{addonitem.name_i18n[locale]}</span>
                                      {barcodesArrayAddonItems}
                                      <br />
                                    </span>
                                    :
                                    // dashboard mode
                                    <span key={"ordercard-tbody-menuitem-" + menuitem.menuitemId + "-addongroup-addonitem" + addonitem.addonitemId + "-" + index}>
                                      &nbsp;{index === 0 ? "" : "+"}
                                      &nbsp;<span style={{fontWeight: 600}}>{addonitem.name_i18n ? addonitem.name_i18n[locale] : addonitem.name}</span>
                                    </span>
                                  );
                                });

                                return (
                                  <span key={"ordercard-tbody-menuitem-" + menuitem.menuitemId + "-addongroup-" + addongroup.addongroupsId + "-" + index}>
                                    <br /><u>{addongroup.name_i18n ? addongroup.name_i18n[locale] : addongroup.name}</u>:{addonitems}
                                  </span>
                                );
                              });
                              
                              const noteFromCustomer = menuitem.note && menuitem.note !== "" ? (
                                <code>
                                  <br />
                                  <FormattedMessage id="OrderCard.table.tbody.menuitem.note.note.from.customer" 
                                                    defaultMessage="Note from customer" />: {menuitem.note}
                                </code>
                              ) : null;
                              
                              const idForElement = "ordercard-tbody-menuitem-barcode-" + menuitem.menuitemId + "-" + index;
                              //console.log('idForElement => ', idForElement);

                              const barcodesArray = [];

                              // if we have a menu item with a quantity bigger than one it would be great to display
                              // as many barcodes as the quantity so the person moving the order to the pos
                              // using a barcode scanner do not omit any menu item
                              if(isShowBarCodes && menuitem.size.posReferenceId) {
                                for(let i = 1; i <= menuitem.size.quantity; i++) {
                                  barcodesArray.push(
                                    <Barcode key={"barcode-for-menuitem-" + idForElement + "-" + i} 
                                            value={menuitem.size.posReferenceId}
                                            height={70}
                                            width={1} />
                                  )
                                }
                              }

                              let isFoodCategoryAlreadyPresent = false;

                              if (menuItemFoodCategory && arrayOfFoodCategories[menuItemFoodCategory]) {
                                isFoodCategoryAlreadyPresent = true;
                              } else {
                                if(menuItemFoodCategory && !arrayOfFoodCategories[menuItemFoodCategory]) {
                                  arrayOfFoodCategories[menuItemFoodCategory] = true;
                                }
                              }

                              return (
                                <tr key={"ordercard-tbody-menuitem-" + idForElement + "-" + index}>
                                  <td>
                                    {
                                      menuItemFoodCategory && !isFoodCategoryAlreadyPresent ? (
                                        <div className={s.foodCategoryHeader}>
                                          &nbsp;
                                        </div>
                                      ) : null
                                    }
                                    {index + 1}
                                  </td>
                                  <td>
                                    {
                                      menuItemFoodCategory && !isFoodCategoryAlreadyPresent ? (
                                        <div  className={s.foodCategoryHeader}>
                                          {menuItemFoodCategory}
                                        </div>
                                      ) : null
                                    }
                                    <div style={{width: isShowBarCodes && menuitem.size.posReferenceId ? '100%' : 270}}>
                                      <span style={{fontWeight: 700}}>{menuitem.size.quantity}</span>
                                      &nbsp;x&nbsp; 
                                      [<u>{menuitem.size.name_i18n ? menuitem.size.name_i18n[locale] : menuitem.size.name}</u>]&nbsp;
                                      {menuitem.name_i18n ? menuitem.name_i18n[locale] : menuitem.name}
                                      {
                                        isShowBarCodes && menuitem.size.posReferenceId ?
                                        (
                                          <div>
                                            {barcodesArray}
                                          </div>
                                        )
                                        :
                                          noteFromCustomer
                                      }
                                      {addons}
                                    </div>
                                  </td>
                                </tr>
                              );
                            }

                          })
                        }
                      </tbody>
                    </Table>
                    <ButtonToolbar className="mt-lg justify-content-end d-print-none">
                    {
                      laneId === 'Submitted' && 
                      order.isWithOnlinePayment &&
                      order.paymentInformation.statusCode === "paid" && !isShowBarCodes &&
                      // we only display this button if we are today
                      (
                        moment(order.createdOn).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ||
                        moment(order.createdOn).format('YYYY-MM-DD') === moment().subtract(1, 'days').format('YYYY-MM-DD')
                      ) ?
                        <Button
                          style={{width: "132px"}}
                          onClick={() => {
                          const cardToMove = this.props;
                          if(laneId === 'Submitted') {
                            const { intl } = this.props;
                            const fromLaneId = laneId;
                            const toLaneId = 'Submitted';
                            /* call prompt() with custom message to get user input from alert-like dialog */
                            const refundReason = prompt(
                              intl.formatMessage({ id: "OrderCard.alert.popup.refund.reason.message", defaultMessage: 'Indicate the reason for a refund'})
                            );
                            if(refundReason) {
                              const tempPaymentInformation = {...cardToMove.paymentInformation};
                              tempPaymentInformation.refundReason = refundReason;
                              const tempCardToMove = {...cardToMove, status: "Refunded", paymentInformation: tempPaymentInformation};
                              moveCardToLane(tempCardToMove, fromLaneId, toLaneId);
                            }
                          }
                        }} color="primary" className="mr-2">
                            <i className="fa fa-credit-card" />
                            &nbsp;&nbsp;
                            <FormattedMessage id="OrderCard.buttontoolbar.button.refund.label" 
                                                    defaultMessage="Refund" />
                        </Button>
                      :  (
                        laneId === 'Submitted' && 
                        // order.isDineInOrder &&
                        !order.isWithOnlinePayment &&
                        !isShowBarCodes &&
                        // we only display this button if we are today
                        (
                          moment(order.createdOn).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ||
                          moment(order.createdOn).format('YYYY-MM-DD') === moment().subtract(1, 'days').format('YYYY-MM-DD')
                        ) ?
                          <Button
                            style={{width: "132px"}}
                            onClick={() => {
                            const cardToMove = this.props;
                            if(laneId === 'Submitted') {
                              const { intl } = this.props;
                              const fromLaneId = laneId;
                              const toLaneId = 'Submitted';
                              /* call prompt() with custom message to get user input from alert-like dialog */
                              const refundReason = prompt(
                                intl.formatMessage({ id: "OrderCard.alert.popup.reject.reason.message", defaultMessage: 'Indicate the reason for rejecting the order'})
                              );
                              if(refundReason) {
                                const tempPaymentInformation = {
                                  refundReason
                                };
                                const tempCardToMove = {...cardToMove, status: "Rejected", paymentInformation: tempPaymentInformation};
                                moveCardToLane(tempCardToMove, fromLaneId, toLaneId);
                              }
                            }
                          }} color="primary" className="mr-2">
                              <i className="fa fa-times" />
                              &nbsp;&nbsp;
                              <FormattedMessage id="OrderCard.buttontoolbar.button.reject.label" 
                                                      defaultMessage="Reject" />
                          </Button> : null
                      )
                    }
                    {
                      (laneId === 'Submitted' || laneId === 'Accepted') && !isShowBarCodes &&
                      // we only display this button if we are today
                      (
                        moment(order.createdOn).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ||
                        moment(order.createdOn).format('YYYY-MM-DD') === moment().subtract(1, 'days').format('YYYY-MM-DD')
                      ) &&
                      order.posSyncStatus && order.posSyncStatus === 'failed' ?
                        <Button
                          style={{width: "132px"}}
                          disabled={this.state.seconds > 0 && this.state.seconds < countdownNumber}
                          onClick={() => {
                            this.myInterval = setInterval(() => {
                              const { seconds } = this.state
                              if (seconds > 0) {
                                this.setState({
                                  seconds: seconds - 1
                                })
                              }
                              if (seconds === 0) {
                                clearInterval(this.myInterval)
                                this.setState({
                                  seconds: countdownNumber
                                })
                              }
                            }, 1000)
                            const token = localStorage.getItem('id_token');
                            sendPoSSyncOrderCommand(token, order)
                        }} color="primary" className="mr-2">
                            <i className="fa fa-exchange" />
                            &nbsp;&nbsp;
                            {
                              this.state.seconds > 0 && this.state.seconds < countdownNumber ? (
                                `Sync...(${this.state.seconds}) `
                              ) : (
                                <FormattedMessage id="OrderCard.buttontoolbar.button.manualpossync.label" 
                                defaultMessage="PoS Sync" />
                              )
                            }                          
                        </Button>
                      : null
                    }
                    {
                      laneId === 'Submitted' && !isShowBarCodes && 
                      // we only display this button if we are today
                      (
                        moment(order.createdOn).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ||
                        moment(order.createdOn).format('YYYY-MM-DD') === moment().subtract(1, 'days').format('YYYY-MM-DD')
                      ) ?
                        <Button
                        style={{width: "132px"}}
                        onClick={() => {
                          const cardToMove = this.props;
                          if(laneId === 'Submitted') {
                            const fromLaneId = laneId;
                            const toLaneId = 'Accepted';
                            moveCardToLane(cardToMove, fromLaneId, toLaneId);
                          }
                        }} color="primary" className="mr-2">
                            <i className="fa fa-check" />
                            &nbsp;&nbsp;
                            <FormattedMessage id="OrderCard.buttontoolbar.button.accept.label" 
                                                    defaultMessage="Accept" />
                        </Button>
                      :  null
                    }
                    {
                      laneId === 'Accepted' && !isShowBarCodes && 
                      // we only display this button if we are today
                      (
                        moment(order.createdOn).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ||
                        moment(order.createdOn).format('YYYY-MM-DD') === moment().subtract(1, 'days').format('YYYY-MM-DD')
                      ) ?
                        // massage at spa orders do not need to be in ready to serve they can move
                        // from accepted to added in pos
                        order.type.includes('massageatspa') ? 
                        <Button
                          style={{width: "132px"}}
                          onClick={() => {
                          const cardToMove = this.props;
                            const fromLaneId = 'Accepted';
                            const toLaneId = 'InPoS';
                            moveCardToLane(cardToMove, fromLaneId, toLaneId);
                        }} color="primary" className="mr-2">
                            <i className="fa fa-desktop" />
                            &nbsp;&nbsp;
                            <FormattedMessage id="OrderCard.buttontoolbar.button.registeredinpos.label" 
                                                    defaultMessage="Added in POS" />
                          </Button>
                        : <Button 
                          style={{width: "132px"}}
                          onClick={() => {
                          const cardToMove = this.props;
                          if(laneId === 'Accepted') {
                            const fromLaneId = laneId;
                            const toLaneId = 'ReadyToServe';
                            moveCardToLane(cardToMove, fromLaneId, toLaneId);
                          }
                        }} color="primary" className="mr-2">
                            <i className="fa fa-thumbs-o-up" />
                            &nbsp;&nbsp;
                            <FormattedMessage id="OrderCard.buttontoolbar.button.readytoserve.label" 
                                                    defaultMessage="Ready to serve" />
                        </Button>
                      :  null
                    }
                    {
                      order.isPrintingEnabled ?
                        <Button 
                          style={{width: "132px"}}
                          onClick={() => {
                            const token = localStorage.getItem('id_token');
                            sendPrintOrderCommand(token, order)
                        }} color="primary" className="mr-2">
                            <i className="fa fa-print" />
                            &nbsp;&nbsp;
                            <FormattedMessage id="OrderCard.buttontoolbar.button.print.label" 
                                                    defaultMessage="Print" />
                        </Button>
                      :  null
                    }
                    {
                      !isShowBarCodes ?
                        <Button 
                          style={{width: "132px"}}
                          onClick={() => printOrder(order)} color="primary" className="mr-2">
                          <i className="fa fa-barcode" />
                          &nbsp;&nbsp;
                          <FormattedMessage id="OrderCard.buttontoolbar.button.barcodes.label" 
                                                    defaultMessage="Barcodes" />
                        </Button> : null
                    }
                    {
                      laneId === 'ReadyToServe' && !isShowBarCodes && 
                      // we only display this button if we are today
                      (
                        moment(order.createdOn).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ||
                        moment(order.createdOn).format('YYYY-MM-DD') === moment().subtract(1, 'days').format('YYYY-MM-DD')
                      ) ?
                      <div>
                        <Button
                          style={{width: "132px"}}
                          onClick={() => {
                          const cardToMove = this.props;
                          if(laneId === 'ReadyToServe') {
                            const fromLaneId = laneId;
                            const toLaneId = 'InPoS';
                            moveCardToLane(cardToMove, fromLaneId, toLaneId);
                          }
                        }} color="primary" className="mr-2">
                            <i className="fa fa-desktop" />
                            &nbsp;&nbsp;
                            <FormattedMessage id="OrderCard.buttontoolbar.button.registeredinpos.label" 
                                                    defaultMessage="Added in POS" />
                        </Button>
                      </div>
                      : null
                    }
                    </ButtonToolbar>
                  </section>
                </div>
                )
              :  
                null
            }
            </Widget>
          </Col>
        </Row>
      </Col>
    </Row>
    </div>
    );
  }
} // end of class

export default injectIntl(OrderCard);
