import { getListOfMenusFromServerForRestaurant, updateMenuIsActiveProperty } from '../api/menu.js';

export const MENUS_REQUEST = 'MENUS_REQUEST';
export const MENUS_SUCCESS = 'MENUS_SUCCESS';
export const MENUS_FAILURE = 'MENUS_FAILURE';
export const MENU_IS_ACTIVE_UPDATE_REQUEST = 'MENU_IS_ACTIVE_UPDATE_REQUEST';
export const MENU_IS_ACTIVE_UPDATE_SUCCESS = 'MENU_IS_ACTIVE_UPDATE_SUCCESS';
export const MENU_IS_ACTIVE_UPDATE_FAILURE = 'MENU_IS_ACTIVE_UPDATE_FAILURE';

function requestMenus() {
  return {
    type: MENUS_REQUEST,
    isFetching: true
  };
}

function receiveMenus(restaurantMenus) {
  return {
    type: MENUS_SUCCESS,
    isFetching: false,
    restaurantWithMenus: restaurantMenus
  };
}

function menusError(message) {
  return {
    type: MENUS_FAILURE,
    isFetching: false,
    message,
  };
}

function requestUpdateIsActiveMenu() {
  return {
    type: MENU_IS_ACTIVE_UPDATE_REQUEST,
    isFetching: true
  };
}

function receiveUpdateIsActiveMenu(updatedMenu) {
  return {
    type: MENU_IS_ACTIVE_UPDATE_SUCCESS,
    isFetching: false,
    updatedMenu
  };
}

function updateIsActiveMenuError(message) {
  return {
    type: MENU_IS_ACTIVE_UPDATE_FAILURE,
    isFetching: false,
    message,
  };
}


// Logs the user out
export function getMenusFromServer(restaurantId) {
  return (dispatch) => {
    dispatch(requestMenus());
    const token = localStorage.getItem('id_token');
    getListOfMenusFromServerForRestaurant(token, restaurantId)
        .then(restaurantWithMenus => {
          //console.log('in getMenusFromServer restaurantWithMenus  => ' + JSON.stringify(restaurantWithMenus));
          //localStorage.setItem('arrayOfMenus', arrayOfMenus);
          // Dispatch the success action with fake user
          if(restaurantWithMenus.errors) return dispatch(menusError(restaurantWithMenus.errors[0].message));

          dispatch(receiveMenus(restaurantWithMenus));
        })
        .catch(error => {
          //console.log('An error occurred inLoginUser: ', error.response.data.message);
          if (error.response && error.response.data) {
            return dispatch(menusError(error.response.data.message));
          }
          return dispatch(menusError(error.toString()));
        })
  };
}

// Logs the user out
export function updateMenuVisibility(menu) {
  return (dispatch) => {
    dispatch(requestUpdateIsActiveMenu());
    const token = localStorage.getItem('id_token');
    updateMenuIsActiveProperty(token, menu)
        .then(updatedMenu => {
          //console.log('in getMenuItemsFromServer restaurantWithMenuItems  => ' + JSON.stringify(restaurantWithMenuItems));
          //localStorage.setItem('arrayOfMenus', arrayOfMenus);
          // Dispatch the success action with fake user
          dispatch(receiveUpdateIsActiveMenu(updatedMenu));
        })
        .catch(error => {
          //console.log('An error occurred inLoginUser: ', error.response.data.message);
          if (error.response && error.response.data) {
            return dispatch(updateIsActiveMenuError(error.response.data.message));
          }
          return dispatch(updateIsActiveMenuError(error.toString()));
        })
  };
}