import axios from "axios";
// import runtimeEnv from '@mars/heroku-js-runtime-env';

export async function apiGetListOfLanguagePacks() {
  // const env = runtimeEnv();
  // Request API
  /* env.REACT_APP_STRAPI_HOST || */
  /* env.REACT_APP_STRAPI_PROTOCOL || */
  /* env.REACT_APP_STRAPI_PORT || */
  const userInfo = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : null;
  if (userInfo) {
    const currentRestaurant = userInfo.restaurants[0];
    const availableLanguagePacksForCurrentRestaurant =
      currentRestaurant.languagepacks;
    /* console.log(
      "currentRestaurant.languagepacks => ",
      currentRestaurant.languagepacks
    ); */
    // one language pack available for the current restaurant
    if (availableLanguagePacksForCurrentRestaurant && availableLanguagePacksForCurrentRestaurant.length === 1) {
      const responseData = await axios
        .get(
          (process.env.REACT_APP_STRAPI_PROTOCOL || "http") +
            "://" +
            (process.env.REACT_APP_STRAPI_HOST || "localhost") +
            ":" +
            (process.env.REACT_APP_STRAPI_PORT || "1337") +
            "/aplanguagepacks?id=" + availableLanguagePacksForCurrentRestaurant[0].id,
          {}
        )
        .then((response) => {
          // Handle success.
          // console.log('response =>', response);
          return response.data;
        });

      return responseData;
    } else {
        let queryString = "id_in=" + availableLanguagePacksForCurrentRestaurant[0].id;
        for (let index = 1; index < availableLanguagePacksForCurrentRestaurant.length; index++) {
            const languageId = availableLanguagePacksForCurrentRestaurant[index].id;
            queryString += "&id_in=" + languageId
        }
        const responseData = await axios
        .get(
          (process.env.REACT_APP_STRAPI_PROTOCOL || "http") +
            "://" +
            (process.env.REACT_APP_STRAPI_HOST || "localhost") +
            ":" +
            (process.env.REACT_APP_STRAPI_PORT || "1337") +
            "/aplanguagepacks?" + queryString,
          {}
        )
        .then((response) => {
          // Handle success.
          // console.log('response =>', response);
          return response.data;
        });

      return responseData;
    }
  } else {
    const responseData = await axios
      .get(
        (process.env.REACT_APP_STRAPI_PROTOCOL || "http") +
          "://" +
          (process.env.REACT_APP_STRAPI_HOST || "localhost") +
          ":" +
          (process.env.REACT_APP_STRAPI_PORT || "1337") +
          "/aplanguagepacks",
        {}
      )
      .then((response) => {
        // Handle success.
        // console.log('response =>', response);
        return response.data;
      });

    return responseData;
  }
}
