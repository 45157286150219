import axios from 'axios';
//import runtimeEnv from '@mars/heroku-js-runtime-env';

export async function getCollectionsForRestaurant (token, restaurantId) {
    // endpoint path
    const apiEndpointPath = '/graphql';
    // init runtime env var
    //const env = runtimeEnv();
    // Request API.
    return await axios
    .post( (/*env.REACT_APP_STRAPI_PROTOCOL || */process.env.REACT_APP_STRAPI_PROTOCOL || 'http') +
    '://' + (/*env.REACT_APP_STRAPI_HOST || */process.env.REACT_APP_STRAPI_HOST || 'localhost') +
     ":" + (/*env.REACT_APP_STRAPI_PORT || */process.env.REACT_APP_STRAPI_PORT || '1337') + apiEndpointPath + '?restaurantId=' + restaurantId + "&nocaching=1", 
           {
            "query": "query getCollectionsForRestaurant {\n  restaurantDetails(slug: \"" + restaurantId + "\") {\n    id\n    name\n    restaurantcols(where: {isActive: true}) {\n      id\n      name\n    slug\n  name_i18n\n      isActive\n      type\n    }\n  }\n}"
           }
           ,{
               headers: { 
                'Authorization':`Bearer ${token}`
                }
            }
        )
    .then(response => {
        // Handle success.
        //console('menu axios response', response);
        if(response.data.errors) return response.data;

        return response.data.data.restaurantDetails.restaurantcols;
    });
}