import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { changeUserPassword, receiveUpdateUserPwd, updateUserPwdError } from '../../actions/user';
import cx from 'classnames';
import {
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  //Input,
  //Label,
  Form,
  Button,
  //InputGroup,
  //InputGroupAddon,
  FormGroup,
  Alert
} from 'reactstrap';
import { showSuccessMessage } from '../../components/ToastMessenger';
import { GAEvent } from '../../components/Tracking';

import alacarteBlackLogo from '../../images/alacarte-black-logo.png';

import { FormattedMessage, injectIntl } from 'react-intl'; 

//placeholder={intl.formatMessage({ id: "Login.form.input.username.placeholder", defaultMessage: 'Username'})}

import s from './Profile.module.scss';

class Profile extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this._isMounted = false;

    this.state = {
      userInfo: JSON.parse(localStorage.getItem('userInfo'))
    };

    this.doUpdatePassword = this.doUpdatePassword.bind(this);
    this.getUserInfoFromLocalStorage = this.getUserInfoFromLocalStorage.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    if(!this.state.userInfo) {
      this.getUserInfoFromLocalStorage();
    }
  }

  getUserInfoFromLocalStorage() {
    const userInfoFromLocalStorage = localStorage.getItem('userInfo');
    this.setState({
      userInfo: JSON.parse(userInfoFromLocalStorage)
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  doUpdatePassword(e) {
    const { dispatch, intl } = this.props;
    e.preventDefault();
    if (this.state.userInfo) {
      dispatch(changeUserPassword(this.state.userInfo.email)).then(() => {
        dispatch(receiveUpdateUserPwd());
        showSuccessMessage(intl.formatMessage({ id: "Profile.message.password.update.success", defaultMessage: 'An email to reset your password has been sent to your inbox'}));
      })
      .catch((error) => {
        dispatch(updateUserPwdError(error));
      });
      GAEvent("ADMINPANEL", "Change account password", "PROFILE_PAGE_CHANGE_PASSWORD");
    }
  } 
  
  render() {

    const { userInfo } = this.state;
    const { managedRestaurant/*, intl*/ } = this.props;

    //console.log(userInfo);

    return (
      <div className={s.root}>
        <Breadcrumb>
          <BreadcrumbItem>{this.props.managedRestaurant ? this.props.managedRestaurant.name : '...'}</BreadcrumbItem>
          <BreadcrumbItem active><FormattedMessage id="Profile.Breadcrumbitem.MyAccount" defaultMessage="My Account" /></BreadcrumbItem>
        </Breadcrumb>
        <h1 className="page-title">
        <FormattedMessage id="Profile.PageTitle.User" defaultMessage="User" /> -&nbsp;
          <span className="fw-semi-bold">
            <FormattedMessage id="Profile.PageTitle.Profile" defaultMessage="Profile" />
          </span>
        </h1>

        <Row>
          <Col lg={12} xs={12}>
            <div className={s.mainDiv}>
              <div className="widget-top-overflow text-white">
              {
                  // in case we have uploaded an mp4
                  managedRestaurant && managedRestaurant.backgroundPicture && managedRestaurant.backgroundPicture.ext === ".mp4" ?
                    (
                      <div className={s.profileBackgroundVideoOrPicture + " overflow-hidden"}>
                        <video 
                          controlsList="nodownload" 
                          preload="auto" 
                          loop="loop" 
                          muted="muted"
                          autoPlay
                          className={s.videoscroll}>
                            <source src={managedRestaurant.backgroundPicture.url} type={managedRestaurant.backgroundPicture.mime} />
                        </video>
                      </div>
                    ) :
                    (
                      // if we have a background picture for the restaurant of the collaborator
                      managedRestaurant && managedRestaurant.backgroundPicture ? 
                      <div className={s.profileBackgroundVideoOrPicture + " overflow-hidden"}
                        style={{backgroundImage: "url("+ managedRestaurant.backgroundPicture.url + ")",
                                backgroundPosition: "center", backgroundSize: "cover"}}>
                      </div> :
                      // if we do not have any background picture then the alacarte logo is displayed
                      <div className="height-250 overflow-hidden" >
                        <img className={s.alacarteCenteredLogo} src={alacarteBlackLogo} alt="alacarte" />
                      </div>
                    )
                }
              </div>
              <Row>
                <Col lg={12} xs={12} className="text-center">
                  <div className={s.profileContactContainer}>
                    <span className="thumb-xl mb-3" style={{paddingTop: '10px'}}>
                      <img className={[s.profileAvatar, 'rounded-circle'].join(' ')} 
                        src={userInfo && userInfo.profilePicture ? userInfo.profilePicture.url : ''} 
                        alt={userInfo && userInfo.profilePicture ? userInfo.firstName + ' ' + userInfo.lastName : ''} />
                    </span>
                    <div>
                      <h5 className="fw-normal">
                        {userInfo.firstName} <span className="fw-semi-bold">{userInfo.lastName}</span>
                      </h5>
                      <ul className={cx(s.profileContacts, 'mt-sm')}>
                        <li><i className="fa fa-lg fa-envelope fa-fw mr-2" />
                          <button className="btn-link"> {userInfo.email}</button>
                        </li>
                        { 
                          managedRestaurant ?
                          <li><i className="fa fa-lg fa-cutlery fa-fw mr-2" />
                            {managedRestaurant.name}
                          </li> : null
                        }
                        {
                          managedRestaurant && managedRestaurant.collectTimeslots && managedRestaurant.collectTimeslots.length > 0 ?
                          <span>
                            <center>
                              <u>
                                <FormattedMessage id="Profile.collect.timeslots.label" defaultMessage="Order Collection timeslots" />
                              </u>
                            </center>
                            {
                              managedRestaurant.collectTimeslots.map((collectTimeslot) => {
                                return (
                                  <li>
                                    <center>
                                      {collectTimeslot.label}
                                    </center>
                                  </li>
                                );
                              })
                            }
                          </span>
                          : null
                        }
                      </ul>
                    </div>
                      <Form onSubmit={this.doUpdatePassword}>
                      {
                        this.props.errorMessage && ( // eslint-disable-line
                          <Alert
                            className="alert-transparent"
                            color="danger">
                              <span style={{color: 'black'}}>
                                <b>{this.props.errorMessage}</b>
                              </span>
                          </Alert>
                        )
                      }
                      <FormGroup row>
                        <Col lg={12} md={12} xs={12} sm={12}>
                          <Button color="primary" type="submit">
                            {this.props.isFetching ? 
                              <FormattedMessage id="Profile.form.button.SaveChanges.loading" defaultMessage="Loading..." /> : 
                              <FormattedMessage id="Profile.form.button.SaveChanges" defaultMessage="Reset password" />}
                            <i className="fa fa-lock ml-2" />
                          </Button>
                        </Col>
                      </FormGroup>
                    </Form>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  } // end of render
} // end of class

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
    managedRestaurant: state.auth.managedRestaurant
  };
}

export default injectIntl(withRouter(connect(mapStateToProps)(Profile)));
