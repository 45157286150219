import React from "react";
import PropTypes from 'prop-types';
import { FormattedMessage } from "react-intl";

import s from "./LegendBar.module.scss";

class LegendBar extends React.Component {
  static propTypes = {
    deliveryMethods: PropTypes.array.isRequired
  };

  constructor(props) {
    super(props);

    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { deliveryMethods, paymentMethods } = this.props;
    return (
      <div className={s.legendbar}>
        {
          // for dine in delivery method
          deliveryMethods.filter((dm) => dm.code === 'dinein').length > 0 ? (
            <span className={s.title} style={{backgroundColor: "#e9ecef"}}>
            <FormattedMessage
              id="LegendBar.component.dinein.order"
              defaultMessage="Order at table"
            />
          </span>
          ) : null
        }
        {
          // for room service delivery method
          deliveryMethods.filter((dm) => dm.code === 'roomservice').length > 0 ? (
            <span className={s.title} style={{backgroundColor: "rgb(84, 232, 191)"}}>
            <FormattedMessage
              id="LegendBar.component.roomservice.order"
              defaultMessage="Room service's order"
            />
          </span>
          ) : null
        }
        {
          // for delivery to location delivery methods
          deliveryMethods.filter((dm) => dm.code === 'delivertoofficewop' ||
          dm.code === 'deliverytoseat' ||
          dm.code === 'deliverytolocation').length > 0 ? (
            <span className={s.title} style={{backgroundColor: "lightgreen"}}>
            <FormattedMessage
              id="LegendBar.component.delivery.order"
              defaultMessage="Delivery order"
            />
          </span>
          ) : null
        }
        {
          // for delivery to location delivery methods
          deliveryMethods.filter((dm) => dm.code.includes('massageatspa')).length > 0 ? (
            <span className={s.title} style={{backgroundColor: "#33D1FF"}}>
            <FormattedMessage
              id="LegendBar.component.massageatspa.inquiry"
              defaultMessage="Massage inquiry"
            />
          </span>
          ) : null
        }
        {
          // for delivery to location delivery methods
          deliveryMethods.filter((dm) => dm.code === 'collectatkioskwop').length > 0 ? (
            <span className={s.title} style={{backgroundColor: "#FFAF33"}}>
            <FormattedMessage
              id="LegendBar.component.collectatkiosk.order"
              defaultMessage="Collect at Kiosk"
            />
          </span>
          ) : null
        }
        {
          // for delivery to location delivery methods
          deliveryMethods.filter((dm) => dm.code === 'collectatbarnp').length > 0 ? (
            <span className={s.title} style={{backgroundColor: "#02dbd4"}}>
            <FormattedMessage
              id="LegendBar.component.collectatbarnp.order"
              defaultMessage="Collect at Bar (no prepayment)"
            />
          </span>
          ) : null
        }
        {
          // for delivery to location delivery methods
          deliveryMethods.filter((dm) => dm.code === 'servicerequest').length > 0 ? (
            <span className={s.title} style={{backgroundColor: "#f09a4f"}}>
            <FormattedMessage
              id="LegendBar.component.servicerequest.order"
              defaultMessage="Service request"
            />
          </span>
          ) : null
        }
        {
          // We check if the restaurant accepts non online payments like cash or restaurant vouchers
          paymentMethods.filter((pm) => !pm.isOnlinePaymentMethod).length > 0 ? (
              <span className={s.title} style={{backgroundColor: "gold"}}>
              <FormattedMessage
                id="LegendBar.component.dineout.order.notyetpaid"
                defaultMessage="Order in cash"
              />
            </span>
          ) : null
        }
        {
          // for delivery methods like collect and with paid and not paid
          deliveryMethods.filter((dm) => dm.code !== 'dinein' && dm.isWithOnlinePayment).length > 0 ? (
            <span className={s.title} style={{backgroundColor: "lightskyblue"}}>
              <FormattedMessage
                id="LegendBar.component.dineout.order.paid"
                defaultMessage="Order paid online"
              />
            </span>
          ) : null
        }
      </div>
    );
  }
}

export default LegendBar;
