exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/**\n * Customized version of bootstrap using variables from _variables.scss.\n * This file is loaded via separate loader thus allowing to use original bootstrap classes (e.g. .btn-default) through out the app.\n */\n/*\n * Typography\n * ======================================================================== */\n/**\n * Custom application mixins available through out the app\n */\n.Layout_root__KuT-s {\n  height: 100%;\n  position: relative;\n  left: 0;\n  transition: left 0.3s ease-in-out; }\n  .Layout_root__KuT-s.Layout_chatOpen__1xo69 {\n    left: -274px; }\n\n.Layout_wrap__3Y4cM {\n  position: relative;\n  min-height: 100vh;\n  display: -webkit-flex;\n  display: flex;\n  margin-left: 50px;\n  -webkit-flex-direction: column;\n          flex-direction: column;\n  left: 174px;\n  right: 0;\n  transition: left 0.3s ease-in-out; }\n  @media (max-width: 767.98px) {\n    .Layout_wrap__3Y4cM {\n      margin-left: 0;\n      left: 224px; } }\n\n.Layout_sidebarClose__2rT6u div.Layout_wrap__3Y4cM {\n  left: 0; }\n\n.Layout_sidebarStatic__1FyVm .Layout_wrap__3Y4cM {\n  transition: none;\n  left: 0;\n  margin-left: 224px; }\n\n.Layout_content__3nMgS {\n  position: relative;\n  -webkit-flex-grow: 1;\n          flex-grow: 1;\n  padding: 40px 40px 60px;\n  background-color: #f9fbfd; }\n  @media (max-width: 767.98px) {\n    .Layout_content__3nMgS {\n      padding: 20px 15px; } }\n  @media (min-width: 576px) {\n    .Layout_content__3nMgS {\n      -webkit-user-select: auto !important;\n              user-select: auto !important; } }\n\n.Layout_contentFooter__3etPF {\n  position: absolute;\n  bottom: 5px;\n  color: #798892; }\n", ""]);

// exports
exports.locals = {
	"root": "Layout_root__KuT-s",
	"chatOpen": "Layout_chatOpen__1xo69",
	"wrap": "Layout_wrap__3Y4cM",
	"sidebarClose": "Layout_sidebarClose__2rT6u",
	"sidebarStatic": "Layout_sidebarStatic__1FyVm",
	"content": "Layout_content__3nMgS",
	"contentFooter": "Layout_contentFooter__3etPF"
};