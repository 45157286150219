import React from 'react';
import PropTypes from 'prop-types';
//`import { Redirect } from 'react-router';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getOrdersFromServer, updateOrderStatus } from '../../actions/orders';
import Board from 'react-trello'
import { getUserMeInfo } from '../../actions/user';
import {
  Button,
  ButtonGroup,
  Breadcrumb,
  BreadcrumbItem,
  Badge,
  Tooltip,
  InputGroupAddon,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import Datetime from 'react-datetime';
import {isEqual} from './helpers/helpers';
import update from 'immutability-helper';
import LegendBar from './components/LegendBar/LegendBar';

import '../../../node_modules/moment/min/locales.min';

import { FormattedMessage, injectIntl } from 'react-intl'; 
import ProgressIndicator from '../../components/ProgressIndicator';
import { GAEvent } from '../../components/Tracking';
import moment from 'moment';

import { showSuccessMessage } from '../../components/ToastMessenger';

import s from './Orders.module.scss';
import OrderCard from './components/OrderCard/OrderCard';
import { initializeSocket } from '../../actions/websocket';
import onlineImage from '../../images/orders/online.png';
import offlineImage from '../../images/orders/offline.png';

// Not best practice but this flag has been added to avoid a double call to the server
// looking at the isFetching property connected to the reducer which is not fast enougth
// changing value when a call to the backend is sent in the didupdate function and end
// up with another useless call to the server before the isFetching property is updated
// by default this flag is at false indicating that no call has been made to the server
// as soon as a call is made then it is put as true and only back to false when the
// component is unmounted
let isAFetchFromApiInProgress = false;
let callRefreshDataFromServer = false;
let isSocketBeingInitiated = false;

class Orders extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this._isMounted = false;

    const tempMoment = moment().clone();
    tempMoment.locale(props.intl.locale);

    this.state = {
      tooltipRefreshDataFromServer: [false],
      tooltipConnectedToServerWebSocket: [false],
      tooltipButtonReconnectToServerWebSocket: [false],
      isDatePickerOpen: false,
      dateForLoadingOrders: null, // tempMoment,
      todayDateInLocalTimezone: null,
      restaurantTimezone: null,
      clockLocalTime: null,
      boardData: undefined,
      draggedData: undefined,
      orders: undefined,
      eventBus: undefined,
      isDateNavigation: false, // specify is we are going to a previous or next day using the arrows
      moveCardToLane: this.moveCardToLane.bind(this),
      printOrderModal: false,
      printOrder: this.printOrder.bind(this),
      orderToPrint: undefined
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.openOrCloseDatePicker = this.openOrCloseDatePicker.bind(this);
    this.ordersDateChanged = this.ordersDateChanged.bind(this);
    this.goBackOneDay = this.goBackOneDay.bind(this);
    this.goOneDayForward = this.goOneDayForward.bind(this);
    this.refreshOrdersFromServer = this.refreshOrdersFromServer.bind(this);
    this.setEventBus = this.setEventBus.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.updateBoard = this.updateBoard.bind(this);
    this.updateOrderStatusInBackend = this.updateOrderStatusInBackend.bind(this);
    this.moveCardToLane = this.moveCardToLane.bind(this);
    this.printOrder = this.printOrder.bind(this);
    this.commitOrderMove = this.commitOrderMove.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.localClockTime = this.localClockTime.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //console.log('getDerivedStateFromProps');
    // if we did receive the orders list or previous list of orders
    // is different that the newly received one then we restructure
    // the orders response into an object that fits in the trello board
    //console.log('getDerivedStateFromProps prevState.orders => ', prevState.orders);
    if(
      (
        !prevState.orders &&
        nextProps.restaurantWithOrders
      ) 
      || 
      (
        prevState.orders &&
        nextProps.restaurantWithOrders &&
        !isEqual(prevState.orders, nextProps.restaurantWithOrders.orders)
      )
      ) {
        const trelloBoardData = {};
        const orders = nextProps.restaurantWithOrders ? nextProps.restaurantWithOrders.orders : undefined;
        const symbol = nextProps.restaurantWithOrders ? nextProps.restaurantWithOrders.city.country.currency.symbol : undefined;
        const currencyNumberOfDecimals = nextProps.restaurantWithOrders ? nextProps.restaurantWithOrders.city.country.currency.numberOfDecimals : undefined;
        const isRestaurantWithPrinters = nextProps.restaurantWithOrders && 
                                         nextProps.restaurantWithOrders.printers && nextProps.restaurantWithOrders.printers.length > 0 && 
                                          nextProps.restaurantWithOrders.printers.filter(printer => printer.isActive).length > 0
        if((orders && orders.length > 0)) {
          const {intl} = nextProps;
          
          // lane submitted orders
          const submittedOrders = orders ? orders.filter(order => order.status === "Submitted") : [];
          const laneSubmittedOrders = {
            id: 'Submitted',
            title: intl.formatMessage({ id: "Orders.trello.board.lane.submitted.orders.title", defaultMessage: 'Submitted Orders'}),
            label: submittedOrders.length.toString(),
            droppable: false,
            style: {width: 360, minHeight: 100, backgroundColor: '#3179ba', color: '#fff', boxShadow: '2px 2px 4px 0px rgba(0,0,0,0.75)'},
            cards: []
          };

          submittedOrders.forEach((order) => laneSubmittedOrders.cards.push({
            id: order.id,
            uuid: order.uuid,
            table: order.tableLabel,
            createdOn: order.createdOn,
            totalPrice: order.totalPrice,
            totalPriceCurrency: symbol,
            currencyNumberOfDecimals,
            status: order.status,
            orderNumber: order.orderNumber,
            orderContent: order.orderContent,
            type: order.type,
            paymentInformation: order.paymentInformation,
            extraInformation: order.extraInformation,
            customerPhoneNumberForNotification: order.customerPhoneNumberForNotification,
            cardStyle: {borderRadius: 6, boxShadow: '2px 2px 4px 0px rgba(0,0,0,0.5)', marginBottom: 15},
            isPrintingEnabled: isRestaurantWithPrinters,
            posSyncStatus: order.posSyncStatus,
            posName: order.posName,
            isDineInOrder: order.isDineInOrder,
            isCollectAtLocation: order.isCollectAtLocation,
            isDeliverToLocation: order.isDeliverToLocation,
            customerFirstname: order.customerFirstname,
            customerLastname: order.customerLastname,
            isWithOnlinePayment: order.isWithOnlinePayment,
            typeShortCode: order.typeShortCode,
            restaurantTimezone: prevState.restaurantTimezone,
            foodcategory_i18n: order.foodcategory_i18n,
            groupCodeForOrder: order.groupCodeForOrder,
            printOrder: (cardToPrint) => prevState.printOrder(cardToPrint),
            moveCardToLane: (cardToMove, fromLaneId, toLaneId) => prevState.moveCardToLane(cardToMove, fromLaneId, toLaneId)
          }));
  
          // lane accepted orders
          const acceptedOrders = orders ? orders.filter(order => order.status === "Accepted") : [];
          const laneAcceptedOrders = {
            id: 'Accepted',
            title: intl.formatMessage({ id: "Orders.trello.board.lane.accepted.orders.title", defaultMessage: 'Accepted Orders'}),
            label: acceptedOrders.length.toString(),
            droppable: true,
            style: {width: 360, minHeight: 100, backgroundColor: '#3179ba', color: '#fff', boxShadow: '2px 2px 4px 0px rgba(0,0,0,0.75)'},
            cards: [],
          };

          acceptedOrders.forEach((order) => laneAcceptedOrders.cards.push({
            id: order.id,
            uuid: order.uuid,
            table: order.tableLabel,
            createdOn: order.createdOn,
            acceptedOn: order.acceptedOn,
            totalPrice: order.totalPrice,
            totalPriceCurrency: symbol,
            currencyNumberOfDecimals,
            status: order.status,
            orderNumber: order.orderNumber,
            orderContent: order.orderContent,
            type: order.type,
            paymentInformation: order.paymentInformation,
            extraInformation: order.extraInformation,
            customerPhoneNumberForNotification: order.customerPhoneNumberForNotification,
            cardStyle: {borderRadius: 6, boxShadow: '2px 2px 4px 0px rgba(0,0,0,0.5)', marginBottom: 15},
            isStartCollapsed: false,
            isPrintingEnabled: isRestaurantWithPrinters,
            posSyncStatus: order.posSyncStatus,
            posName: order.posName,
            isDineInOrder: order.isDineInOrder,
            isCollectAtLocation: order.isCollectAtLocation,
            isDeliverToLocation: order.isDeliverToLocation,
            customerFirstname: order.customerFirstname,
            customerLastname: order.customerLastname,
            isWithOnlinePayment: order.isWithOnlinePayment,
            typeShortCode: order.typeShortCode,
            restaurantTimezone: prevState.restaurantTimezone,
            foodcategory_i18n: order.foodcategory_i18n,
            groupCodeForOrder: order.groupCodeForOrder,
            printOrder: (cardToPrint) => prevState.printOrder(cardToPrint),
            moveCardToLane: (cardToMove, fromLaneId, toLaneId) => prevState.moveCardToLane(cardToMove, fromLaneId, toLaneId)
          }));

          // lane orders ready to be served
          const ordersReadyToBeServed = orders ? orders.filter(order => order.status === "ReadyToServe") : [];
          const laneReadyToServeOrders = {
            id: 'ReadyToServe',
            title: intl.formatMessage({ id: "Orders.trello.board.lane.readytoserve.orders.title", defaultMessage: 'Ready to be served'}),
            label: ordersReadyToBeServed.length.toString(),
            droppable: true,
            style: {width: 360, minHeight: 100, backgroundColor: '#3179ba', color: '#fff', boxShadow: '2px 2px 4px 0px rgba(0,0,0,0.75)'},
            cards: []
          };

          ordersReadyToBeServed.forEach((order) => laneReadyToServeOrders.cards.push({
            id: order.id,
            uuid: order.uuid,
            table: order.tableLabel,
            createdOn: order.createdOn,
            totalPrice: order.totalPrice,
            totalPriceCurrency: symbol,
            currencyNumberOfDecimals,
            status: order.status,
            orderNumber: order.orderNumber,
            readyToBeServedOn: order.readyToBeServedOn,
            orderContent: order.orderContent,
            type: order.type,
            paymentInformation: order.paymentInformation,
            extraInformation: order.extraInformation,
            customerPhoneNumberForNotification: order.customerPhoneNumberForNotification,
            cardStyle: {borderRadius: 6, boxShadow: '2px 2px 4px 0px rgba(0,0,0,0.5)', marginBottom: 15},
            isStartCollapsed: false,
            isPrintingEnabled: isRestaurantWithPrinters,
            posSyncStatus: order.posSyncStatus,
            posName: order.posName,
            isDineInOrder: order.isDineInOrder,
            isCollectAtLocation: order.isCollectAtLocation,
            isDeliverToLocation: order.isDeliverToLocation,
            customerFirstname: order.customerFirstname,
            customerLastname: order.customerLastname,
            isWithOnlinePayment: order.isWithOnlinePayment,
            typeShortCode: order.typeShortCode,
            restaurantTimezone: prevState.restaurantTimezone,
            foodcategory_i18n: order.foodcategory_i18n,
            groupCodeForOrder: order.groupCodeForOrder,
            printOrder: (cardToPrint) => prevState.printOrder(cardToPrint),
            moveCardToLane: (cardToMove, fromLaneId, toLaneId) => prevState.moveCardToLane(cardToMove, fromLaneId, toLaneId)
          }));

          // lane orders registered in pos
          const ordersInPoS = orders ? orders.filter(order => order.status === "InPoS") : [];

          const laneInPoSOrders = {
            id: 'InPoS',
            title: intl.formatMessage({ id: "Orders.trello.board.lane.inpos.orders.title", defaultMessage: 'Registered in PoS'}),
            droppable: true,
            style: {width: 360, minHeight: 100, backgroundColor: '#3179ba', color: '#fff', boxShadow: '2px 2px 4px 0px rgba(0,0,0,0.75)'},
            cards: []
          };

          let totalPriceForAllOrdersInPoS = 0

          ordersInPoS.forEach((order) => {
            totalPriceForAllOrdersInPoS = totalPriceForAllOrdersInPoS + order.totalPrice
            laneInPoSOrders.cards.push({
                id: order.id,
                uuid: order.uuid,
                table: order.tableLabel,
                createdOn: order.createdOn,
                totalPrice: order.totalPrice,
                totalPriceCurrency: symbol,
                currencyNumberOfDecimals,
                status: order.status,
                orderNumber: order.orderNumber,
                orderContent: order.orderContent,
                type: order.type,
                paymentInformation: order.paymentInformation,
                extraInformation: order.extraInformation,
                customerPhoneNumberForNotification: order.customerPhoneNumberForNotification,
                cardStyle: {borderRadius: 6, boxShadow: '2px 2px 4px 0px rgba(0,0,0,0.5)', marginBottom: 15},
                isPrintingEnabled: isRestaurantWithPrinters,
                posSyncStatus: order.posSyncStatus,
                posName: order.posName,
                isDineInOrder: order.isDineInOrder,
                isCollectAtLocation: order.isCollectAtLocation,
                isDeliverToLocation: order.isDeliverToLocation,
                customerFirstname: order.customerFirstname,
                customerLastname: order.customerLastname,
                isWithOnlinePayment: order.isWithOnlinePayment,
                typeShortCode: order.typeShortCode,
                restaurantTimezone: prevState.restaurantTimezone,
                foodcategory_i18n: order.foodcategory_i18n,
                groupCodeForOrder: order.groupCodeForOrder,
                printOrder: (cardToPrint) => prevState.printOrder(cardToPrint),
                isStartCollapsed: true,
              })
            }
          );

          laneInPoSOrders.label = "Total: " + symbol + " " + Math.round((totalPriceForAllOrdersInPoS + Number.EPSILON) * 100) / 100  + " / " + (ordersInPoS.length).toString()

        trelloBoardData.lanes = [];
        trelloBoardData.lanes.push(laneSubmittedOrders);
        trelloBoardData.lanes.push(laneAcceptedOrders);
        trelloBoardData.lanes.push(laneReadyToServeOrders);
        trelloBoardData.lanes.push(laneInPoSOrders);
      }    

      return {
        orders,
        boardData: trelloBoardData,
        isDateNavigation: false
      };
    }

    // when socket is connected then we reset the initiation in progress flag
    //console.log('nextProps.isConnectedToWebSocketServer => ', nextProps.isConnectedToWebSocketServer);
    //console.log('nextProps.websocket => ', nextProps.websocket);
    //console.log('isSocketBeingInitiated => ', isSocketBeingInitiated);

    if (nextProps.managedRestaurant && !prevState.dateForLoadingOrders) {
      const restaurantTimezone = nextProps.managedRestaurant.city.momentJsTimezoneCode
      const dayAtLocaltime = moment.tz(new Date(), restaurantTimezone)
      // console.log('static props to state Orders dayAtLocaltime => ', dayAtLocaltime.format('YYYY-MM-DD HH:mm:ss'))
      return {
        dateForLoadingOrders: dayAtLocaltime,
        todayDateInLocalTimezone: dayAtLocaltime,
        restaurantTimezone
      };
    }
    
    // no change to state
    return null;
  }

  componentDidUpdate(prevProps) {
    //console.log('menus newProps => ', newProps);
    //console.log('menus this.props => ', this.props);
    if(prevProps.managedRestaurant && 
      this.props.restaurantWithOrders === undefined &&
      prevProps.restaurantWithOrders === undefined &&
       !this.props.isFetching && ! prevProps.isFetching && !isAFetchFromApiInProgress) {
        //console.log('calling server to get menus from component did update');
        //console.log('çalling server 1');
        this.refreshOrdersFromServer(true);
       }
      
    if(this.props.managedRestaurant &&
      prevProps.managedRestaurant === undefined && 
      !this.props.isFetching && ! prevProps.isFetching && !isAFetchFromApiInProgress) {
        //console.log('çalling server 3');
        this.refreshOrdersFromServer(true);
      }

    //console.log('this.props.updatedOrder => ', this.props.updatedOrder);
    //console.log('newProps.updatedOrder => ', newProps.updatedOrder);
    if (this.props.updatedOrder && this.props.updatedOrder.id && 
    (!prevProps.updatedOrder || (prevProps.updatedOrder.status !== this.props.updatedOrder.status) || (prevProps.updatedOrder.tableLabel !== this.props.updatedOrder.tableLabel)) ) {
      showSuccessMessage(
        this.props.intl.formatMessage({ id: "Orders.message.order.status.update.success", defaultMessage: 'Order successfully updated'})
      );
      //console.log('çalling server 2');
      this.refreshOrdersFromServer(true);
    }

    if(!this.props.websocket && this.props.managedRestaurant && !isSocketBeingInitiated) {
      // flag that socket is being initiated
      isSocketBeingInitiated = true;
      // console.log('init websocket managed Restaurant => ', this.props.managedRestaurant);
      const { slug } = this.props.managedRestaurant;
      //console.log('ínitializing socket did mount');
      this.props.dispatch(initializeSocket(slug));
    }

    // we left the orders page and came back but the date in date picker is not reflecting the
    // trello board orders date

    // clone state moment so we can force the locale to english no matter
    // what the user locale is so we compare en date with en date and not en with ar or chinese date
    const tempMoment = this.state.dateForLoadingOrders ? this.state.dateForLoadingOrders.clone() : null;
    if (tempMoment)
      tempMoment.locale('fr');

    if (tempMoment && this.props.restaurantWithOrders && 
      this.props.restaurantWithOrders.orders.length > 0 && 
      this.props.restaurantWithOrders.orders[0].createdOn.substring(0,10) 
        !== tempMoment.format('YYYY-MM-DD') &&
        !this.props.isFetching && ! prevProps.isFetching && !isAFetchFromApiInProgress) {
        //console.log('çalling server 4');
        this.refreshOrdersFromServer(true);
    }

    // we check when the websocket is initialized and when it is connected and if it does not
    // have the update order listener then we add it only once
    if (this.props.websocket && this.props.isConnectedToWebSocketServer && this.props.managedRestaurant
       && ((this.props.websocketMessage && !prevProps.websocketMessage) || 
        (this.props.websocketMessage && prevProps.websocketMessage && this.props.websocketMessage.uuid !== prevProps.websocketMessage.uuid))) {
          this.refreshOrdersFromServer(true);
      }
  }

  componentWillUnmount() {
    isSocketBeingInitiated = false;
    clearInterval(this.state.clockInterval)
    isAFetchFromApiInProgress = false;
    this._isMounted = false;
  }

  refreshOrdersFromServer(forceCallToServer = false) {
    const { dispatch } = this.props;
    let { userInfo } = this.props;
    // we put the flag that a call to the server is being done
    isAFetchFromApiInProgress = true;
    // when component is mounted then get menus from the server
    if(!userInfo) userInfo = JSON.parse(localStorage.getItem('userInfo'));
    //console.log('userInfo => ', userInfo);

    if((userInfo && !this.props.isFetching 
      && (typeof this.props.restaurantWithOrders === 'undefined')) || forceCallToServer) {
      // get the restaurant for which we want to load the menus
      const restaurantSlug = this.props.managedRestaurant ? this.props.managedRestaurant.slug : userInfo.restaurants ? userInfo.restaurants[0].slug : null;
      if (restaurantSlug) {
        // console.log('calling server to get orders');
        //console.log(Datetime.moment().format('YYYY-MM-DD'));
        // clone current moment stored in state
        const tempMoment = this.state.dateForLoadingOrders ? this.state.dateForLoadingOrders.clone() : null ;
        const restaurantTimezone = this.props.managedRestaurant ? this.props.managedRestaurant.city.momentJsTimezoneCode : null
        // change temp moment locale to french so that we make sure
        // that we are sending a date to the api that is understandable and not in arabic or chinese
        tempMoment.locale('fr');
        // console.log('day in local timezone => ', tempMoment.format('YYYY-MM-DD HH:mm:ss'));
        dispatch(getOrdersFromServer(restaurantSlug, tempMoment.format('YYYY-MM-DD'), restaurantTimezone));
      } else {
        // if we do not know which restaurant the current user in managing then
        // we request the info from the server
        //console.log('request user me info in menus page');
        dispatch(getUserMeInfo());
      }
    }

    callRefreshDataFromServer = false;
  }

  ordersDateChanged(moment, userInteraction = true) {
    // if new date selected then we request data from server
    if(this.state.dateForLoadingOrders.date() !== moment.date()) {
      callRefreshDataFromServer = true;
      this.setState({
        dateForLoadingOrders: moment,
      });
    }
    //console.log('isoStringDateWithoutTime => ', isoStringDateWithoutTime);
    // Close date picker
    if(userInteraction)
      this.openOrCloseDatePicker();
  }

  openOrCloseDatePicker() {
    this.setState({
      isDatePickerOpen: !this.state.isDatePickerOpen
    });
  }

  setEventBus(handle) {
    //console.log('setting eventBus => ', handle);
    this.setState({
      eventBus: handle,
    });
  }

  moveCardToLane(cardToMove, fromLaneId, toLaneId) {
    //console.log('ici >>>>>>>. ', this.state.eventBus, fromLaneId, toLaneId)
    this.state.eventBus.publish({
      type: 'MOVE_CARD',
      fromLaneId: fromLaneId,
      toLaneId: toLaneId,
      cardId: cardToMove.id
    });
    this.commitOrderMove(fromLaneId, toLaneId, cardToMove);
  }

  printOrder(orderToPrint) {
    //console.log('orderToPrint => ', orderToPrint);
    this.setState({
      printOrderModal: true,
      orderToPrint
    });
  }

  setLocaltime() {
    if(this._isMounted) {
      const dayAtLocaltime = moment.tz(new Date(), this.state.restaurantTimezone)
      this.setState({
        clockLocalTime: dayAtLocaltime.format('HH:mm:ss'),
        todayDateInLocalTimezone: dayAtLocaltime
      })
    }
  }

  localClockTime() {
    if (!this.state.localClockTimeInterval) {
      const clockInterval = setInterval(
        this.setLocaltime.bind(this),
        1000
      ); 
      this.setState({
        localClockTimeInterval: clockInterval
      })
    }
  }

  goBackOneDay() {
    // no user interaction so sending false so that we do not influence the open date picker state variable
    //console.log(this.state.dateForLoadingOrders);
    // if new date selected then we request data from server
    const currentMoment = this.state.dateForLoadingOrders ? this.state.dateForLoadingOrders.clone() : null;
    const previousDay = currentMoment.subtract(1,"days");
    callRefreshDataFromServer = true;
    // flag to say we are using the arrows to navigate between days and to avoid the flickering in the trello board
    // seeing the price being displayed as the date is changing but the trello board still shows up and the call
    // the server did not happen
    this.setState({
      dateForLoadingOrders: previousDay,
      isDateNavigation: true
    });
    GAEvent("ADMINPANEL", "Go back one day", "ORDERS_PAGE_GO_BACK_ONE_DAY_BUTTON");
  }

  goOneDayForward() {
    // no user interaction so sending false so that we do not influence the open date picker state variable
    //console.log(this.state.dateForLoadingOrders);
    // if new date selected then we request data from server
    // clone the moment saved in the state because if we do not do so
    // then using the add or subtract on the state moment does modify the
    // state object without doing a setState
    const currentMoment = this.state.dateForLoadingOrders ? this.state.dateForLoadingOrders.clone() : null;
    const nextDay = currentMoment.add(1,"days");
    const today = Datetime.moment();
    // if next day is before today then good to go as no orders will be in a date future to today
    if(nextDay.isBefore(today)) {
      callRefreshDataFromServer = true;
      // flag to say we are using the arrows to navigate between days and to avoid the flickering in the trello board
      // seeing the price being displayed as the date is changing but the trello board still shows up and the call
      // the server did not happen
      this.setState({
        dateForLoadingOrders: nextDay,
        isDateNavigation: true
      });
    } else {
      console.log('Day not available');
    }

    GAEvent("ADMINPANEL", "Go one day forward", "ORDERS_PAGE_GO_ONE_DAY_FORWARD_BUTTON");
  }

  toggleTooltip(id, field) {
    const newState = [...this.state[field]];
    newState.fill(false);

    if (!this.state[field][id]) {
      newState[id] = true;
    }

    this._isMounted && this.setState({
      [field]: newState,
    });
  }

  updateBoard(newData) {
    //console.log('calling updateBoard');
    //console.log('newData (before) => ', newData);

    // for each date updated we are sorting the data by created on so the just created one
    // will be on the top
    newData.lanes.forEach(lane => {
      lane.cards.sort((card1, card2) => {
        return new Date(card2.createdOn) - new Date(card1.createdOn);
      })
    });

    this.setState({boardData: newData});
  }

  toggleModal(id) {
    this.setState(prevState => ({
      [id]: !prevState[id],
    }));
  }

  commitOrderMove(sourceLaneId, targetLaneId, card) {
    //console.log('card to commit => ', card);
    //console.log('sourceLaneId => ', sourceLaneId);
    //console.log('targetLaneId => ', targetLaneId);
    // update the counter in the target lane which is located in the label property of the lane
    const targetLaneIndex = this.state.boardData.lanes.findIndex(lane => lane.id === targetLaneId);
    const updatedNumberOfOrdersInTargetLane = this.state.boardData.lanes[targetLaneIndex].cards.length + 1;
    const updatedData = update(this.state.boardData, {lanes: {[targetLaneIndex]: {label: {$set: updatedNumberOfOrdersInTargetLane.toString()}}}});

    // update the counter in the source lane which is located in the label property of the lane
    const sourceLaneIndex = this.state.boardData.lanes.findIndex(lane => lane.id === sourceLaneId);
    const updatedNumberOfOrdersInSourceLane = this.state.boardData.lanes[sourceLaneIndex].cards.length - 1;
    const finalUpdatedData = update(updatedData, {lanes: {[sourceLaneIndex]: {label: {$set: updatedNumberOfOrdersInSourceLane.toString()}}}});
    //console.log('finalUpdatedData => ', finalUpdatedData);

    //const updatedOrderIndex = this.state.orders.findIndex(order => order.id === cardId);
    //const updatedOrdersData = update(this.state.orders, {[updatedOrderIndex]: {status: {$set: targetLaneId}}});

    //console.log('updatedOrdersData => ', updatedOrdersData);
    //console.log('card => ', card);
    const orderToBeUpdated = Object.assign({}, card);
    const previousOrderStatus = sourceLaneId;
    if (orderToBeUpdated.status !== "Refunded" && orderToBeUpdated.status !== "Rejected")
      orderToBeUpdated.status = targetLaneId;
    //console.log('orderToBeUpdated => ', orderToBeUpdated);

    this.setState({
      boardData: finalUpdatedData,
      //orders: updatedOrdersData
    });

    this.updateOrderStatusInBackend(orderToBeUpdated, previousOrderStatus);

  }

  onDragEnd(cardId, sourceLaneId, targetLaneId, position, card) {
    /*console.log('Calling onDragEnd');
    console.log(`cardId: ${cardId}`);
    console.log(`sourceLandId: ${sourceLandId}`);
    console.log(`targetLaneId: ${targetLaneId}`);*/
    //console.log('card => ', card);

    // we update counters in lanes only if we are dropping the item in a different lane that the source lane
    if(sourceLaneId !== targetLaneId) {
      // if we are dropping the card in the ready to serve lane
      // then by default it is open so we can print it for the PoS
      //console.log('target lane => ', targetLaneId);
      //console.log('card => ', card);
      if(targetLaneId === 'ReadyToServe')
        card.isStartCollapsed = false;
      this.commitOrderMove(sourceLaneId, targetLaneId, card);
    }
  }

  updateOrderStatusInBackend(orderToUpdate, previousOrderStatus) {
    const {dispatch} = this.props;
    dispatch(updateOrderStatus(orderToUpdate, previousOrderStatus));
  }

  render() {

    const { orders } = this.props.restaurantWithOrders ? this.props.restaurantWithOrders : {orders: undefined, name : undefined} ;

    if(callRefreshDataFromServer) {
      //console.log('this.state.dateForLoadingOrders => ', this.state.dateForLoadingOrders);
      this.refreshOrdersFromServer(true);
    }

    this.localClockTime();

    // clone moment
    const tempMoment = this.state.dateForLoadingOrders ? this.state.dateForLoadingOrders.clone() : null;
    // force its locale to french to avoid arabic or chinese data displays
    if (tempMoment)
      tempMoment.locale('fr');

    const selectedDatetimeIsTodayDate = tempMoment ? this.state.dateForLoadingOrders.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') : false;

    return (
      <div className={s.root}>
        <Breadcrumb>
          <BreadcrumbItem>{this.props.managedRestaurant? this.props.managedRestaurant.name : null}</BreadcrumbItem>
          <BreadcrumbItem active>
            <FormattedMessage id="Orders.Breadcrumbitem.Orders" defaultMessage="Orders" />&nbsp;&nbsp;
            {orders && orders.length > 0 ? <Badge color="primary">{orders.length}</Badge> : null}
            <span>&nbsp;&nbsp;
              <img id="imageServerWebSocketConnectivity"
                style={{height: 23}} 
                src={this.props.isConnectedToWebSocketServer ? onlineImage : offlineImage} 
                alt="connectivity"/>
                <Tooltip placement="top" isOpen={this.state.tooltipConnectedToServerWebSocket[0]} 
                         toggle={() => this.toggleTooltip(0, 'tooltipConnectedToServerWebSocket')} 
                         target={"imageServerWebSocketConnectivity"}>
                  {
                    this.props.isConnectedToWebSocketServer ?
                    <FormattedMessage id="Orders.image.server.websocket.connectivity.online" 
                                      defaultMessage="Realtime connection online" /> :
                    <FormattedMessage id="Orders.image.server.websocket.connectivity.offline" 
                                      defaultMessage="Realtime connection offline" />
                  }
                </Tooltip>
                {
                  !this.props.isConnectedToWebSocketServer ?
                  <span>
                    <Button id='buttonReconnectToWebSocketServer'
                      className={s.buttonReconnectToWebSocket} 
                      onClick={() => {
                        const { slug } = this.props.managedRestaurant;
                        this.props.dispatch(initializeSocket(slug))
                      }} 
                      color="default">
                      <i className="fa fa-plug" style={{position: 'relative', left: '-6px', top: '-1px'}}></i>
                    </Button>
                    <Tooltip placement="top" isOpen={this.state.tooltipButtonReconnectToServerWebSocket[0]} 
                          toggle={() => this.toggleTooltip(0, 'tooltipButtonReconnectToServerWebSocket')} 
                          target={"buttonReconnectToWebSocketServer"}>
                      <FormattedMessage id="Orders.button.reconnect.to.websocket.server" 
                                        defaultMessage="Reconnect to realtime server" />
                    </Tooltip>
                  </span>
                  : null
                }
            </span>
          </BreadcrumbItem>
        </Breadcrumb>
        {
          // if we have other than dine in then we display the legend bar
          this.props.restaurantWithOrders &&
          this.props.managedRestaurant &&
          this.props.managedRestaurant.paymentMethods &&
          this.props.restaurantWithOrders.deliverymethods &&
          this.props.restaurantWithOrders.deliverymethods.length > 0 ?
          <LegendBar deliveryMethods={this.props.restaurantWithOrders.deliverymethods} 
                     paymentMethods={this.props.managedRestaurant.paymentMethods}/> : null
        }
        {this.props.isFetching ? <ProgressIndicator />: null}
        <div className={s.galleryControls}>
          <div className="datepicker" style={{display: 'flex'}}>
            {
              tempMoment ? (
              <Datetime
                id="datepicker"
                open={this.state.isDatePickerOpen}
                viewMode="days" timeFormat={false}
                inputProps={{ 
                  ref: (input) => { this.refDatePicker = input; }, 
                  id: 'datepickerInputField', 
                  value: tempMoment.format('YYYY-MM-DD'),
                  disabled: true 
                }}
                onChange={this.ordersDateChanged}
                dateFormat="YYYY-MM-DD"
                /*value={this.state.dateForLoadingOrders}*/
                isValidDate={ (current) => {
                    // valid date is from today and in the past
                    var today = Datetime.moment();
                    return current.isBefore( today );
                  } 
                }
              />
              ) : null
            }
            <InputGroupAddon addonType="append">
              <span className="input-group-text" onClick={() => {
                // if input does not have focus then we set automatic focus
                /*if(this.refDatePicker !== document.activeElement)
                  this.refDatePicker.focus();*/
                this.openOrCloseDatePicker();
                GAEvent("ADMINPANEL", "Open/Close calendar using calendar button", "ORDERS_PAGE_OPEN_OR_CLOSE_CALENDAR_USING_CALENDAR_BUTTON");
                //this._eventBus.publish({type: 'ADD_CARD', laneId: 'Submitted', card: {id: "M1", title: "Buy Milk", label: "15 mins", description: "Also set reminder"}});
              }
              }><i className="fa fa-calendar" /></span>
            </InputGroupAddon>
            &nbsp;&nbsp;
            <InputGroupAddon addonType="append">
              <span className="input-group-text" onClick={this.goBackOneDay}><i className="fa fa-chevron-left" /></span>
              <span className="input-group-text" onClick={this.goOneDayForward}><i className="fa fa-chevron-right" /></span>
            </InputGroupAddon>
            &nbsp;&nbsp;
            { 
              this.state.dateForLoadingOrders && this.state.clockLocalTime &&
              this.state.dateForLoadingOrders.format('YYYY-MM-DD') === this.state.todayDateInLocalTimezone.format('YYYY-MM-DD')? (
                <span className="input-group-text">
                  { 
                    this.state.clockLocalTime
                  }
                </span>
              ) : null 
            }
          </div>
          {orders && selectedDatetimeIsTodayDate ?
            <div>
              <ButtonGroup id="buttonRefreshDataFromServer" className={s.buttonRefresh}>
                <Button onClick={() => {
                  this.refreshOrdersFromServer(true);
                  GAEvent("ADMINPANEL", "Refresh data from server", "ORDERS_PAGE_REFRESH_DATA_FROM_SERVER");
                }} color="default"><i className="la la-refresh"></i></Button>
              </ButtonGroup>
              <Tooltip placement="top" isOpen={this.state.tooltipRefreshDataFromServer[0]} toggle={() => this.toggleTooltip(0, 'tooltipRefreshDataFromServer')} 
                target={"buttonRefreshDataFromServer"}>
                <FormattedMessage id="Orders.button.refresh.data.from.server" defaultMessage="Refresh list of orders" />
              </Tooltip>
            </div>: null}
          </div>
        {
          orders && orders.length === 0 ? 
            <span>&nbsp;<FormattedMessage id="Orders.Page.No.Orders.Available" defaultMessage="No orders available for this date" />.</span> : null
        }
        {(orders && orders.length > 0) && !this.state.isDateNavigation?
          <div className={s.trelloBoard}>
            <Board data={this.state.boardData} 
                   draggable
                   laneDraggable={false}
                   cardDraggable={selectedDatetimeIsTodayDate}
                   style={{backgroundColor: '#eee'}} 
                   eventBusHandle={this.setEventBus}
                   onDataChange={this.updateBoard}
                   handleDragEnd={this.onDragEnd}
                   components={{ Card: OrderCard }} >
            </Board>
          </div> : null}
          {/* Modal used to display order with barcodes */}
            <Modal id="modalToPrintOrder"
              isOpen={this.state.printOrderModal} toggle={() => this.toggleModal('printOrderModal')}>
              <ModalHeader toggle={() => this.toggleModal('printOrderModal')}>
                <FormattedMessage id="Orders.Page.modal.order.barcodes.title" 
                  defaultMessage="Order with barcodes" />
              </ModalHeader>
              <ModalBody className="bg-white" style={{textAlign: 'none'}}>
                <OrderCard
                  {...this.state.orderToPrint}
                  isShowBarCodes={true}/>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={() => this.toggleModal('printOrderModal')}>
                  <FormattedMessage id="Orders.Page.modal.order.barcodes.button.close.label" 
                    defaultMessage="Close" />
                </Button>
                {/*<Button color="primary">Save changes</Button>*/}
              </ModalFooter>
            </Modal>
      </div>);
  } // enf of render
} // end of class

function mapStateToProps(store) {
  return {
    isFetching: store.order.isFetching,
    errorMessage: store.order.errorMessage,
    restaurantWithOrders: store.order.restaurantWithOrders,
    userInfo: store.auth.userInfo,
    managedRestaurant: store.auth.managedRestaurant,
    updatedOrder: store.order.updatedOrder,
    websocket: store.websocket.socket,
    websocketMessage: store.websocket.messageObject,
    isConnectedToWebSocketServer: store.websocket.connected
  };
}

export default injectIntl(withRouter(connect(mapStateToProps)(Orders)));
