exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".LocaleSelector_imageStyleForActiveLocaleItem__3JeId {\n  margin-top: -1px;\n  height: 15px; }\n\n.LocaleSelector_imageStyleForNonActiveLocaleItem__2PJSj {\n  height: 15px;\n  margin-top: 3px; }\n\n.LocaleSelector_dropDownMenuStyle__DNmBj {\n  min-width: 100px !important; }\n\n.LocaleSelector_dropDownMenuItemStyle__1SYbZ {\n  padding: 0.25rem 0.5rem !important; }\n", ""]);

// exports
exports.locals = {
	"imageStyleForActiveLocaleItem": "LocaleSelector_imageStyleForActiveLocaleItem__3JeId",
	"imageStyleForNonActiveLocaleItem": "LocaleSelector_imageStyleForNonActiveLocaleItem__2PJSj",
	"dropDownMenuStyle": "LocaleSelector_dropDownMenuStyle__DNmBj",
	"dropDownMenuItemStyle": "LocaleSelector_dropDownMenuItemStyle__1SYbZ"
};