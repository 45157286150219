import React from 'react';
import PropTypes from 'prop-types';

import {
    Tooltip,
  } from 'reactstrap';

import {FormattedMessage} from 'react-intl';

import s from './Allergens.module.scss';

class Allergens extends React.Component {
    static propTypes = {
        listOfAllergens: PropTypes.array.isRequired,
        locale: PropTypes.string.isRequired,
        islistview: PropTypes.bool
    };

    static defaultProps = {
      islistview: true
    };

    constructor(props) {
      super(props);

      this._isMounted = false;
  
      this.state = {
        tooltipsAllergens: []
      }
  
      this.toggleTooltip = this.toggleTooltip.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
  
    toggleTooltip(id, field) {
      const newState = [...this.state[field]];
      newState.fill(false);
  
      if (!this.state[field][id]) {
        newState[id] = true;
      }
  
      this._isMounted && this.setState({
        [field]: newState,
      });
    }

    render() {
        return(
            <div className={this.props.islistview? s.allergensForListView : s.allergens}>
              {
                !this.props.islistview ? (
                  <span className={s.title}>
                    <FormattedMessage id="Allergens.component.title" defaultMessage="Allergens" />
                </span>
                ) : null
              }
              {
                this.props.listOfAllergens.map((allergen, index) => {
                  return (
                    <div key={'allergen-' + allergen.id + '-' + index}>
                      <div 
                        id={"allergen-" + allergen.id}
                        style={{ 
                          backgroundImage: `url(${allergen.icon && allergen.icon.formats && allergen.icon.formats.thumbnail ?
                            allergen.icon.formats.thumbnail.url : allergen.icon.url})`
                          }}
                      />
                      {
                        !this.props.islistview ?
                        <Tooltip placement="top" 
                        isOpen={this.state.tooltipsAllergens[index]} 
                        toggle={() => this.toggleTooltip(index, "tooltipsAllergens")} 
                          target={"allergen-" + allergen.id}>
                            {allergen.name_i18n[this.props.locale]}
                        </Tooltip> :
                        <span className={s.allergenLabelListView}>
                          {allergen.name_i18n[this.props.locale]}
                        </span>
                      }
                    </div>
                  );
                })
              }
            </div>
        );
    }
}

export default Allergens;