import React from 'react';
import PropTypes from 'prop-types';
//`import { Redirect } from 'react-router';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getMenusFromServer, updateMenuVisibility } from '../../actions/menu';
import { getUserMeInfo } from '../../actions/user';
import {
  Button,
  ButtonGroup,
  Breadcrumb,
  BreadcrumbItem,
  Badge,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from 'reactstrap';

import { FormattedMessage, injectIntl } from 'react-intl'; 
import ProgressIndicator from '../../components/ProgressIndicator';

import { showSuccessMessage } from '../../components/ToastMessenger';
import CarouselMenu from '../../components/CarouselMenu/CarouselMenu';

import eye from '../../images/eyes/eye.svg';
import eyeSlash from '../../images/eyes/eye-slash.svg';
import { GAEvent } from "../../components/Tracking/Tracking";
import DaysAvailabilityBar from "./DaysAvailabilityBars/DaysAvailabilityBar";

import s from './Menus.module.scss';

// Not best practice but this flag has been added to avoid a double call to the server
// looking at the isFetching property connected to the reducer which is not fast enougth
// changing value when a call to the backend is sent in the didupdate function and end
// up with another useless call to the server before the isFetching property is updated
// by default this flag is at false indicating that no call has been made to the server
// as soon as a call is made then it is put as true and only back to false when the
// component is unmounted
let isAFetchFromApiInProgress = false;

class Menus extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this._isMounted = false;

    this.state = {
      isModalHideMenuOpen: false,
      isModalShowMenuOpen: false,
      isModalHideAllMenusOpen: false,
      selectedMenuToHideOrShow: null,
      tooltipsShowHideMenu: [],
      tooltipRefreshDataFromServer: [false],
      tooltipHideAllMenus: [false],
      tooltipActivateAllMenus: [false],
      currentImage: 0,
      lightboxIsOpen: false,
      theme: {
        arrow: {
          ':focus': {
            outline: 0,
          },
        },
        close: {
          ':focus': {
            outline: 0,
          },
        },
      },
    };

    this.closeLightbox = this.closeLightbox.bind(this);
    this.handleClickImage = this.handleClickImage.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.refreshMenusFromServer = this.refreshMenusFromServer.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.hideOrShowMenu = this.hideOrShowMenu.bind(this);
    this.toggleModalHideMenu = this.toggleModalHideMenu.bind(this);
    this.toggleModalShowMenu = this.toggleModalShowMenu.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
    this.hideAllMenus = this.hideAllMenus.bind(this);
    this.toggleModalHideAllMenus = this.toggleModalHideAllMenus.bind(this);
    this.toggleModalActivateAllMenus = this.toggleModalActivateAllMenus.bind(this);
    this.showAllMenus = this.showAllMenus.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    //console.log('this.props.restaurantWithMenus in componentDidMount => ', this.props.restaurantWithMenus);
    //console.log('this.props.isFetching in componentDidMount => ', this.props.isFetching);
    //console.log('isAFetchFromApiInProgress => ', isAFetchFromApiInProgress);
    if (this.props.restaurantWithMenus === undefined && !this.props.isFetching && !isAFetchFromApiInProgress) {
      //console.log('calling server to get menus in componentDidMount');
      //console.log('çalling server 0');
      this.refreshMenusFromServer();
    }
  }

  componentDidUpdate(newProps) {
    //console.log('menus newProps => ', newProps);
    //console.log('menus this.props => ', this.props);
    if(newProps.managedRestaurant && 
      this.props.restaurantWithMenus === undefined &&
      newProps.restaurantWithMenus === undefined &&
       !this.props.isFetching && ! newProps.isFetching && !isAFetchFromApiInProgress) {
        //console.log('calling server to get menus from component did update');
        //console.log('çalling server 1');
        this.refreshMenusFromServer(true);
       }
      
    if(this.props.managedRestaurant &&
      newProps.managedRestaurant === undefined && 
      !this.props.isFetching && ! newProps.isFetching) {
        //console.log('çalling server 3');
        this.refreshMenusFromServer(true);
      }

    if (this.props.updatedMenu.id && 
    (newProps.updatedMenu.id !== this.props.updatedMenu.id || newProps.updatedMenu.isActive !== this.props.updatedMenu.isActive) ) {
      showSuccessMessage(this.props.updatedMenu.userLabel_i18n[this.props.intl.locale] + " " + this.props.intl.formatMessage({ id: "Menus.message.menu.isactive.update.success", defaultMessage: 'successfully updated'}));
      //console.log('çalling server 2');
      this.refreshMenusFromServer(true);
    }

    if (this.props.managedRestaurant && newProps.managedRestaurant &&
       this.props.managedRestaurant.id !== newProps.managedRestaurant.id) {
         this.refreshMenusFromServer(true);
       }
  }

  componentWillUnmount() {
    isAFetchFromApiInProgress = false;
    this._isMounted = false;
  }

  hideOrShowMenu(Menus, index) {
    const menuToUpdate =  Menus[index];
    //menuItemToUpdate.isActive = !menuItemToUpdate.isActive;
    if(menuToUpdate.isActive) {
      this._isMounted && this.setState({
        isModalHideMenuOpen: true,
        isModalShowMenuOpen: false,
        selectedMenuToHideOrShow: menuToUpdate
      });
    } else {
      this._isMounted && this.setState({
        isModalShowMenuOpen: true,
        isModalHideMenuOpen: false,
        selectedMenuToHideOrShow: menuToUpdate
      });
    }
  }

  refreshMenusFromServer(forceCallToServer = false) {
    const { dispatch } = this.props;
    let { userInfo } = this.props;
    // we put the flag that a call to the server is being done
    isAFetchFromApiInProgress = true;
    // when component is mounted then get menus from the server
    if(!userInfo) userInfo = JSON.parse(localStorage.getItem('userInfo'));
    //console.log('userInfo => ', userInfo);

    if((userInfo && !this.props.isFetching 
      && (typeof this.props.restaurantWithMenus === 'undefined')) || forceCallToServer) {
      // console.log('refreshMenusFromServer this.props.managedRestaurant => ', this.props.managedRestaurant);
      // get the restaurant for which we want to load the menus
      const restaurantSlug = this.props.managedRestaurant ? this.props.managedRestaurant.slug : userInfo.restaurants ? userInfo.restaurants[0].slug : null;
      //console.log('restaurantId => ', restaurantId);
      if (restaurantSlug) {
        //console.log('calling server to get menus');
        dispatch(getMenusFromServer(restaurantSlug));
      } else {
        // if we do not know which restaurant the current user in managing then
        // we request the info from the server
        //console.log('request user me info in menus page');
        dispatch(getUserMeInfo());
      }
    }
  }

  showMenu() {
    const {dispatch} = this.props;
    const {selectedMenuToHideOrShow} = this.state;
    dispatch(updateMenuVisibility(selectedMenuToHideOrShow));
    GAEvent("ADMINPANEL", "Show menu", "MENUS_PAGE_SHOW_MENU");
    this._isMounted && this.setState({
      isModalShowMenuOpen: false,
      isModalHideMenuOpen: false,
      selectedMenuToHideOrShow: null
    });
  }

  hideMenu() {
    const {dispatch} = this.props;
    const {selectedMenuToHideOrShow} = this.state;
    dispatch(updateMenuVisibility(selectedMenuToHideOrShow));
    GAEvent("ADMINPANEL", "Hide menu", "MENUS_PAGE_HIDE_MENU");
    this._isMounted && this.setState({
      isModalShowMenuOpen: false,
      isModalHideMenuOpen: false,
      selectedMenuToHideOrShow: null
    });
  }

  hideAllMenus() {
    const { menus } = this.props.restaurantWithMenus
    if (menus && menus.length > 0) {
      const {dispatch} = this.props;
      for (let index = 0; index < menus.length; index++) {
        const menuObject = menus[index];
        if (menuObject.isActive) {
          dispatch(updateMenuVisibility(menuObject));
        }
      }
      GAEvent("ADMINPANEL", "Hide all menus", "MENUS_PAGE_HIDE_ALL_MENUS");
      this._isMounted && this.setState({
        isModalHideAllMenusOpen: false
      });
    }
  }

  showAllMenus(){
    const { menus } = this.props.restaurantWithMenus
    if (menus && menus.length > 0) {
      const {dispatch} = this.props;
      for (let index = 0; index < menus.length; index++) {
        const menuObject = menus[index];
        if (!menuObject.isActive) {
          dispatch(updateMenuVisibility(menuObject));
        }
      }
      GAEvent("ADMINPANEL", "Activate all menus", "MENUS_PAGE_SHOW_ALL_MENUS");
      this._isMounted && this.setState({
        isModalActivateAllMenusOpen: false
      });
    }
  }

  toggleModalHideMenu() {
    this._isMounted && this.setState({
      isModalHideMenuOpen: !this.state.isModalHideMenuOpen
    })
  }

  toggleModalHideAllMenus() {
    this._isMounted && this.setState({
      isModalHideAllMenusOpen: !this.state.isModalHideAllMenusOpen
    })
  }

  toggleModalActivateAllMenus() {
    this._isMounted && this.setState({
      isModalActivateAllMenusOpen: !this.state.isModalActivateAllMenusOpen
    })
  }

  toggleModalShowMenu() {
    this._isMounted && this.setState({
      isModalShowMenuOpen: !this.state.isModalShowMenuOpen
    })
  }

  toggleTooltip(id, field) {
    const newState = [...this.state[field]];
    newState.fill(false);

    if (!this.state[field][id]) {
      newState[id] = true;
    }

    this._isMounted && this.setState({
      [field]: newState,
    });
  }

  openLightbox(index, event) {
    event.preventDefault();
    this._isMounted && this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    });
  }

  closeLightbox() {
    this._isMounted && this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }

  handleClickImage() {
    this.closeLightbox();
  }

  render() {

    const { menus, name } = this.props.restaurantWithMenus ? this.props.restaurantWithMenus : {menus: undefined, name: undefined} ;

    return (
      <div className={s.root}>
          <Row>
            <Col xs={7}>
              <Breadcrumb>
                <BreadcrumbItem>{this.props.managedRestaurant? this.props.managedRestaurant.name : null}</BreadcrumbItem>
                <BreadcrumbItem active>
                  <FormattedMessage id="Menus.Breadcrumbitem.Menus" defaultMessage="Menus" />&nbsp;&nbsp;
                  {menus && menus.length > 0 ? <Badge color="primary">{menus.length}</Badge> : null}
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
            <Col>
              <div className={s.galleryControls}>
                <ButtonGroup id="buttonActivateAllMenus">
                  <Button onClick={() => {
                    this.toggleModalActivateAllMenus();
                    GAEvent("ADMINPANEL", "Click on eye button to activate all menus", "MENUS_PAGE_EYE_BUTTON_TO_ACTIVATE_ALL_MENUS");
                  }} color="default"><i className="la la-eye" style={{fontSize: '18px', paddingTop: '5px'}}></i></Button>
                </ButtonGroup>
                <Tooltip placement="top" isOpen={this.state.tooltipActivateAllMenus[0]} toggle={() => this.toggleTooltip(0, 'tooltipActivateAllMenus')} 
                  target={"buttonActivateAllMenus"}>
                  <FormattedMessage id="Menus.button.activate.all.menus" defaultMessage="Activate all menus" />
                </Tooltip>
                &nbsp;&nbsp;
                <ButtonGroup id="buttonHideAllMenus">
                  <Button onClick={() => {
                    this.toggleModalHideAllMenus();
                    GAEvent("ADMINPANEL", "Click on eye button to hide all menus", "MENUS_PAGE_EYE_BUTTON_TO_HIDE_ALL_MENUS");
                  }} color="default"><i className="la la-eye-slash" style={{fontSize: '18px', paddingTop: '5px'}}></i></Button>
                </ButtonGroup>
                <Tooltip placement="top" isOpen={this.state.tooltipHideAllMenus[0]} toggle={() => this.toggleTooltip(0, 'tooltipHideAllMenus')} 
                  target={"buttonHideAllMenus"}>
                  <FormattedMessage id="Menus.button.hide.all.menus" defaultMessage="Hide all menus" />
                </Tooltip>
                &nbsp;&nbsp;
                <ButtonGroup id="buttonRefreshDataFromServer">
                  <Button onClick={() => {
                    this.refreshMenusFromServer(true);
                    GAEvent("ADMINPANEL", "Refresh menus data from server", "MENUS_PAGE_REFRESH_DATA_FROM_SERVER");
                  }} color="default"><i className="la la-refresh"></i></Button>
                </ButtonGroup>
                <Tooltip placement="top" isOpen={this.state.tooltipRefreshDataFromServer[0]} toggle={() => this.toggleTooltip(0, 'tooltipRefreshDataFromServer')} 
                  target={"buttonRefreshDataFromServer"}>
                  <FormattedMessage id="Menus.button.refresh.data.from.server" defaultMessage="Refresh list of menus" />
                </Tooltip>
              </div>
            </Col>
          </Row>
        {this.props.isFetching ? <ProgressIndicator />: null}
        {menus && menus.length === 0 ?<h1 className="page-title"><FormattedMessage id="Menus.Page.Title.Restaurant" defaultMessage="Restaurant" /> - <span className="fw-semi-bold">{name ? name : null}</span></h1> : null}
        {menus && menus.length === 0 ?<span>&nbsp;<FormattedMessage id="Menus.Page.No.Menus.Available" defaultMessage="No menus are available for this restaurant" />.</span> : null}
        <div className={s.gallery}>
          {
            menus && menus.length > 0?
            menus.map((menu, index) => {
              const key = menu.userLabel_i18n[this.props.intl.locale] + index;
              return (
                <div key={key} className={`${s.picture} card`}>
                  {/*<a href={menu.themePicture.url} onClick={e => this.openLightbox(index, e)}><img className={"figure-img" + (menu.isActive?"":" disableWithGrayscale")} src={menu.themePicture.url} alt={menu.userLabel_i18n[this.props.intl.locale]} /></a>*/}
                  <CarouselMenu menu={menu} height={"320px"}/>
                  <Button id={"button-disable-enable-menu-" + index} className={s.eye} color="primary" 
                    onClick={() => {
                      this.hideOrShowMenu(menus, index);
                  }}>
                    <img src={menu.isActive ? eye : eyeSlash} alt="" />
                  </Button>
                  <Tooltip placement="top" isOpen={this.state.tooltipsShowHideMenu[index]} toggle={() => this.toggleTooltip(index, 'tooltipsShowHideMenu')} 
                  target={"button-disable-enable-menu-" + index}>
                    <FormattedMessage id="MenuItems.Menu.Card.tooltip.show.hide.menu" defaultMessage="Show or hide menu" />
                  </Tooltip>
                  <div className={s.description}>
                    <h5 className="mt-0 mb-xs fw-semi-bold">{menu.userLabel_i18n[this.props.intl.locale]}</h5>
                    {
                      menu.deliverymethods && menu.deliverymethods.length > 0 ? 
                        menu.deliverymethods.map((dm, indexForDm) => {
                          return (
                            <span key={`menu-${menu.id}-dm-${dm.code}-${indexForDm}`}>
                              <Badge color="primary">
                                {dm.description_i18n[this.props.intl.locale]}
                              </Badge>
                              &nbsp;&nbsp;
                            </span>
                          )
                        }) : null
                    }
                    {
                      menu.isAlwaysVisible ?
                        (
                          <span key={`menu-${menu.id}-menu-always-visible`}>
                            <Badge color="secondary">
                              <FormattedMessage id="Menu.Card.Badge.Always.Visible" defaultMessage="Always visible" />
                            </Badge>
                            &nbsp;&nbsp;
                          </span>
                        )
                      : null
                    }
                    <DaysAvailabilityBar menu={menu} />
                    <div className={menu.availabilityTimeslots.length === 1 ? "w-100 d-flex justify-content-between align-items-center" : "w-100 justify-content-between align-items-center"}
                      style={{"paddingTop": "10px"}}>
                      {
                        menu.availabilityDescription_i18n ? (
                          <span className="text-muted">
                            <span 
                              className="fw-semi-bold"
                              dangerouslySetInnerHTML={{__html: menu.availabilityDescription_i18n[this.props.intl.locale]}}>
                            </span>
                          </span>
                        ) : (
                          menu.availabilityTimeslots.map((availabilityTimeslot, i) =>  (<span key={`available-from-to-${menu.id}-${i}`} className="text-muted">{ i === 0 ? <FormattedMessage id="Menus.Menu.Card.Available.From" defaultMessage="Available from" /> : (<span>&nbsp;<FormattedMessage id="Menus.Menu.Card.Available.And" defaultMessage="and" /></span>)}<span className="fw-semi-bold"> {availabilityTimeslot.startTimeInHours > 9 ? availabilityTimeslot.startTimeInHours : "0" + availabilityTimeslot.startTimeInHours}:{availabilityTimeslot.startTimeInMinutes === 0 ? "00" : (availabilityTimeslot.startTimeInMinutes < 10 ? "0" + availabilityTimeslot.startTimeInMinutes : availabilityTimeslot.startTimeInMinutes )} <FormattedMessage id="Menus.Menu.Card.Available.To" defaultMessage="to" /> {availabilityTimeslot.endTimeInHours > 9 ? availabilityTimeslot.endTimeInHours : "0" + availabilityTimeslot.endTimeInHours}:{availabilityTimeslot.endTimeInMinutes === 0 ? "00" : (availabilityTimeslot.endTimeInMinutes > 9 ? availabilityTimeslot.endTimeInMinutes : "0" + availabilityTimeslot.endTimeInMinutes)}{availabilityTimeslot.isAvailabilityGoingOverNextDay ? '(+1)' : null }</span></span>) )
                        )
                      }
                      {
                        // menu.availabilityTimeslots.length === 1 ? (<Button className="mt-lg" style={{marginTop: "10px"}} color="info" href={`#/app/menus/${menu.id}`} ><FormattedMessage id="Menus.Menu.Card.Details" defaultMessage="Details" /></Button>) : null
                      }
                    </div>
                    {
                        /*menu.availabilityTimeslots.length > 1 ? */(<div align="right"><Button className="mt-lg" style={{marginTop: "10px"}} color="info" href={`#/app/menus/${menu.id}`} ><FormattedMessage id="Menus.Menu.Card.Details" defaultMessage="Details" /></Button></div>)
                    }
                  </div>
                </div>
              );
            }) : null
          }
        </div>
        <Modal size="lg" isOpen={this.state.isModalHideMenuOpen} toggle={this.toggleModalHideMenu}>
          <ModalHeader toggle={this.toggleModalHideMenu}>
            <FormattedMessage id="Menus.Modal.hide.menu.header" defaultMessage="Hide menu confirmation" />
          </ModalHeader>
          <ModalBody className="bg-white">
            {this.state.selectedMenuToHideOrShow? <b>{this.state.selectedMenuToHideOrShow.userLabel_i18n[this.props.intl.locale]}</b>: null}<br /><br />
            <span style={{color:"red"}}>
              <FormattedMessage id="Menus.Modal.hide.menu.body" defaultMessage="This action will remove the above menu from your list of menus available to your customers. This means that your customers won't be able to see it in the list of menus and therefore its menu items won't be available for ordering." />
            </span>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleModalHideMenu}>
              <FormattedMessage id="Menus.Modal.hide.menu.footer.button.close" defaultMessage="Close" />
            </Button>
            <Button color="primary" onClick={this.hideMenu}>
              <FormattedMessage id="Menus.Modal.hide.menu.footer.button.confirm" defaultMessage="Confirm" />
            </Button>
          </ModalFooter>
        </Modal>
        <Modal size="lg" isOpen={this.state.isModalShowMenuOpen} toggle={this.toggleModalShowMenu}>
          <ModalHeader toggle={this.toggleModalShowMenu}>
            <FormattedMessage id="Menus.Modal.show.menu.header" defaultMessage="Show menu confirmation" />
          </ModalHeader>
          <ModalBody className="bg-white">
            {this.state.selectedMenuToHideOrShow? <b>{this.state.selectedMenuToHideOrShow.userLabel_i18n[this.props.intl.locale]}</b>: null}<br /><br />
            <span style={{color:"blue"}}>
              <FormattedMessage id="Menus.Modal.show.menu.body" defaultMessage="This action will publish the above menu to your list of menus. This means that your customers will be able to see it in the list of menus and therefore its menu items will be available for ordering." />
            </span>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleModalShowMenu}>
              <FormattedMessage id="Menus.Modal.show.menu.footer.button.close" defaultMessage="Close" />
            </Button>
            <Button color="primary" onClick={this.showMenu}>
              <FormattedMessage id="Menus.Modal.show.menu.footer.button.confirm" defaultMessage="Confirm" />
            </Button>
          </ModalFooter>
        </Modal>
        <Modal size="lg" isOpen={this.state.isModalHideAllMenusOpen} toggle={this.toggleModalHideAllMenus}>
          <ModalHeader toggle={this.toggleModalHideAllMenus}>
            <FormattedMessage id="Menus.Modal.hide.all.menus.header" defaultMessage="Hide all menus confirmation" />
          </ModalHeader>
          <ModalBody className="bg-white">
            <span style={{color:"red"}}>
              <FormattedMessage id="Menus.Modal.hide.all.menus.body" defaultMessage="This action will hide all your menus from the list of menus available to your customers. This means that your customers won't be able to see any of your menus in the list of menus and therefore all your menu items won't be available for ordering." />
            </span>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleModalHideAllMenus}>
              <FormattedMessage id="Menus.Modal.hide.all.menus.footer.button.close" defaultMessage="Close" />
            </Button>
            <Button color="primary" onClick={this.hideAllMenus}>
              <FormattedMessage id="Menus.Modal.hide.all.menus.footer.button.confirm" defaultMessage="Confirm" />
            </Button>
          </ModalFooter>
        </Modal>
        <Modal size="lg" isOpen={this.state.isModalActivateAllMenusOpen} toggle={this.toggleModalActivateAllMenus}>
          <ModalHeader toggle={this.toggleModalActivateAllMenus}>
            <FormattedMessage id="Menus.Modal.activate.all.menus.header" defaultMessage="Activate all menus confirmation" />
          </ModalHeader>
          <ModalBody className="bg-white">
            <span style={{color:"red"}}>
              <FormattedMessage id="Menus.Modal.activate.all.menus.body" defaultMessage="This action will activate all your menus from the list of menus available to your customers. This means that your customers will now be able to see all your menus in the list of menus and therefore all your menu items will be available for ordering." />
            </span>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleModalActivateAllMenus}>
              <FormattedMessage id="Menus.Modal.activate.all.menus.footer.button.close" defaultMessage="Close" />
            </Button>
            <Button color="primary" onClick={this.showAllMenus}>
              <FormattedMessage id="Menus.Modal.activate.all.menus.footer.button.confirm" defaultMessage="Confirm" />
            </Button>
          </ModalFooter>
        </Modal>
      </div>);
  } // enf of render
} // end of class

function mapStateToProps(store) {
  return {
    isFetching: store.menu.isFetching,
    errorMessage: store.menu.errorMessage,
    restaurantWithMenus: store.menu.restaurantWithMenus,
    userInfo: store.auth.userInfo,
    managedRestaurant: store.auth.managedRestaurant,
    updatedMenu: store.menu.updatedMenu,
  };
}

export default injectIntl(withRouter(connect(mapStateToProps)(Menus)));
