import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { dismissAlert } from '../../actions/alerts';
import s from './Sidebar.module.scss';
import LinksGroup from './LinksGroup/LinksGroup';
import { injectIntl } from 'react-intl'; 

import { openSidebar, closeSidebar, changeActiveSidebarItem } from '../../actions/navigation';
import isScreen from '../../core/screenHelper';
import { logoutUser } from '../../actions/user';

const authenticatedRoleName = "Authenticated";
const operationsAndDataManagerRoleName = "Operations and Data Manager"
const operationsManagerRoleName = "Operations Manager"
const contentManagerRoleName = "Content Manager"
const ordersManagerRoleName = "Orders Manager"
const dataManagerRoleName = "Data Manager"
const menusManagerRoleName = "Menus Manager"
const dashboardViewerRoleName = "Dashboard Viewer"

class Sidebar extends React.Component {
  static propTypes = {
    sidebarStatic: PropTypes.bool,
    sidebarOpened: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    activeItem: PropTypes.string,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    sidebarStatic: false,
    sidebarOpened: false,
    activeItem: '',
  };

  constructor(props) {
    super(props);

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.doLogout = this.doLogout.bind(this);
    this.onSideBarItemSelected = this.onSideBarItemSelected.bind(this);
  }

  onMouseEnter() {
    if (!this.props.sidebarStatic && (isScreen('lg') || isScreen('xl'))) {
      const paths = this.props.location.pathname.split('/');
      paths.pop();
      this.props.dispatch(openSidebar());
      this.props.dispatch(changeActiveSidebarItem(paths.join('/')));
    }
  }

  onMouseLeave() {
    if (!this.props.sidebarStatic && (isScreen('lg') || isScreen('xl'))) {
      this.props.dispatch(closeSidebar());
      this.props.dispatch(changeActiveSidebarItem(null));
    }
  }

  onSideBarItemSelected(link) {
    //console.log('activeItem => ', link);
    this.props.dispatch(changeActiveSidebarItem(link));
    //console.log('!this.props.sidebarStatic => ', !this.props.sidebarStatic);
    if (!this.props.sidebarStatic) {
      this.props.dispatch(closeSidebar());
    }
  }

  dismissAlert(id) {
    this.props.dispatch(dismissAlert(id));
  }

  doLogout() {
    this.props.dispatch(logoutUser());
  }

  render() {
    const { intl } = this.props;

    const userRole = this.props.userInfo && this.props.userInfo.role ? this.props.userInfo.role : null

    return (
      <nav
        onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}
        className={[s.root, this.props.sidebarStatic ? s.staticSidebar : '', !this.props.sidebarOpened ? s.sidebarClose : ''].join(' ')}
      >
        <header className={s.logo}>
          <a href="#/app/main"><img src="/favicon.png" width="30px" alt="Alacarte" /></a>
        </header>
        <ul className={s.nav}>
          {
            userRole && (
              userRole.name === authenticatedRoleName || 
              userRole.name === dashboardViewerRoleName ||
              userRole.name === operationsManagerRoleName
            ) ? 
            <LinksGroup
              onActiveSidebarItemChange={link => this.onSideBarItemSelected(link)}
              header={intl.formatMessage({ id: "Sidebar.linksgroup.dashboard.header.Label", defaultMessage: 'Dashboard'})}
              isHeader
              iconName="flaticon-stopwatch-3"
              link="/app/dashboard"
              index="analytics"
            /> :  null
          }
          {
            userRole && (
              userRole.name === authenticatedRoleName || 
              userRole.name === operationsAndDataManagerRoleName ||
              userRole.name === operationsManagerRoleName ||
              userRole.name === ordersManagerRoleName
            ) ? 
            <LinksGroup
              onActiveSidebarItemChange={link => this.onSideBarItemSelected(link)}
              header={intl.formatMessage({ id: "Sidebar.linksgroup.orders.header.Label", defaultMessage: 'Orders'})}
              isHeader
              iconName="flaticon-notebook-3"
              link="/app/orders"
              index="orders"
            /> : null
          }
          {
            userRole && (
              userRole.name === authenticatedRoleName || 
              userRole.name === operationsAndDataManagerRoleName ||
              userRole.name === operationsManagerRoleName ||
              userRole.name === contentManagerRoleName ||
              userRole.name === menusManagerRoleName
            ) ? 
            <LinksGroup
              onActiveSidebarItemChange={link => this.onSideBarItemSelected(link)}
              header={intl.formatMessage({ id: "Sidebar.linksgroup.menus.header.Label", defaultMessage: 'Menus'})}
              isHeader
              iconName="flaticon-notepad"
              link="/app/menus"
              index="menus"
            /> : null
          }
          {
            userRole && (
              userRole.name === authenticatedRoleName || 
              userRole.name === operationsAndDataManagerRoleName ||
              userRole.name === contentManagerRoleName ||
              userRole.name === dataManagerRoleName
            ) ? 
            <LinksGroup
              onActiveSidebarItemChange={link => this.onSideBarItemSelected(link)}
              header={intl.formatMessage({ id: "Sidebar.linksgroup.datamanagement.header.Label", defaultMessage: 'Data Management'})}
              isHeader
              iconName="flaticon-database-1"
              link="/app/datamanagement"
              index="datamanagement"
            /> : null
          }
          <LinksGroup
            onActiveSidebarItemChange={link => this.onSideBarItemSelected(link)}
            header={intl.formatMessage({ id: "Sidebar.linksgroup.profile.header.Label", defaultMessage: 'Profile'})}
            isHeader
            iconName="flaticon-user"
            link="/app/profile"
            index="profile"
          />
          {/*<LinksGroup
            onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
            activeItem={this.props.activeItem}
            header="Charts"
            link="/app/charts"
            isHeader
            iconName="flaticon-controls"
            index="charts"
            childrenLinks={[
              {
                header: 'Charts Overview', link: '/app/charts/overview',
              },
              {
                header: 'Flot Charts', link: '/app/charts/flot',
              },
              {
                header: 'Morris Charts', link: '/app/charts/morris',
              },
              {
                header: 'Rickshaw Charts', link: '/app/charts/rickshaw',
              },
              {
                header: 'Sparkline Charts', link: '/app/charts/sparkline',
              },
              {
                header: 'Easy Pie Charts', link: '/app/charts/easy-pie',
              },
            ]}
          />
          <LinksGroup
            header="Grid"
            link="/app/grid"
            isHeader
            iconName="flaticon-menu-4"
          />
          <LinksGroup
            onActiveSidebarItemChange={t => this.props.dispatch(changeActiveSidebarItem(t))}
            activeItem={this.props.activeItem}
            header="Tables"
            isHeader
            iconName="flaticon-map-location"
            link="/app/tables"
            index="tables"
            childrenLinks={[
              {
                header: 'Tables Basic', link: '/app/tables/static',
              },
              {
                header: 'Tables Dynamic', link: '/app/tables/dynamic',
              },
            ]}
          />
          <LinksGroup
            onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
            activeItem={this.props.activeItem}
            header="Maps"
            isHeader
            iconName="flaticon-equal-1"
            link="/app/maps"
            index="maps"
            childrenLinks={[
              {
                header: 'Google Maps', link: '/app/maps/google',
              },
              {
                header: 'Vector Map', link: '/app/maps/vector',
              },
            ]}
          />
          <LinksGroup
            onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
            activeItem={this.props.activeItem}
            header="Extra"
            isHeader
            iconName="flaticon-star"
            link="/app/extra"
            index="extra"
            childrenLinks={[
              {
                header: 'Calendar', link: '/app/extra/calendar',
              },
              {
                header: 'Invoice', link: '/app/extra/invoice',
              },
              {
                header: 'Login Page', link: '/login', onClick: this.doLogout,
              },
              {
                header: 'Error Page', link: '/error',
              },
              {
                header: 'Gallery', link: '/app/extra/gallery',
              },
              {
                header: 'Search Result', link: '/app/extra/search',
              },
              {
                header: 'Time line', link: '/app/extra/timeline',
              },
            ]}
          />
          <LinksGroup
            onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
            activeItem={this.props.activeItem}
            header="Menu Levels"
            isHeader
            iconName="flaticon-folder-10"
            link="/app/menu"
            index="menu"
            childrenLinks={[
              {
                header: 'Level 1.1', link: '/app/menu/level1',
              },
              {
                header: 'Level 1.2',
                link: '/app/menu/level_12',
                index: 'level_12',
                childrenLinks: [
                  {
                    header: 'Level 2.1',
                    link: '/app/menu/level_12/level_21',
                    index: 'level_21',
                  },
                  {
                    header: 'Level 2.2',
                    link: '/app/menu/level_12/level_22',
                    index: 'level_22',
                    childrenLinks: [
                      {
                        header: 'Level 3.1',
                        link: '/app/menu/level_12/level_22/level_31',
                        index: 'level_31',
                      },
                      {
                        header: 'Level 3.2',
                        link: '/app/menu/level_12/level_22/level_32',
                        index: 'level_32 ',
                      },
                    ],
                  },
                  {
                    header: 'Level 2.3',
                    link: '/app/menu/level_12/level_23',
                    index: 'level_23',
                  },
                ],
              },
            ]}
          />*/}
        </ul>
      </nav >
    );
  }
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    sidebarStatic: store.navigation.sidebarStatic,
    alertsList: store.alerts.alertsList,
    activeItem: store.navigation.activeItem,
    userInfo: store.auth.userInfo
  };
}

export default injectIntl(withRouter(connect(mapStateToProps)(Sidebar)));
