import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Button,
  Input,
  Label,
  Row,
  Col,
  Badge
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';


import {FormattedMessage, injectIntl} from 'react-intl';
import PrepTimeClock from '../PrepTimeClock/PrepTimeClock';
import { updateMenuItemSizeVisibilityForDineIn, updateMenuItemSizeVisibilityForDineOut } from '../../../../actions/menuitems';

import './PopupInfoMenuItemSize.module.scss';

class PopupInfoMenuItemSize extends React.Component {
    static propTypes = {
        menuItemSize: PropTypes.object.isRequired,
        currencyForMenuItemSize: PropTypes.object.isRequired,
        locale: PropTypes.string.isRequired,
        isModalHideDishOpen: PropTypes.bool.isRequired,
        isShowPrice: PropTypes.bool,
        onModalClosed: PropTypes.func.isRequired,
        dispatch: PropTypes.func.isRequired
    };

    constructor(props) {
      super(props);

      this._isMounted = false;
  
      this.state = {
        menuItemSize: this.props.menuItemSize,
        isModalHideDishOpen: this.props.isModalHideDishOpen,
        locale: this.props.locale,
        currencyForMenuItemSize: this.props.currencyForMenuItemSize
      }

      this.hideModal = this.hideModal.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;

        //console.log('this.state.isModalHideDishOpen => ', this.state.isModalHideDishOpen);
    }

    componentDidUpdate(prevProps) {
      // console.log('this.props.updatedMenuItemSize => ', this.props.updatedMenuItemSize)
      // console.log('newProps.updatedMenuItemSize => ', prevProps.updatedMenuItemSize)
      // we check if the user updated any of the dine in or take away flags of one of the menu item sizes
      if (this.props.updatedMenuItemSize && prevProps.updatedMenuItemSize &&
          this.props.updatedMenuItemSize.id === prevProps.updatedMenuItemSize.id) {
        const currentMenuItemSizeAvailableForDineIn = this.props.updatedMenuItemSize.isAvailableForDineIn;
        const currentMenuItemSizeAvailableForDineOut = this.props.updatedMenuItemSize.isAvailableForDineOut;
        const prevMenuItemSizeAvailableForDineIn = prevProps.updatedMenuItemSize.isAvailableForDineIn;
        const prevMenuItemSizeAvailableForDineOut = prevProps.updatedMenuItemSize.isAvailableForDineOut;
        if(currentMenuItemSizeAvailableForDineIn !== prevMenuItemSizeAvailableForDineIn ||
          currentMenuItemSizeAvailableForDineOut !== prevMenuItemSizeAvailableForDineOut) {
            // console.log('clicked change second time!')
            this.setState({
              menuItemSize: this.props.updatedMenuItemSize
            });
        }
      } else {
        if (this.props.updatedMenuItemSize && prevProps.updatedMenuItemSize &&
          this.props.updatedMenuItemSize.id !== prevProps.updatedMenuItemSize.id) {
            this.setState({
              menuItemSize: this.props.updatedMenuItemSize
            });
          }
          else{
            if (this.props.updatedMenuItemSize && !prevProps.updatedMenuItemSize) {
              this.setState({
                menuItemSize: this.props.updatedMenuItemSize
              });
            }
          }
      }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    hideModal() {
      this.props.onModalClosed();
    }

    render() {
        const {menuItemSize, currencyForMenuItemSize} = this.state;
        const {dispatch} = this.props;
        let inputForSwitcher = <Input type="checkbox" className="ios" disabled defaultChecked />;

        if(!menuItemSize.isPreselected)
          inputForSwitcher = <Input type="checkbox" className="ios" disabled />;

        const inputForIsPreselectedSwitcher = (
            <Label className="switch">
              {inputForSwitcher}
              <i />
            </Label>
        );

        const inputAvailableForDineInForSwitcher = <Input type="checkbox" className="ios" disabled checked={menuItemSize.isAvailableForDineIn} />;

        const inputForIsAvailableForDineInSwitcher = (
            <Label 
              onClick={() => {
                // console.log('my dine out => ', row)
                dispatch(updateMenuItemSizeVisibilityForDineIn(menuItemSize))
              }}
              className="switch">
              {inputAvailableForDineInForSwitcher}
              <i />
            </Label>
        );

        const inputAvailableForDineOutForSwitcher = <Input type="checkbox" className="ios" disabled checked={menuItemSize.isAvailableForDineOut} />;

        const inputForIsAvailableForDineOutSwitcher = (
            <Label
              onClick={() => {
                // console.log('my dine out => ', row)
                dispatch(updateMenuItemSizeVisibilityForDineOut(menuItemSize))
              }}
              className="switch">
              {inputAvailableForDineOutForSwitcher}
              <i />
            </Label>
        );

        return(
          <Modal size="lg" isOpen={this.state.isModalHideDishOpen} toggle={this.hideModal}>
            <ModalHeader toggle={this.hideModal}>
              {menuItemSize.name_i18n[this.state.locale]} <FormattedMessage id="PopupInfoMenuItemSize.Details.Modal.Header.Title.Detail.Label" defaultMessage="details" />
            </ModalHeader>
            <ModalBody className="bg-white">
              <Row>
                <Col sm={5}>
                  <FormattedMessage id="PopupInfoMenuItemSize.form.price.label"
                                                  defaultMessage="Price" />
                </Col>
                <Col sm={7}>
                  {
                    this.props.isShowPrice ?
                      <Badge color="primary">
                        {currencyForMenuItemSize.symbol} {currencyForMenuItemSize.numberOfDecimals <= 2 ? parseFloat(menuItemSize.price.toFixed(currencyForMenuItemSize.numberOfDecimals)) : menuItemSize.price.toFixed(currencyForMenuItemSize.numberOfDecimals)}
                      </Badge> :
                      <Badge color="secondary">
                        N/A
                      </Badge>
                  }
                </Col>
              </Row>
              <Row>
                <Col sm={5}>
                  <FormattedMessage id="PopupInfoMenuItemSize.form.calories.label"
                                                    defaultMessage="Calories" />
                </Col>
                <Col sm={7}>
                  {menuItemSize.calories}
                </Col>
              </Row>
              <Row>
                <Col sm={5}>
                  <FormattedMessage id="PopupInfoMenuItemSize.form.preparation.time.label"
                                                    defaultMessage="Prep. Time" />
                </Col>
                <Col sm={7}>
                  <div>
                    <PrepTimeClock idForComponent={"menuitemsizemodalpopupdetails-" + menuItemSize.id} 
                                   timeToDisplay={menuItemSize.preparationTimeInMinutes} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={5}>
                  <FormattedMessage id="PopupInfoMenuItemSize.form.pos.reference.label"
                                                  defaultMessage="POS Ref. ID" />
                </Col>
                <Col sm={7}>
                  {menuItemSize.posReferenceId ? menuItemSize.posReferenceId : 'N/A'}
                </Col>
              </Row>
              <Row>
                <Col sm={5}>
                  <FormattedMessage id="PopupInfoMenuItemSize.form.ispreselected.label"
                                                  defaultMessage="Preselected?" />
                </Col>
                <Col sm={7}>
                  {inputForIsPreselectedSwitcher}
                </Col>
              </Row>
              <Row>
                <Col sm={5}>
                  <FormattedMessage id="PopupInfoMenuItemSize.form.isavailablefordinein.label"
                                                  defaultMessage="Available for dine in?" />
                </Col>
                <Col sm={7}>
                  {inputForIsAvailableForDineInSwitcher}
                </Col>
              </Row>
              <Row>
                <Col sm={5}>
                  <FormattedMessage id="PopupInfoMenuItemSize.form.isavailablefordineout.label"
                                                  defaultMessage="Available to take away?" />
                </Col>
                <Col sm={7}>
                  {inputForIsAvailableForDineOutSwitcher}
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.hideModal}>
                <FormattedMessage id="PopupInfoMenuItemSize.Details.Modal.Button.Close" defaultMessage="Close" />
              </Button>
            </ModalFooter>
          </Modal>
        );
    }
}

PopupInfoMenuItemSize.defaultProps = {
  isShowPrice: true
};

function mapStateToProps(store) {
  return {
    isFetchingMenuItemSizeDineIn: store.menuitem.isFetchingMenuItemSizeDineIn,
    isFetchingMenuItemSizeDineOut: store.menuitem.isFetchingMenuItemSizeDineOut,
    updatedMenuItemSize: store.menuitem.updatedMenuItemSize
  };
}

export default injectIntl(withRouter(connect(mapStateToProps)(PopupInfoMenuItemSize)));
