import React from 'react';
import PropTypes from 'prop-types';

import {
    Tooltip,
  } from 'reactstrap';

import s from './Foodtypes.module.scss';

class Foodtypes extends React.Component {
    static propTypes = {
        foodCategoryLabel: PropTypes.string.isRequired,
        listOfFoodtypes: PropTypes.array.isRequired,
        locale: PropTypes.string.isRequired,
        islistview: PropTypes.bool
    };

    static defaultProps = {
      islistview: true
    };
  
    constructor(props) {
      super(props);

      this._isMounted = false;
  
      this.state = {
        tooltipsFoodtypes: []
      }
  
      this.toggleTooltip = this.toggleTooltip.bind(this);
    }

    componentDidMount() {
      this._isMounted = true;
    }
  
    componentWillUnmount() {
      this._isMounted = false;
    }
  
    toggleTooltip(id, field) {
      const newState = [...this.state[field]];
      newState.fill(false);
  
      if (!this.state[field][id]) {
        newState[id] = true;
      }
  
      this._isMounted && this.setState({
        [field]: newState,
      });
    }

    render() {
        return(
          <div className={this.props.islistview? s.foodtypesForListView : s.foodtypes}>
              {
                !this.props.islistview ? (
                  <span className={s.title}>
                    {this.props.foodCategoryLabel}
                  </span>
                ) : null
              }
              {
                this.props.listOfFoodtypes.map((foodtype, index) => {
                  return (
                    <div key={'foodtype-' + foodtype.id + '-' + index}>
                      <div 
                        style={{ backgroundImage: `url(${foodtype.icon.url})` }} 
                        id={"foodtype-" + foodtype.id}
                      >
                        {
                          !this.props.islistview ? (
                            <Tooltip placement="top" 
                            isOpen={this.state.tooltipsFoodtypes[index]} 
                            toggle={() => this.toggleTooltip(index,"tooltipsFoodtypes")} 
                              target={"foodtype-" + + foodtype.id}>
                                {foodtype.name_i18n[this.props.locale]}
                            </Tooltip>
                          ) : (
                            <span className={s.foodtypesLabelListView}>
                              {foodtype.name_i18n[this.props.locale]}
                            </span>
                          )
                        }
                      </div>
                    </div>
                  );
                })
              }
            </div>
        );
    }
}

export default Foodtypes;
