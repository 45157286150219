import React from 'react';
import PropTypes from 'prop-types';
import Widget from '../../../../components/Widget';
import './MenuitemgroupsTable.modules.scss';
import {FormattedMessage} from 'react-intl';
import {
  Row,
  Col,
  Input,
  Label
} from 'reactstrap';
//import MenuitemsTable from '../MenuitemsTable';
import MenuitemgroupsGallery from '../MenuitemgroupsGallery';

class MenuitemgroupsTable extends React.Component {

  static propTypes = {
    menuitemgroups: PropTypes.array.isRequired,
    filteredMenuitems: PropTypes.array.isRequired,
    locale: PropTypes.string.isRequired,
    restaurantId: PropTypes.string.isRequired,
    menuId: PropTypes.string.isRequired,
    menuCategoryIdForMenuGroup: PropTypes.string.isRequired,
    activeMenuCategoryId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      menuitemgroups: this.props.menuitemgroups,
      filteredMenuitems: this.props.filteredMenuitems,
      locale: this.props.locale
    };
  }

  componentDidUpdate(prevProps) {
    // if we loaded a new set of menuitem groups
    //console.log(prevProps);
    if(prevProps.menuitemgroups !== this.props.menuitemgroups || 
      prevProps.filteredMenuitems !== this.props.filteredMenuitems) {
      this.setState({
        menuitemgroups: this.props.menuitemgroups,
        filteredMenuitems: this.props.filteredMenuitems,
        locale: this.props.locale
      });
    }
  }


  render() {

    const {menuitemgroups, locale} = this.state;

    return (
      <div>
        {
          menuitemgroups.map((menuitemgroup, index) => {

            let inputForSwitcher = <Input type="checkbox" className="ios" disabled defaultChecked />;

            if(!menuitemgroup.isMandatory)
              inputForSwitcher = <Input type="checkbox" className="ios"  disabled />;

            const inputForIsMandatorySwitcher = (
                <Label className="switch" key={"switchIsMandatory-"+menuitemgroup.id}>
                  {inputForSwitcher}
                  <i /> <span className="minmaxdescription">{menuitemgroup.isMandatory? "(min: " + menuitemgroup.minNumberWhenMandatory + " - max: " + menuitemgroup.maxNumberWhenMandatory + ")": null}</span>
                </Label>
            );

            

            // get list of menu items in the current menu group
            const listOfMenuItemsInMenuitemgroup = menuitemgroup.orderedmenuitems.map((orderedMenuItem) => {
              orderedMenuItem.menuitem.orderedmenuitemId =  orderedMenuItem.id;
              return orderedMenuItem.menuitem;
            });

            //console.log(listOfMenuItemsInMenuitemgroup);
            //console.log('this.state.filteredMenuitems => ', this.state.filteredMenuitems);
            // filter the list of menuitem in the current menuitemsgroup based on the filters selected
            // in the menu items page
            const listOfMenuItemsToDisplay = listOfMenuItemsInMenuitemgroup.filter((menuitem) => {
              
              // if the current menu item that is in the menuitems group is in the filtered list of menu items
              // then we display it
              return this.props.filteredMenuitems.filter(filteredMenuItem => filteredMenuItem.id === menuitem.id).length > 0;
            });

            //console.log('listOfMenuItemsToDisplay => ', listOfMenuItemsToDisplay);

            return (
              <Widget key={"menuitemgrouptablecell-" + menuitemgroup.id + "-" + index} 
                      title={
                              <h4>
                                <span className="fw-semi-bold">
                                   {menuitemgroup.name_i18n[locale]}
                                </span>
                              </h4>
                            } 
                      collapse>
                      <Row id="menuitemgroupRowDetails" key={"menuitemgrouptablecellrow-" + menuitemgroup.id + "-" + index}>
                        <Col md={12} sm={12}>
                            <FormattedMessage id="MenuitemgroupsTable.isMandatory.label"
                                                              defaultMessage="Mandatory?"/>
                            {inputForIsMandatorySwitcher}
                        </Col>
                      </Row>
                      {/*<MenuitemsTable menuitems={listOfMenuItemsToDisplay}
                                            locale={this.state.locale}/>*/}
                      <MenuitemgroupsGallery menuitems={listOfMenuItemsToDisplay}
                                             restaurantId={this.props.restaurantId}
                                             menuId={this.props.menuId}
                                             menuCategoryIdForMenuGroup={this.props.menuCategoryIdForMenuGroup}
                                             activeMenuCategoryId={this.props.activeMenuCategoryId}
                                             menuitemsgroupId={menuitemgroup.id}
                                            locale={this.state.locale}/>
                      
                </Widget>
              );
          })
        }
      </div>
    );
  }

}

export default MenuitemgroupsTable;
