import { apiLoginLocalAuthProvider, apiLoginAuth0Provider, apiUserMe, apiUpdateUserPwd, reloadOutlet } from '../api/user.js';
import createAuth0Client from '@auth0/auth0-spa-js';
import jwt_decode from 'jwt-decode';
import { factory } from "@gooddata/api-client-bear";

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const USERME_REQUEST = 'USERME_REQUEST';
export const USERME_SUCCESS = 'USERME_SUCCESS';
export const USERME_FAILURE = 'USERME_FAILURE';
export const UPDATE_USER_PWD_REQUEST = 'UPDATE_USER_PWD_REQUEST';
export const UPDATE_USER_PWD_SUCCESS = 'UPDATE_USER_PWD_SUCCESS';
export const UPDATE_USER_PWD_FAILURE = 'UPDATE_USER_PWD_FAILURE';
export const SWITCH_OUTLET_REQUEST = 'SWITCH_OUTLET_REQUEST';
export const SWITCH_OUTLET_SUCCESS = 'SWITCH_OUTLET_SUCCESS';
export const SWITCH_OUTLET_FAILURE = 'SWITCH_OUTLET_FAILURE';


function requestLogin(creds) {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    creds,
  };
}

function requestLoginWithAccessCode(accessCode) {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    accessCode,
  };
}

export function receiveLogin(user) {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    id_token: user.id_token,
    userInfo: user.userInfo
  };
}

function loginError(message) {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message,
  };
}

function requestOutletSwitch() {
  return {
    type: SWITCH_OUTLET_REQUEST,
    isFetching: true,
  };
}

export function receiveOutletSwitch(managedRestaurant) {
  return {
    type: SWITCH_OUTLET_SUCCESS,
    isFetching: false,
    managedRestaurant
  };
}

function outletSwitchError(message) {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    message,
  };
}

function requestUserMe() {
  return {
    type: USERME_REQUEST,
    isFetching: true,
  };
}

export function receiveUserMe(managedRestaurant) {
  return {
    type: USERME_SUCCESS,
    isFetching: false,
    managedRestaurant
  };
}

function userMeError(message) {
  return {
    type: USERME_FAILURE,
    isFetching: false,
    message,
  };
}

function requestUpdateUserPwd() {
  return {
    type: UPDATE_USER_PWD_REQUEST,
    isFetching: true,
  };
}

export function receiveUpdateUserPwd() {
  return {
    type: UPDATE_USER_PWD_SUCCESS,
    isFetching: false
  };
}

export function updateUserPwdError(message) {
  return {
    type: UPDATE_USER_PWD_FAILURE,
    isFetching: false,
    message,
  };
}

function requestLogout() {
  return {
    type: LOGOUT_REQUEST,
    isFetching: true,
    isAuthenticated: true,
  };
}

export function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false,
  };
}

export function checkTokenExpiration() {
  return (dispatch) => {
    // get the token from local storage
    const token = localStorage.getItem('id_token');
    // if token is available
    if(token) {
      try {
        // decode token and get expiration time in seconds since epoch
        const { exp } = jwt_decode(token);
        if (Date.now() / 1000 > exp) {
          // if token expired then logout user
          dispatch(logoutUser());
        } else {
          // token is still valid
          const userInfo = localStorage.getItem('userInfo');
          dispatch(receiveLogin({ id_token: token, userInfo: userInfo }));
        }

      } catch (err) {
        // if any error with token then
        dispatch(logoutUser());
      }
    } else {
      // token not available so by default it is expired
      dispatch(logoutUser());
    }
  }
}

export function getUserMeInfo() {
  return (dispatch) => {
    dispatch(requestUserMe());
    const token = localStorage.getItem('id_token');
    apiUserMe(token)
        .then(data => {
          // console.log('in getUserMeInfo function => ', data);
          // get currently stored user info coming from auth/local call when logging in
          const currentUserInfoInLocalStorage = JSON.parse(localStorage.getItem('userInfo'));
          const mergedUserInfo = Object.assign(currentUserInfoInLocalStorage, data);
          // console.log('mergedUserInfo => ', {...mergedUserInfo.restaurants});
          // localStorage.removeItem('lastLoggedInUsername');
          let managedRestaurant = Object.assign({}, mergedUserInfo.restaurants[0]);
          // check if the current user has multiple restaurants to manage and retrieve the one he wants to manage
          const restaurantSelectedByUser = JSON.parse(localStorage.getItem('selectedRestaurant'));
          if (restaurantSelectedByUser) {
            const tempListOfRestaurantsForLoggedInUSer = mergedUserInfo.restaurants.splice(0);
            const filteredListOfRestaurants = tempListOfRestaurantsForLoggedInUSer.filter(restaurantObject => restaurantObject.id === restaurantSelectedByUser.id);
            managedRestaurant = filteredListOfRestaurants[0];
            // console.log('getUserMeInfo managedRestaurant => ', managedRestaurant);
            mergedUserInfo.restaurants = []
            mergedUserInfo.restaurants[0] = Object.assign({}, managedRestaurant);
          }
          if (mergedUserInfo) {
            localStorage.setItem('userInfo', JSON.stringify(mergedUserInfo));
          }
          
          // console.log('getUserMeInfo');
          dispatch(receiveUserMe(managedRestaurant));
        })
        .catch(error => {
          if (error.response && error.response.data) {
            return dispatch(userMeError(error.response.data.message));
          }
          //console.log('An error occurred inLoginUser: ', error.response.data.message);
          return dispatch(userMeError(error.toString()));
        })
  };
}

export function changeUserPassword(userEmailAddress) {
  return (dispatch) => {
    dispatch(requestUpdateUserPwd());
    // return the axios promise so we can handle success response in the page or component calling the function via a dispatch
    return apiUpdateUserPwd(userEmailAddress);
  };
}

// Logs the user out
export function logoutUser() {
  return async (dispatch) => {
    dispatch(requestLogout());
    const token = localStorage.getItem('id_token');
    const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
    const restaurantSelectedByUser = JSON.parse(localStorage.getItem('selectedRestaurant'));
    // console.log('logout restaurantSelectedByUser => ', restaurantSelectedByUser)
    // store last logged username in order to compare it with the new one
    // that will login and decide either to load the previously loaded url
    // or to go to the app home page
    if (token) {
      localStorage.removeItem('id_token');
      document.cookie = 'id_token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
    if (userInfo) {
      // localStorage.setItem('lastLoggedInUsername', userInfo.username);
      localStorage.removeItem('userInfo');
    }
    if (restaurantSelectedByUser) {
      localStorage.removeItem('selectedRestaurant');
    }
    const domain = process.env.REACT_APP_GOODDATA_DOMAIN;
    const sdk = factory({ domain });
    if (sdk) {
      await sdk.user.logout()
    }
    dispatch(receiveLogout());
    const auth0AlacarteAdminpanelClient = await createAuth0Client({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID
    });
    // console.log('logout auth0 => ', auth0)
    if (auth0AlacarteAdminpanelClient) {
      auth0AlacarteAdminpanelClient.logout();
    }
    const auth0AlacarteAnalyticsClient = await createAuth0Client({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      client_id: process.env.REACT_APP_AUTH0_DASHBOARD_CLIENT_ID
    });
    // console.log('logout auth0 => ', auth0)
    if (auth0AlacarteAnalyticsClient) {
      auth0AlacarteAnalyticsClient.logout();
    }
  };
}

export function loginUser(creds) {
  return (dispatch) => {
    // We dispatch requestLogin to kickoff the call to the API
    dispatch(requestLogin(creds));

    if (creds.login.length > 0 && creds.password.length > 0) {
      apiLoginLocalAuthProvider(creds.login, creds.password)
        .then(data => {
          //console.log('in loginUser function => ' + data.jwt);
          if (data.jwt) {
            localStorage.setItem('id_token', data.jwt);
          }
          if (data.user) {
            localStorage.setItem('userInfo', JSON.stringify(data.user));
          }
          // console.log('loginUser');
          // Dispatch the success action with fake user
          dispatch(receiveLogin({ id_token: data.jwt, userInfo: data.user }));
        })
        .catch(error => {
          // console.log('An error occurred inLoginUser: ', error.response.data.message);
          // console.log('error.toString() => ', error.toString());
          if (error.response && error.response.data) {
            return dispatch(loginError(error.response.data.message[0].messages[0].message));
          }
          //console.log('action loginUser error => ', error);
          return dispatch(loginError(error.toString()));
        })
    }
  };
}
export function loginUserWithAuth0(accessToken) {
  return (dispatch) => {
    // We dispatch requestLogin to kickoff the call to the API
    dispatch(requestLoginWithAccessCode(accessToken));
    //console.log('accessToken => ', accessToken)
    if (accessToken) {
      apiLoginAuth0Provider(accessToken)
        .then(data => {
          // console.log('in loginUser function => ' + data);
          if (data.jwt) {
            localStorage.setItem('id_token', data.jwt);
          }
          if (data.user) {
            localStorage.setItem('userInfo', JSON.stringify(data.user));
          }
          // console.log('loginUser');
          // Dispatch the success action with fake user
          dispatch(receiveLogin({ id_token: data.jwt, userInfo: data.user }));
        })
        .catch(error => {
          // console.log('An error occurred inLoginUser: ', error.response.data.message);
          console.log('error.toString() => ', error);
          return dispatch(loginError(error.toString()));
        })
    }
  };
}

export function switchOutlet() {
  return (dispatch) => {
    // We dispatch requestLogin to kickoff the call to the API
    dispatch(requestOutletSwitch());
    //console.log('accessToken => ', accessToken)
    const restaurantSelectedByUser = JSON.parse(localStorage.getItem('selectedRestaurant'));
    if (restaurantSelectedByUser) {
      reloadOutlet(restaurantSelectedByUser)
      .then(managedRestaurant => {
        const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
        if (userInfo) {
          userInfo.restaurants[0] = Object.assign({}, restaurantSelectedByUser, managedRestaurant.user.restaurants[0])
          localStorage.setItem('userInfo', JSON.stringify(userInfo));
          const token = localStorage.getItem('id_token') ? localStorage.getItem('id_token') : null;
          if (token) {
            dispatch(receiveLogin({ id_token: token, userInfo: userInfo }));
            window.location.reload()
          } else {
            dispatch(outletSwitchError('User not authenticated'));
          }
        }
        // dispatch(receiveOutletSwitch(managedRestaurant));
      })
      .catch(error => {
        // console.log('An error occurred inLoginUser: ', error.response.data.message);
        // console.log('error.toString() => ', error);
        return dispatch(outletSwitchError(error.toString()));
      })
    } else {
      return dispatch(outletSwitchError('Restaurant not available'));
    }
  };
}
