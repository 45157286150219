import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUserMeInfo } from '../../actions/user';
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Breadcrumb,
  BreadcrumbItem,
  Badge,
  Row,
  Col,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from 'reactstrap';

import queryString from 'query-string'

import { showSuccessMessage } from '../../components/ToastMessenger';

import { getMenuItemsFromServer, updateMenuItemVisibility } from '../../actions/menuitems';

import FilterElement from './components/FilterElement/FilterElement';
//import RealPictureRibbon from './components/RealPictureRibbon/RealPictureRibbon';
import RealPictureText from '../../components/RealPictureText/RealPictureText';
import CarouselMenuItem from '../../components/CarouselMenuItem/CarouselMenuItem';
//import ProductCard from './components/ProductCard/ProductCard';
import MobileModal from './components/MobileModal/MobileModal';
import { GAEvent } from "../../components/Tracking/Tracking";

import { FormattedMessage, injectIntl } from 'react-intl'; 
import ProgressIndicator from '../../components/ProgressIndicator';
import MenuitemgroupsTable from './components/MenuitemgroupsTable';

import s from './Menuitems.module.scss';

import chefHat from '../../images/chef/chef-hat.svg';
import eye from '../../images/eyes/eye.svg';
import eyeSlash from '../../images/eyes/eye-slash.svg';


// Not best practice but this flag has been added to avoid a double call to the server
// looking at the isFetching property connected to the reducer which is not fast enougth
// changing value when a call to the backend is sent in the didupdate function and end
// up with another useless call to the server before the isFetching property is updated
// by default this flag is at false indicating that no call has been made to the server
// as soon as a call is made then it is put as true and only back to false when the
// component is unmounted
let isAFetchFromApiInProgress = false;

const MyIsActiveComponent = props => <input id="check-input-active" disabled checked={props.checked} type="checkbox" className="ios form-check-input"></input>;
    

class Menuitems extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    const {intl} = this.props;

    this._isMounted = false;
    this.activeMenuCategory = 'all';
    this.activeMenuCategoryId = null;

    const allString = intl.formatMessage({ id: "all.message.for.filters", defaultMessage: 'All'});
    const foodCategoriesFilterLabel = intl.formatMessage({ id: "MenuItems.filter.food.category", defaultMessage: 'Food Categories'});
    const foodTypesFilterLabel = intl.formatMessage({ id: "MenuItems.filter.food.type", defaultMessage: 'Food Types'});
    const activeFilterLabel = intl.formatMessage({ id: "MenuItems.filter.active", defaultMessage: 'Visible'});

    this.state = {
      activeMenuCategoryIdInState: null,
      isModalHideDishOpen: false,
      isModalShowDishOpen: false,
      tooltipsShowHideDish: [],
      tooltipsRecommendedByTheChef: [],
      tooltipRefreshDataFromServer: [false],
      tooltipMenuCategory: [],
      foodCategoriesFilterLabel,
      foodTypesFilterLabel,
      activeFilterLabel,
      filtersData: [],
      allString,
      foodTypeFilter: allString,
      foodCategoryFilter: allString,
      activeFilter: allString,
      isModalActive: false,
      modalId: null,
      order: null,
      selectedDishToHideOrShow: null,
    };

    this.refreshMenuItemsFromServer = this.refreshMenuItemsFromServer.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.filterMenuItemsByMenuCategory = this.filterMenuItemsByMenuCategory.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.filterMenuItemsBasedOnSelectedFilters = this.filterMenuItemsBasedOnSelectedFilters.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.retrieveListOfMenuCategories = this.retrieveListOfMenuCategories.bind(this);
    this.toggleModalHideDish = this.toggleModalHideDish.bind(this);
    this.toggleModalShowDish = this.toggleModalShowDish.bind(this);
    this.hideOrShowDishInMenu = this.hideOrShowDishInMenu.bind(this);
    this.showDishInMenu = this.showDishInMenu.bind(this);
    this.hideDishInMenu = this.hideDishInMenu.bind(this);
    this.retrieveMenuItemsFilters = this.retrieveMenuItemsFilters.bind(this);
    this.componentDidUpdate = this.componentDidUpdate.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    // by default if in menu category mode we select the 'all' tab
    this.activeMenuCategory = 'all';
    // get the query parameters
    const params = queryString.parse(this.props.location.search);
    // if deeplink with query parameters then we select the right
    // menu category or menu group directly instead of pointing to
    // the first in the list
    this.activeMenuCategoryId = params.menucategoryid || params.menugroupid;
    if(this.activeMenuCategoryId === undefined)
      this.activeMenuCategoryId = null;
    //console.log('this.activeMenuCategoryId => ', this.activeMenuCategoryId);
    // sort all menu items by ascendant order
    //console.log(this.props.intl.locale);
    //console.log('this.props.restaurantWithMenus in componentDidMount => ', this.props.restaurantWithMenus);
    //console.log('this.props.isFetching in componentDidMount => ', this.props.isFetching);
    if (this.props.restaurantMenuItems === undefined && !this.props.isFetching && !isAFetchFromApiInProgress) {
      //console.log('calling server to get menu items in componentDidMount');
      this.refreshMenuItemsFromServer(false, this.props.match.params.menuid);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const params = queryString.parse(nextProps.location.search);
    const nextActiveMenuCategoryId = params.menucategoryid || params.menugroupid;
    // if we have a paremeter in query string
    if(nextActiveMenuCategoryId !== undefined) {
      // if we had an active menu category in the state
      if(nextActiveMenuCategoryId !== prevState.activeMenuCategoryIdInState) {
        //console.log('prevState.activeMenuCategoryIdInState => ', prevState.activeMenuCategoryIdInState);
        //console.log('nextActiveMenuCategoryId => ', nextActiveMenuCategoryId);
        // then we re render to display the menu items of the new menu category or menu group
        //console.log('we have moved to another item');
        return {
          activeMenuCategoryIdInState: nextActiveMenuCategoryId
        };
      }
    } else {
      return {
        activeMenuCategoryIdInState: null
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    //console.log('menu items newProps => ', newProps);
    const params = queryString.parse(prevProps.location.search);
    let previousMenuCategoryId = params.menucategoryid || params.menugroupid;
    const currentLoadedURLTimestamp = params.ts;
    const currentTimeStamp = new Date().getTime();
    const isUserGoingBackInBrowserHistory = currentLoadedURLTimestamp ? (currentTimeStamp - currentLoadedURLTimestamp) > 0 : false;
    //console.log('isUserGoingBackInBrowserHistory => ', isUserGoingBackInBrowserHistory);
    // as the one in the state would have become the new category menu id or menu group id
    // as it would have been updated in getDerivedStateFromProps
    //console.log('this.state.activeMenuCategoryIdInState => ', this.state.activeMenuCategoryIdInState);
    //console.log('this.activeMenuCategoryId => ', this.activeMenuCategoryId);
    if(this.activeMenuCategoryId === undefined)
      this.activeMenuCategoryId = null;
    if(previousMenuCategoryId === undefined)
      previousMenuCategoryId = null;
    //console.log('this.activeMenuCategoryId #22 => ', this.activeMenuCategoryId);
    //console.log('this.state.activeMenuCategoryIdInState #22 => ', this.state.activeMenuCategoryIdInState);
    //console.log('previousMenuCategoryId => ', previousMenuCategoryId);
    // if we just loaded the page and we are going back in history the 
    // state stays undefined and then we refresh data from the server
    if(isUserGoingBackInBrowserHistory &&
      this.state.activeMenuCategoryIdInState &&
      this.activeMenuCategoryId !== this.state.activeMenuCategoryIdInState &&
      !this.props.isFetching && !prevProps.isFetching) {
      //console.log('reloading history');
      this.activeMenuCategoryId = this.state.activeMenuCategoryIdInState;
      this.refreshMenuItemsFromServer(true, this.props.match.params.menuid);
    } else {
      if(!this.state.activeMenuCategoryIdInState && this.activeMenuCategoryId && isUserGoingBackInBrowserHistory &&
        !this.props.isFetching && !prevProps.isFetching) {
        //console.log('this.activeMenuCategoryId ###ZZZ => ', this.activeMenuCategoryId);
        this.activeMenuCategoryId = null;
        this.refreshMenuItemsFromServer(true, this.props.match.params.menuid);
        //this.forceUpdate();
      }
    }

    //console.log('menu items this.props => ', this.props);
    if( this.props.restaurantWithMenus === undefined &&
      prevProps.restaurantMenuItems.restaurant === undefined &&
       !this.props.isFetching && !prevProps.isFetching && !isAFetchFromApiInProgress) {
        //console.log('calling server 1');
        this.refreshMenuItemsFromServer(true, this.props.match.params.menuid);
    }

    //console.log('this.props.match.params.id => ', this.props.match.params.id);
    //console.log('newProps.match.params.id => ', newProps.match.params.id);
    // console.log('this.props.isFetching => ', this.props.isFetching);
    // if we are loading another menu then we call the server to get this new menu
    // dishes
    //console.log('menus newProps', newProps);
    //console.log('menus this.props', this.props);
    //console.log('isAFetchFromApiInProgress => ', isAFetchFromApiInProgress);
    if (this.props.restaurantMenuItems.restaurant !== undefined &&
      this.props.restaurantMenuItems.restaurant !== null &&
      this.props.restaurantMenuItems.restaurant.menus[0].id !== prevProps.match.params.menuid &&
      !this.props.isFetching && !prevProps.isFetching && !isAFetchFromApiInProgress) {
      //console.log('####calling server to get menu items from component did update');
      //console.log('calling server 2');
      this.refreshMenuItemsFromServer(true, prevProps.match.params.menuid);
    }

    if(this.props.managedRestaurant &&
      prevProps.managedRestaurant === undefined && 
      !this.props.isFetching && ! prevProps.isFetching) {
        //console.log('calling server 3');
        this.refreshMenuItemsFromServer(true, prevProps.match.params.menuid);
      }

    //console.log('newProps.updatedMenuItem => ', newProps.updatedMenuItem);
    //console.log('this.props.updatedMenuItem => ', this.props.updatedMenuItem);
    // if we did update a menu item (which means the previously updated one has a different id than the new one or
    // if we are updating the same one as the previous one then its isActive state should be different)
    if (this.props.updatedMenuItem.id && 
      (prevProps.updatedMenuItem.id !== this.props.updatedMenuItem.id || prevProps.updatedMenuItem.isActive !== this.props.updatedMenuItem.isActive) ) {
      showSuccessMessage(this.props.updatedMenuItem.name + " " + this.props.intl.formatMessage({ id: "MenuItems.message.menuitem.isactive.update.success", defaultMessage: 'successfully updated'}));
      //console.log('calling server 3');
      this.refreshMenuItemsFromServer(true, prevProps.match.params.menuid);
    }
  }

  componentWillUnmount() {
    // put the flag back to false so when the component will be loaded again we will
    // be able to call the server
    isAFetchFromApiInProgress = false;
    this._isMounted = false;
    this.activeMenuCategory = 'all';
    this.activeMenuCategoryId = null;
  }

  // list of menu categories or menu groups
  retrieveListOfMenuCategories() {
    let menuCategories = [];
    const {locale} = this.props.intl;

    //console.log('this.activeMenuCategory (retrieveListOfMenuCategories) => ', this.activeMenuCategory);

    // if we are in menugroup mode
    if(this.props.restaurantMenuItems &&
      this.props.restaurantMenuItems.restaurant &&
      this.props.restaurantMenuItems.restaurant.menus[0].menucategories.length === 1 &&
      this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].orderedmenuitems.length === 0) {
      menuCategories =  this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].menugroups;
      // select the first menu group 
      //console.log('we are in menugroup mode @@@@@@@@@@@@@@@@');
      if(this.activeMenuCategoryId === null && menuCategories.length > 0) {
        const params = queryString.parse(this.props.location.search);
        const currentMenuGroupIdInURL = params.menugroupid;
        this.activeMenuCategoryId = currentMenuGroupIdInURL || menuCategories[0].id;
        if(this.activeMenuCategoryId) {
          const filteredArrayOfMenugroups = menuCategories.filter((mg) => mg.id === this.activeMenuCategoryId);
          if(filteredArrayOfMenugroups.length > 0)
            this.activeMenuCategory = filteredArrayOfMenugroups[0].name_i18n[locale];
        }
        else
          this.activeMenuCategory = menuCategories[0].name_i18n[locale];
        //console.log('this.activeMenuCategory ## 1 => ', this.activeMenuCategory);
      } else {
        //console.log('this.activeMenuCategoryId (retrieveListOfMenuCategories) => ', this.activeMenuCategoryId);
        //console.log('menuCategories ## 1.5 => ', menuCategories); 
        const filteredArrayOfMenugroups = menuCategories.filter((mg) => mg.id === this.activeMenuCategoryId);
        if(filteredArrayOfMenugroups.length > 0) {
          this.activeMenuCategoryId = filteredArrayOfMenugroups[0].id;
          this.activeMenuCategory = filteredArrayOfMenugroups[0].name_i18n[locale];
        }
        //console.log('this.activeMenuCategory ## 1.5 => ', this.activeMenuCategory);
      }
    } else {
      // menu categories mode
      //console.log('we are in menu category mode $$$$$$$$$$$$$');
      // when we switch from menugroup to menu categories the only way to know that
      // we need to reset the menuActiveCategory to all is to check if we have
      // an active menu category that is a menu group when being in menu category mode
      // this means that we can reset the active menu category to all
      if(this.props.restaurantMenuItems && 
        this.props.restaurantMenuItems.restaurant && 
        this.props.restaurantMenuItems.restaurant.menus[0].menucategories.length >= 1 &&
        this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].orderedmenuitems.length > 0) {
        const arrayFilteredMenuCategories = this.props.restaurantMenuItems.restaurant.menus[0].menucategories.filter((mc) => mc.id === this.activeMenuCategoryId);
        
        //console.log('arrayFilteredMenuCategories =>', arrayFilteredMenuCategories);
        if(arrayFilteredMenuCategories.length === 0) {
          const params = queryString.parse(this.props.location.search);
          const currentMenuCategoryIdInURL = params.menucategoryid;
          this.activeMenuCategoryId = currentMenuCategoryIdInURL;
          if(this.activeMenuCategoryId) {
            const arrayFilteredMenuCategories = this.props.restaurantMenuItems.restaurant.menus[0].menucategories.filter((mc) => mc.id === this.activeMenuCategoryId);
            if(arrayFilteredMenuCategories.length > 0)
              this.activeMenuCategory = arrayFilteredMenuCategories[0].name_i18n[locale];
          }
          else {
            // if we only have one menu category then it is preselected
            if(arrayFilteredMenuCategories.length > 0) {
              this.activeMenuCategoryId = arrayFilteredMenuCategories[0].id;
              this.activeMenuCategory = arrayFilteredMenuCategories[0].name_i18n[locale];
            }
            else
              this.activeMenuCategory = 'all';
          }
          //console.log('this.activeMenuCategory ## 2 => ', this.activeMenuCategory);
        } else {
          this.activeMenuCategory = arrayFilteredMenuCategories[0].name_i18n[locale];
        }

        menuCategories =  this.props.restaurantMenuItems.restaurant.menus[0].menucategories;
      }
    }
    //console.log('menuCategoriesFilter =>', menuCategoriesFilter);
    const menuCategoriesFilter = menuCategories.map(mc => {return {id: mc.id, name:mc.name_i18n[locale]}});

    return menuCategoriesFilter;
  }

  retrieveMenuItemsFilters() {
    //console.log('parseDataFromServer => ', this.props.restaurantMenuItems);
    const {intl} = this.props;
    const {locale} = intl;

    let allMenuItems = [];
    // if we have only one category this means we are now in a bundle menus offer
    // in menu group mode with ordered menu items in it
    if (this.props.restaurantMenuItems && 
      this.props.restaurantMenuItems.restaurant && 
      this.props.restaurantMenuItems.restaurant.menus[0].menucategories.length === 1 &&
      this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].orderedmenuitems.length === 0) {
      const selectedMenugroupMenuItems = this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].menugroups.filter((mg) => mg.id === this.activeMenuCategoryId);
      //console.log('selectedMenugroupMenuItems => ', selectedMenugroupMenuItems);
      if(selectedMenugroupMenuItems.length > 0) {
        allMenuItems = selectedMenugroupMenuItems[0].orderedmenuitems;
        allMenuItems = allMenuItems.map((orderedMenuItem) => {
          orderedMenuItem.menuitem.orderedmenuitemId =  orderedMenuItem.id;
          // we flag this menu item as a menuitem that does not belongs to a menuitem group
          orderedMenuItem.menuitem.isMenuItemForGroup = false;
          return orderedMenuItem.menuitem;
        });

        // array that will contain all the menu items of a menu group
        let tempMenuitemsGroupMenuItems = [];
        // we are menuitems group mode
        if(selectedMenugroupMenuItems[0].menuitemgroups.length > 0) {
          tempMenuitemsGroupMenuItems = [...new Set([].concat(...selectedMenugroupMenuItems[0].menuitemgroups.map((mig) => mig.orderedmenuitems)))];
          //console.log('tempMenuitemsGroupMenuItems => ', tempMenuitemsGroupMenuItems);
          // if all menuitems group in the menu group have ordered menu items
          if(tempMenuitemsGroupMenuItems.length > 0) {
            // we map the array to only return menu items
            tempMenuitemsGroupMenuItems = tempMenuitemsGroupMenuItems.map((orderedMenuItem) => {
              orderedMenuItem.menuitem.orderedmenuitemId =  orderedMenuItem.id;
              // we flag this menu item as a menuitem that belongs to a menuitem group
              orderedMenuItem.menuitem.isMenuItemForGroup = true;
              return orderedMenuItem.menuitem;
            });
            allMenuItems = [...new Set(allMenuItems.concat(tempMenuitemsGroupMenuItems))];
            //console.log('allMenuItems => ', allMenuItems);
          }
        }
      }
      //console.log('allMenuItems => ', allMenuItems);
    } else {
        // Normal menu mode
        if (this.props.restaurantMenuItems && 
        this.props.restaurantMenuItems.restaurant && 
        this.props.restaurantMenuItems.restaurant.menus[0].menucategories.length >= 1 &&
        this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].orderedmenuitems.length > 0) {
          allMenuItems = this.props.restaurantMenuItems && this.props.restaurantMenuItems.restaurant ? 
            [...new Set([].concat(...this.props.restaurantMenuItems.restaurant.menus[0].menucategories.map((mc) => mc.orderedmenuitems)))] : [];
            allMenuItems = allMenuItems.map((orderedMenuItem) => {
              orderedMenuItem.menuitem.orderedmenuitemId =  orderedMenuItem.id;
              return orderedMenuItem.menuitem;
            });
      }
    } // end else of menugroup mode
    
    let filtersData = [];

    if(allMenuItems.length > 0) {
      const allString = intl.formatMessage({ id: "all.message.for.filters", defaultMessage: 'All'});
      const foodCategoriesFilterLabel = intl.formatMessage({ id: "MenuItems.filter.food.category", defaultMessage: 'Food Categories'});
      const foodTypesFilterLabel = intl.formatMessage({ id: "MenuItems.filter.food.type", defaultMessage: 'Food Types'});
      const activeFilterLabel = intl.formatMessage({ id: "MenuItems.filter.active", defaultMessage: 'Visible'});
      const filterTitle = intl.formatMessage({ id: "MenuItems.filtersData.title", defaultMessage: 'Filter'});

      // listing all food categories and removing duplicates
      const foodCategories = [...new Set(allMenuItems.map((menuItem) => menuItem.foodcategory ? menuItem.foodcategory.namePlural_i18n[locale] : "Other"))];
      //console.log('foodCategories => ', foodCategories);

      // getting all food type children arrays
      const foodTypes =  [...new Set([].concat(...allMenuItems.map((menuitem) => menuitem.foodtypes)))];
      //console.log('foodTypes => ', foodTypes);

      // removing duplicate foodtype objects
      const seen = {};
      const filteredFoodTypes = foodTypes.filter((foodTypeObj) => {
        return seen.hasOwnProperty(foodTypeObj.name_i18n) ? false : (seen[foodTypeObj.name_i18n[locale]] = true);
      });
      // from object to string
      const finalFilteredFoodType = [...new Set(filteredFoodTypes.map((filteredFoodTypeObj) => filteredFoodTypeObj.name_i18n[locale]))];
      //console.log('finalFilteredFoodType => ', finalFilteredFoodType);
  
      filtersData = [{
        title: filterTitle,
        data: [{
          id: 0,
          label: foodCategoriesFilterLabel,
          options: [allString].concat(foodCategories),
        },
        {
          id: 1,
          label: foodTypesFilterLabel,
          options: [allString].concat(finalFilteredFoodType),
        },
        {
          id: 2,
          label: activeFilterLabel,
          options: [allString, 
            intl.formatMessage({ id: "MenuItems.filtersData.activeFilterLabel.Yes", defaultMessage: 'Yes'}), 
            intl.formatMessage({ id: "MenuItems.filtersData.activeFilterLabel.No", defaultMessage: 'No'})],
        }],
      },
      /*{
        id: 6,
        title: 'Sort',
        data: ['Favourite', 'Price', 'Popular'],
      }*/
      ];
    }

    return filtersData;
  }

  refreshMenuItemsFromServer(forceCallToServer = false, pMenuId) {
    const { dispatch } = this.props;
    let { userInfo } = this.props;
    // we put the flag that a call to the server is being done
    isAFetchFromApiInProgress = true;
    if(!userInfo) userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if((userInfo && !this.props.isFetching 
      && (typeof this.props.restaurantMenuItems === 'undefined')) || forceCallToServer) {
      // get the restaurant for which we want to load the menus
      const restaurantSlug = this.props.managedRestaurant ? this.props.managedRestaurant.slug : userInfo.restaurants ? userInfo.restaurants[0].slug : null;
      //console.log('restaurantId => ', restaurantId);
      if (restaurantSlug) {
        //console.log('calling server to get menus');
        // get the menu id from the url id parameter
        const menuId = pMenuId;
        //console.log(menuId);
        // resetting the list of filtered menu items as we are loading new data
        this._isMounted && this.setState({allFilteredMenuItems : null});
        dispatch(getMenuItemsFromServer(restaurantSlug, menuId));
        //this.setState({isAFetchFromApiInProgress: false});
      } else {
        // if we do not know which restaurant the current user in managing then
        // we request the info from the server
        dispatch(getUserMeInfo());
      }
    }
  }

  updateFilter(filterName, currentOption) {
    //console.log('filterName => ', filterName);
    //console.log('updateFoodTypeFilter => ', currentOption);
    const selectedOption = currentOption ? currentOption : this.state.allString;
    const { foodTypesFilterLabel, foodCategoriesFilterLabel, activeFilterLabel } = this.state;
    // set the state
    switch(filterName) {
      case foodTypesFilterLabel: this._isMounted && this.setState({foodTypeFilter: selectedOption});break;
      case foodCategoriesFilterLabel: this._isMounted && this.setState({foodCategoryFilter: selectedOption});break;
      case activeFilterLabel: this._isMounted && this.setState({activeFilter: selectedOption});break;
      default: break;
    }
  }

  filterMenuItemsBasedOnSelectedFilters() {
    const allStr = this.state.allString;

    let allMenuItems = [];

    const {locale} = this.props.intl;

    //console.log("this.activeMenuCategory (filterMenuItemsBasedOnSelectedFilters) => ", this.activeMenuCategory);
    
    if(this.state.allFilteredMenuItems) {
      allMenuItems = this.state.allFilteredMenuItems;
    } else {
      if(this.props.restaurantMenuItems && this.props.restaurantMenuItems.restaurant) {
        if(this.props.restaurantMenuItems.restaurant.menus[0].menucategories.length === 1 &&
          this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].orderedmenuitems.length === 0) {
          const selectedMenugroupMenuItems = this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].menugroups.filter((mg) => mg.id === this.activeMenuCategoryId);
          //console.log('selectedMenugroupMenuItems => ', selectedMenugroupMenuItems);
          if(selectedMenugroupMenuItems.length > 0) {
            allMenuItems = selectedMenugroupMenuItems[0].orderedmenuitems;
            allMenuItems = allMenuItems.map((orderedMenuItem) => {
              orderedMenuItem.menuitem.orderedmenuitemId =  orderedMenuItem.id;
              orderedMenuItem.menuitem.menucategory = orderedMenuItem.menucategory;
              // we flag this menu item as a menuitem that does not belongs to a menuitem group
              orderedMenuItem.menuitem.isMenuItemForGroup = false;
              return orderedMenuItem.menuitem;
            });

            // array that will contain all the menu items of a menu group
            let tempMenuitemsGroupMenuItems = [];
            // we are menuitems group mode
            if(selectedMenugroupMenuItems[0].menuitemgroups.length > 0) {
              tempMenuitemsGroupMenuItems = [...new Set([].concat(...selectedMenugroupMenuItems[0].menuitemgroups.map((mig) => mig.orderedmenuitems)))];
              //console.log('tempMenuitemsGroupMenuItems => ', tempMenuitemsGroupMenuItems);
              // if all menuitems group in the menu group have ordered menu items
              if(tempMenuitemsGroupMenuItems.length > 0) {
                // we map the array to only return menu items
                tempMenuitemsGroupMenuItems = tempMenuitemsGroupMenuItems.map((orderedMenuItem) => {
                  orderedMenuItem.menuitem.orderedmenuitemId =  orderedMenuItem.id;
                  // we flag this menu item as a menuitem that belongs to a menuitem group
                  orderedMenuItem.menuitem.isMenuItemForGroup = true;
                  return orderedMenuItem.menuitem;
                });
                allMenuItems = [...new Set(allMenuItems.concat(tempMenuitemsGroupMenuItems))];
                //console.log('allMenuItems => ', allMenuItems);
              }
            }
          }
          //console.log('allMenuItems => ', allMenuItems);
        } else {
          // Normal Menu mode
          if(this.props.restaurantMenuItems.restaurant.menus[0].menucategories.length >= 1 &&
            this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].orderedmenuitems.length > 0) {
            allMenuItems = [...new Set([].concat(...this.props.restaurantMenuItems.restaurant.menus[0].menucategories.map((mc) => mc.orderedmenuitems)))];
            allMenuItems = allMenuItems.map((orderedMenuItem) => {
              orderedMenuItem.menuitem.orderedmenuitemId =  orderedMenuItem.id;
              orderedMenuItem.menuitem.menucategory = orderedMenuItem.menucategory;
              return orderedMenuItem.menuitem;
            });

            // filter the list of menu items based on the selected menu category
            if (this.activeMenuCategory !== "all") {
              allMenuItems =  allMenuItems.filter((menuitem) => {
                //const group = child.groups.find(item => item === type);
                //const group = child.groups.find(item => item === type);
                const group = menuitem.menucategory.name_i18n[locale] === this.activeMenuCategory;
                
                return !!group;
              });
            }
          }
        }
      }
    }
    
    if(this.state.foodTypeFilter === allStr &&
      this.state.foodCategoryFilter === allStr &&
      this.state.activeFilter === allStr) {
        //console.log('on est la sans filtre');
       return allMenuItems;
    } else {
      //console.log('on est la avec filtre');
      let filteredData = allMenuItems;
      // filter on foodType
      if (this.state.foodTypeFilter !== allStr) {
        filteredData = filteredData.filter((item) => {
          const foundFoodType = item.foodtypes.length > 0 ? item.foodtypes.find(foodType => foodType.name_i18n[locale] === this.state.foodTypeFilter) : null;
          return !!foundFoodType;
        })
      }
      
      // filter on food category
      if (this.state.foodCategoryFilter !== allStr) {
        filteredData = filteredData.filter((item) => {
          const foundFoodCategory = item.foodcategory ? item.foodcategory.namePlural_i18n[locale] === this.state.foodCategoryFilter : null;
          return !!foundFoodCategory;
        })
      }
      // filter on food is active or not
      if (this.state.activeFilter !== allStr) {
        filteredData = filteredData.filter((item) => {
          const {intl} = this.props;
          const foundActiveFoodItem = item.isActive === (this.state.activeFilter === intl.formatMessage({ id: "MenuItems.filtersData.activeFilterLabel.Yes", defaultMessage: 'Yes'}));
          return !!foundActiveFoodItem;
        })
      }

      GAEvent("ADMINPANEL", "Filter menu items based on filters", "MENUITEMS_PAGE_FILTER_MENUITEMS_BASED_ON_FILTERS");
      return filteredData;
    }
  }

  openModal(id) {
    this._isMounted && this.setState({ isModalActive: true, modalId: id });
  }

  closeModal = () => {
    this._isMounted && this.setState({ isModalActive: false, modalId: null });
  }

  hideOrShowDishInMenu(filteredMenuItems, index) {
    const menuItemToUpdate =  filteredMenuItems[index];
    //menuItemToUpdate.isActive = !menuItemToUpdate.isActive;
    if(menuItemToUpdate.isActive) {
      this._isMounted && this.setState({
        isModalHideDishOpen: true,
        isModalShowDishOpen: false,
        selectedDishToHideOrShow: menuItemToUpdate
      });
    } else {
      this._isMounted && this.setState({
        isModalShowDishOpen: true,
        isModalHideDishOpen: false,
        selectedDishToHideOrShow: menuItemToUpdate
      });
    }
  }

  showDishInMenu() {
    const {dispatch} = this.props;
    const {selectedDishToHideOrShow} = this.state;
    //console.log('hiding ', selectedDishToHideOrShow);
    dispatch(updateMenuItemVisibility(selectedDishToHideOrShow));
    GAEvent("ADMINPANEL", "Show dish in menu", "MENUITEMS_PAGE_SHOW_DISH_IN_MENU");
    this._isMounted && this.setState({
      isModalShowDishOpen: false,
      isModalHideDishOpen: false,
      selectedDishToHideOrShow: null
    });
  }

  hideDishInMenu() {
    const {dispatch} = this.props;
    const {selectedDishToHideOrShow} = this.state;
    //console.log('hiding ', selectedDishToHideOrShow);
    dispatch(updateMenuItemVisibility(selectedDishToHideOrShow));
    GAEvent("ADMINPANEL", "Hide dish in menu", "MENUITEMS_PAGE_HIDE_DISH_IN_MENU");
    this._isMounted && this.setState({
      isModalShowDishOpen: false,
      isModalHideDishOpen: false,
      selectedDishToHideOrShow: null
    });
  }

  toggleModalHideDish() {
    this._isMounted && this.setState({
      isModalHideDishOpen: !this.state.isModalHideDishOpen
    })
  }

  toggleModalShowDish() {
    this._isMounted && this.setState({
      isModalShowDishOpen: !this.state.isModalShowDishOpen
    })
  }

  // Menu category or menu group
  filterMenuItemsByMenuCategory(categoryId, categoryName) {
    let allMenuItems = null;
    //console.log('filterMenuItemsByMenuCategory categoryId => ', categoryId);
    //console.log('filterMenuItemsByMenuCategory categoryName => ', categoryName);
    //console.log('filterMenuItemsByMenuCategory this.activeMenuCategoryId => ', this.activeMenuCategoryId);
    // if we have only one category this means we are now in a bundle menus offer (=> menugroup)
    if (this.props.restaurantMenuItems.restaurant.menus[0].menucategories.length === 1 &&
      this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].orderedmenuitems.length === 0) {
        // in menugroup Mode
      const selectedMenugroupMenuItems = this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].menugroups.filter((mg) => mg.id === categoryId);
      //console.log('selectedMenugroupMenuItems => ', selectedMenugroupMenuItems);
      if (selectedMenugroupMenuItems.length > 0) {
        allMenuItems = selectedMenugroupMenuItems[0].orderedmenuitems;
        allMenuItems = allMenuItems.map((orderedMenuItem) => {
          orderedMenuItem.menuitem.orderedmenuitemId =  orderedMenuItem.id;
          // we flag this menu item as a menuitem that does not belongs to a menuitem group
          orderedMenuItem.menuitem.isMenuItemForGroup = false;
          return orderedMenuItem.menuitem;
        });

        // array that will contain all the menu items of a menu group
        let tempMenuitemsGroupMenuItems = [];
        // we are menuitems group mode
        if(selectedMenugroupMenuItems[0].menuitemgroups.length > 0) {
          tempMenuitemsGroupMenuItems = [...new Set([].concat(...selectedMenugroupMenuItems[0].menuitemgroups.map((mig) => mig.orderedmenuitems)))];
          //console.log('tempMenuitemsGroupMenuItems 2 => ', tempMenuitemsGroupMenuItems);
          // if all menuitems group in the menu group have ordered menu items
          if(tempMenuitemsGroupMenuItems.length > 0) {
            // we map the array to only return menu items
            tempMenuitemsGroupMenuItems = tempMenuitemsGroupMenuItems.map((orderedMenuItem) => {
              orderedMenuItem.menuitem.orderedmenuitemId =  orderedMenuItem.id;
              // we flag this menu item as a menuitem that belongs to a menuitem group
              orderedMenuItem.menuitem.isMenuItemForGroup = true;
              return orderedMenuItem.menuitem;
            });
            allMenuItems = [...new Set(allMenuItems.concat(tempMenuitemsGroupMenuItems))];
            //console.log('allMenuItems 2 => ', allMenuItems);
          }
        }

        this.activeMenuCategory = categoryName;
        this.activeMenuCategoryId = categoryId; 
        //console.log("this.activeMenuCategory (menugroup)=> ", this.activeMenuCategory);
        //console.log('allMenuItems => ', allMenuItems);

        if(this.activeMenuCategoryId) {
          // check if the current menugroupid in the url is different than
          // the one stored in the component activemenucategoryid var
          const params = queryString.parse(this.props.location.search);
          const currentMenuGroupIdInURL = params.menugroupid;
          if(this.activeMenuCategoryId !== currentMenuGroupIdInURL){
            this.props.history.push(`?menugroupid=${this.activeMenuCategoryId}&ts=${new Date().getTime()}`);
          }
        } else {
          this.props.history.push(`?ts=${new Date().getTime()}`);
        }

        this._isMounted && this.setState({
          allFilteredMenuItems: allMenuItems,
          activeMenuCategoryIdInState: this.activeMenuCategoryId
        });
      } 
    } else {
      // we are in normal menu mode
      allMenuItems = [...new Set([].concat(...this.props.restaurantMenuItems.restaurant.menus[0].menucategories.map((mc) => mc.orderedmenuitems)))];
      allMenuItems = allMenuItems.map((orderedMenuItem) => {
        orderedMenuItem.menuitem.orderedmenuitemId = orderedMenuItem.id;
        return orderedMenuItem.menuitem;
      });

      // filter the list of menu items based on the selected menu category
      const allFilteredMenuItems = categoryName === 'all' ? allMenuItems : allMenuItems.filter((child) => {
        //const group = child.groups.find(item => item === type);
        const {locale} = this.props.intl;
        const group = child.menucategory ?  child.menucategory.name_i18n[locale] === categoryName : false;
        return !!group;
      });

      this.activeMenuCategory = categoryName;
      this.activeMenuCategoryId = categoryId; 
      //console.log("this.activeMenuCategory (menucategory) => ", this.activeMenuCategory);

      if(this.activeMenuCategoryId) {
        // check if the current menugroupid in the url is different than
        // the one stored in the component activemenucategoryid var
        const params = queryString.parse(this.props.location.search);
        const currentMenuCategoryIdInURL = params.menucategoryid;
        if(this.activeMenuCategoryId !== currentMenuCategoryIdInURL){
          this.props.history.push(`?menucategoryid=${this.activeMenuCategoryId}&ts=${new Date().getTime()}`);
        }
      } else {
        this.props.history.push(`?ts=${new Date().getTime()}`);
      }

      this._isMounted && this.setState({
        allFilteredMenuItems,
        activeMenuCategoryIdInState: this.activeMenuCategoryId
      });

      GAEvent("ADMINPANEL", "Filter menu items by menu category", "MENUITEMS_PAGE_FILTER_MENUITEMS_BY_MENU_CATEGORY");
    }
  }

  toggleTooltip(id, field) {
    const newState = [...this.state[field]];
    newState.fill(false);

    if (!this.state[field][id]) {
      newState[id] = true;
    }

    this._isMounted && this.setState({
      [field]: newState,
    });
  }

  render() {
    const {locale} = this.props.intl;
    const menuCategories = this.props.restaurantMenuItems && this.props.restaurantMenuItems.restaurant ? this.props.restaurantMenuItems.restaurant.menus[0].menucategories : null;
    const filterForMenuCategories = this.retrieveListOfMenuCategories();
    const menuItemsArray = this.filterMenuItemsBasedOnSelectedFilters();
    //console.log('menuItemsArray => ', menuItemsArray);
    const { isModalActive, modalId } = this.state;
    const filtersForMenuItems = this.retrieveMenuItemsFilters();
    const currencySymbol = this.props.restaurantMenuItems && this.props.restaurantMenuItems.restaurant ? this.props.restaurantMenuItems.restaurant.city.country.currency.symbol : "";
    //console.log(currencySymbol);
    const menuName = this.props.restaurantMenuItems && this.props.restaurantMenuItems.restaurant ? this.props.restaurantMenuItems.restaurant.menus[0].userLabel_i18n[locale] : "";
    const { restaurant } = this.props.restaurantMenuItems;
    const areWeLoadingANewMenu = this.props.restaurantMenuItems &&
                                 this.props.restaurantMenuItems.restaurant ? this.props.restaurantMenuItems.restaurant.menus[0].id !== this.props.match.params.menuid : false;
    // if we have a menu group then this variable will retrieve the description of the menu group
    const menuGroupObj = this.props.restaurantMenuItems &&
                                          this.props.restaurantMenuItems.restaurant &&
                                          this.props.restaurantMenuItems.restaurant.menus[0].menucategories.length === 1 &&
                                          this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].orderedmenuitems.length === 0 &&
                                          this.activeMenuCategoryId ? 
                                            this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].menugroups.filter((mg) => mg.id === this.activeMenuCategoryId)[0]
                                             : null;

    // if we have a menu group with menuitems groups
    const menuitemsgroupArray = menuGroupObj ? menuGroupObj.menuitemgroups : null;
    // get the menu category id for the bundled menus
    const menuCategoryIdForMenuGroup = this.props.restaurantMenuItems &&
                                        this.props.restaurantMenuItems.restaurant &&
                                        this.props.restaurantMenuItems.restaurant.menus[0].menucategories.length === 1 &&
                                        this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].orderedmenuitems.length === 0 ? this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].id : null;
    //console.log("this.activeMenuCategory => ", this.activeMenuCategory);
    return (
      <div className={s.root}>
        <Breadcrumb>
          <BreadcrumbItem>{this.props.managedRestaurant? this.props.managedRestaurant.name : null}</BreadcrumbItem>
          <BreadcrumbItem>{!areWeLoadingANewMenu ? <Link style={{color: 'black', textDecoration: 'underline'}} to="/app/menus">{menuName}</Link> : "..." }</BreadcrumbItem>
          {
            this.activeMenuCategoryId && menuGroupObj === null ?
            <BreadcrumbItem active={this.activeMenuCategoryId === null}>
                <a href="/" 
                  style={{color: 'black', textDecoration: 'underline'}}
                  onClick={(e) => {
                      e.preventDefault();
                      this.filterMenuItemsByMenuCategory(null, 'all')
                  }}>
                  <FormattedMessage id="MenuItems.Breadcrumbitem.MenusItems" defaultMessage="Menus Items" />
                </a>
              &nbsp;&nbsp;
              <Badge color="primary">{menuItemsArray && !areWeLoadingANewMenu ? menuItemsArray.length : null}</Badge>
            </BreadcrumbItem>
            :
            <BreadcrumbItem active>
              {this.activeMenuCategory}
              &nbsp;&nbsp;
              {
                // we do not want to display the count elements badge in combo menus
                menuGroupObj === null ? 
                  <Badge color="primary">{menuItemsArray && !areWeLoadingANewMenu ? menuItemsArray.length : null}</Badge> : null
              }
            </BreadcrumbItem>
          }
        </Breadcrumb>
        {this.props.isFetching ? <ProgressIndicator />: null}
        {menuCategories && menuCategories.length > 0 && !isModalActive && !areWeLoadingANewMenu ?
        <Row style={{marginBottom: "10px"}}>
              <Col lg={9} md={9} sm={9} xs={9} style={{marginBottom: "5px"}}>
                <ButtonToolbar>
                  {
                    //###############################################################
                    // only for menu categories ('all' tab)
                    //###############################################################
                    !areWeLoadingANewMenu && menuGroupObj === null && filterForMenuCategories.length > 1?
                    <ButtonGroup>
                          <Button color="default" onClick={() => this.filterMenuItemsByMenuCategory(null, 'all')} active={this.activeMenuCategoryId === null || this.activeMenuCategoryId === undefined}>{this.state.allString}</Button>
                    </ButtonGroup>
                    : null
                  }
                {
                  //###############################################################
                  // for every menu category or menu group (top tabs)
                  //###############################################################
                  filterForMenuCategories.length > 1 ?
                  filterForMenuCategories.map((menuCategoryObj, index) => {
                    const menuCategoryStr = menuCategoryObj.name;
                    //console.log("menuCategoryStr => ", menuCategoryStr);
                    //console.log("menuCategoryObj => ", menuCategoryObj);
                    const key = menuCategoryStr + index;
                    // we want to limit the visible text in the button group to xx characters
                    // for the sake of a simple, consistent and clean UI
                    if (menuCategoryStr.length > 15) {
                      //console.log('this.activeMenuCategoryId => ', this.activeMenuCategoryId);
                      //console.log('menuCategoryObj.id => ', menuCategoryObj.id);
                      return(
                        <span key={key} id={"menu-category-" + index}>
                          <ButtonGroup>
                            <Button color="default" onClick={() => this.filterMenuItemsByMenuCategory(menuCategoryObj.id, menuCategoryStr)} active={this.activeMenuCategoryId === menuCategoryObj.id}>
                              {menuCategoryStr.substring(0,15)}...
                            </Button>
                          </ButtonGroup>
                          <Tooltip placement="top" isOpen={this.state.tooltipMenuCategory[index]} toggle={() => this.toggleTooltip(index, 'tooltipMenuCategory')} 
                          target={"menu-category-" + index}>
                            {menuCategoryStr}
                          </Tooltip>
                        </span>
                      );
                    } else {
                      return (
                        <ButtonGroup key={key}>
                          <Button color="default" onClick={() => this.filterMenuItemsByMenuCategory(menuCategoryObj.id, menuCategoryStr)} active={this.activeMenuCategoryId === menuCategoryObj.id}>{menuCategoryStr}</Button>
                        </ButtonGroup>
                      )
                    }
                  }) : null
                }
                </ButtonToolbar>
              </Col>
              <Col lg={3} md={3} sm={3} xs={3} className="text-right">
                <span>
                  <ButtonGroup id="buttonRefreshDataFromServer">
                    <Button onClick={() => {
                        this.refreshMenuItemsFromServer(true, this.props.match.params.menuid);
                        GAEvent("ADMINPANEL", "Refresh menu items data from server", "MENUITEMS_PAGE_REFRESH_DATA_FROM_SERVER");
                      }
                    } color="default"><i className="la la-refresh"></i></Button>
                  </ButtonGroup>
                  <Tooltip placement="top" isOpen={this.state.tooltipRefreshDataFromServer[0]} toggle={() => this.toggleTooltip(0, 'tooltipRefreshDataFromServer')} 
                    target={"buttonRefreshDataFromServer"}>
                    <FormattedMessage id="MenuItems.button.refresh.data.from.server" defaultMessage="Refresh list of dishes" />
                  </Tooltip>
                </span>
              </Col>
        </Row> : null}
        {
          //###############################################################
          // for every menugroup object
          //###############################################################
          this.activeMenuCategoryId && !areWeLoadingANewMenu && menuGroupObj ?
          <Row>
            <Col>
              <div className={s.jumbotronContainer + " jumbotron"} style={{
                    backgroundImage: 'url('+menuGroupObj.picture.url+')'
              }}>
                <h3 className={s.titleMenuGroupCard}>
                  {menuGroupObj.name_i18n[locale]}
                  {
                    menuGroupObj.posReferenceId ? 
                    <span className={s.posReferenceIdMenuGroup}>
                      &nbsp;({menuGroupObj.posReferenceId})
                    </span> : null
                  }
                </h3>
                <p className={s.subtitleMenuGroupCard}>
                  {menuGroupObj.description_i18n[locale]}
                </p>
                <p>
                  <Label className={s.switch}>
                    <span className={s.switchLabel}>
                      <FormattedMessage id="MenuItems.menugroup.jumbotron.isactive.switch.label" defaultMessage="Active?" />&nbsp;
                    </span>
                    <MyIsActiveComponent checked={menuGroupObj.isActive} />
                    <i></i>
                  </Label>
                </p>
                <p className="lead">
                  <Badge color="primary" style={{fontSize: '22px'}}>{restaurant.city.country.currency.symbol} {restaurant.city.country.currency.numberOfDecimals <= 2 ? parseFloat(menuGroupObj.price.toFixed(restaurant.city.country.currency.numberOfDecimals)) : menuGroupObj.price.toFixed(restaurant.city.country.currency.numberOfDecimals)}</Badge>
                </p>
                {
                  /*<p className="lead">
                    <Button color="primary">Learn More</Button>
                  </p>*/
                }
              </div>
            </Col>
          </Row> : null
        }
        {menuCategories && menuCategories.length === 0  && !isModalActive && !areWeLoadingANewMenu ?<span>&nbsp;<FormattedMessage id="MenuItems.Page.No.MenuItems.Available" defaultMessage="No menu items available for this menu" />.</span> : null}
        {
          //###############################################################
          // For every filter (category, type, visibility)
          //###############################################################
          menuCategories && menuCategories.length > 0 && !isModalActive && !areWeLoadingANewMenu ? 
          <div className={s.productsListFilters}>
                {filtersForMenuItems.map(item =>
                  (typeof item.data[0] === 'string'
                    ? <FilterElement defaultLabel={item.title} onChange={(currentOption) => {this.updateFilter(item.title,currentOption)}} options={item.data} key={item.id} />
                    : item.data.map(i =>
                      <FilterElement defaultLabel={i.label} onChange={(currentOption) => {this.updateFilter(i.label,currentOption)}} options={i.options} key={i.id} />)),
                )}
          </div> : null
        }
        {
          menuCategories && menuCategories.length > 0 && !isModalActive && !areWeLoadingANewMenu ? 
          <div className={s.mobileFilterButtons}>
            <button
              className="btn btn-transparent btn-lg"
              onClick={() => this.openModal(0)}
            >
              <FormattedMessage id="MenuItems.filtersData.title" defaultMessage="Filter" /><i className="fa fa-2x fa-angle-down" />
            </button>
          </div> : null
        }
        <div className={s.gallery}>
          {
            //###############################################################
            // for every Menu Item
            //###############################################################
            menuItemsArray && menuItemsArray.length > 0 && !areWeLoadingANewMenu ? 
            // we only display the menu items that are not part of a menu item group as a gallery
            // the others will be displayed in widget of menuitem groups
            menuItemsArray.filter(menuitem => !menuitem.isMenuItemForGroup).map((menuItemObj, index) => {
              //console.log('menuItemObj => ', menuItemObj);
              const key = menuItemObj.name + index;
              return (
                <div key={key} className={`${s.picture} card`}>
                  <CarouselMenuItem menuItem={menuItemObj} height={"320px"}/>
                  {
                    menuItemObj.isRecommendedByTheChef ? 
                    <div>
                      <div className={s.label + ' bg-danger'} id={"ribbon-recommendedByTheChef-" + index}>
                        <img width="15px" src={chefHat} alt="" />
                      </div>
                      <Tooltip placement="top" isOpen={this.state.tooltipsRecommendedByTheChef[index]} toggle={() => this.toggleTooltip(index, 'tooltipsRecommendedByTheChef')} 
                      target={"ribbon-recommendedByTheChef-" + index}>
                        <FormattedMessage id="MenuItems.MenuItem.ribbon.recommandedByTheChef" defaultMessage="Recommended by the Chef" />
                      </Tooltip>
                    </div>
                    : null
                  }
                  {/*<RealPictureRibbon isRealPicture = {menuItemObj.isRealPictures} />*/}
                  <Button id={"button-disable-enable-menuitem-" + index} className={s.eye} color="primary" onClick={() => this.hideOrShowDishInMenu(menuItemsArray, index)}>
                    <img src={menuItemObj.isActive ? eye : eyeSlash} alt={menuItemObj.isActive ?  <FormattedMessage id="MenuItems.MenuItem.Card.Active" defaultMessage="Active" /> : <FormattedMessage id="MenuItems.MenuItem.Card.Non.Active" defaultMessage="Non Active" />} />
                  </Button>
                  <Tooltip placement="top" isOpen={this.state.tooltipsShowHideDish[index]} toggle={() => this.toggleTooltip(index, 'tooltipsShowHideDish')} 
                  target={"button-disable-enable-menuitem-" + index}>
                    <FormattedMessage id="MenuItems.MenuItem.Card.Active.tooltip.show.hide.dish" defaultMessage="Show or hide dish" />
                  </Tooltip>
                  <div className={s.description}>
                    <h6 className="mt-0 mb-xs fw-semi-bold">{menuItemObj.name_i18n[locale]}</h6>
                    <RealPictureText isRealPicture = {menuItemObj.isRealPictures} />
                  </div>
                  <div className={s.footer}>
                    <hr style={{marginBottom: '7px'}}/>
                    <div className="w-100 d-flex justify-content-between align-items-center">
                      {
                        // if we have a menu that is a menu group
                        restaurant && (restaurant.menus[0].menucategories.length >= 1 &&
                          this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].orderedmenuitems.length > 0) ? (
                          menuItemObj.menuitemsizes[0] ? (
                            menuItemObj.menuitemsizes.length > 1 ?
                              <span className="text-muted fw-semi-bold">      
                                <FormattedMessage id="MenuItems.MenuItem.Card.label.price.from" defaultMessage="From" /> {currencySymbol} {restaurant.city.country.currency.numberOfDecimals <= 2 ? parseFloat(menuItemObj.menuitemsizes[0].price.toFixed(restaurant.city.country.currency.numberOfDecimals)) : menuItemObj.menuitemsizes[0].price.toFixed(restaurant.city.country.currency.numberOfDecimals)}
                              </span> :
                              <span className="text-muted fw-semi-bold">      
                                {currencySymbol} {restaurant.city.country.currency.numberOfDecimals <= 2 ? parseFloat(menuItemObj.menuitemsizes[0].price.toFixed(restaurant.city.country.currency.numberOfDecimals)) : menuItemObj.menuitemsizes[0].price.toFixed(restaurant.city.country.currency.numberOfDecimals)}
                              </span>
                            )
                            : <span className="btn-danger">
                                &nbsp;
                                <FormattedMessage id="MenuItems.MenuItem.Card.label.price.not.available" defaultMessage="Price is not available" />
                                &nbsp;
                              </span>
                        ) : <span className="btn-gray">
                              &nbsp;
                              <FormattedMessage id="MenuItems.MenuItem.Card.label.price.not.applicable" defaultMessage="Price is not applicable" />
                              &nbsp;
                            </span>
                      }
                      {
                        //###############################################################
                        // for every menuitem obj determine the menucategory or menugroup url
                        //###############################################################
                        restaurant && (restaurant.menus[0].menucategories.length >= 1 &&
                          this.props.managedRestaurant &&
                          this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].orderedmenuitems.length > 0) ?
                          <Button color="info" href={`#/app/restaurant/${this.props.managedRestaurant.slug}/menu/${restaurant.menus[0].id}/menucategory/${menuItemObj.menucategory.id}/menuitem/${menuItemObj.orderedmenuitemId}`}>
                            <FormattedMessage id="MenuItems.MenuItem.Card.button.details" defaultMessage="Details" />
                          </Button> : 
                          <Button color="info" href={`#/app/restaurant/${this.props.managedRestaurant.slug}/menu/${restaurant.menus[0].id}/menucategory/${menuCategoryIdForMenuGroup}/menugroup/${this.activeMenuCategoryId}/menuitem/${menuItemObj.orderedmenuitemId}`}>
                            <FormattedMessage id="MenuItems.MenuItem.Card.button.details" defaultMessage="Details" />
                          </Button>
                      }
                    </div>
                  </div>
                </div>
              );
            }): null // end of menucategory.map
          }
        </div>
        {
          menuitemsgroupArray && menuitemsgroupArray.length > 0 &&
          this.props.managedRestaurant &&
          !areWeLoadingANewMenu &&
          menuItemsArray && menuItemsArray.length > 0 ?
            <MenuitemgroupsTable menuitemgroups={menuitemsgroupArray}
                            restaurantId={this.props.managedRestaurant.slug}
                            menuId={restaurant.menus[0].id}
                            menuCategoryIdForMenuGroup={menuCategoryIdForMenuGroup}
                            activeMenuCategoryId={this.activeMenuCategoryId}
                            filteredMenuitems={menuItemsArray.filter(menuitem => menuitem.isMenuItemForGroup)}
                            locale={locale} />
          : null
        }
        {
          menuCategories && menuCategories.length > 0 && 
          menuItemsArray && menuItemsArray.length === 0 && 
          !areWeLoadingANewMenu ?
            <span>&nbsp;
              <FormattedMessage id="MenuItems.Page.No.FilteredMenuItems.Available" 
                                defaultMessage="No menu items available for the selected filters" />.</span> 
            : null
        }
        {
          filtersForMenuItems.length > 0 ?
          <MobileModal active={isModalActive && modalId === 0} 
                    data={filtersForMenuItems[0]} 
                    close={this.closeModal}
                    onSelectedValue={this.updateFilter} />
          :null
        }
        <Modal size="lg" isOpen={this.state.isModalHideDishOpen} toggle={this.toggleModalHideDish}>
          <ModalHeader toggle={this.toggleModalHideDish}>
            <FormattedMessage id="MenuItems.Modal.hide.dish.header" defaultMessage="Hide dish confirmation" />
          </ModalHeader>
          <ModalBody className="bg-white">
            {this.state.selectedDishToHideOrShow? <b>{this.state.selectedDishToHideOrShow.name_i18n[locale]}</b>: null}<br /><br />
            <span style={{color:"red"}}>
              <FormattedMessage id="MenuItems.Modal.hide.dish.body" defaultMessage="This action will remove the above dish from the menu. This means that your customers won't be able to see it in the menu and therefore it won't be available for ordering." />
            </span>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleModalHideDish}>
              <FormattedMessage id="MenuItems.Modal.hide.dish.footer.button.close" defaultMessage="Close" />
            </Button>
            <Button color="primary" onClick={this.hideDishInMenu}>
              <FormattedMessage id="MenuItems.Modal.hide.dish.footer.button.confirm" defaultMessage="Confirm" />
            </Button>
          </ModalFooter>
        </Modal>
        <Modal size="lg" isOpen={this.state.isModalShowDishOpen} toggle={this.toggleModalShowDish}>
          <ModalHeader toggle={this.toggleModalShowDish}>
            <FormattedMessage id="MenuItems.Modal.show.dish.header" defaultMessage="Show dish confirmation" />
          </ModalHeader>
          <ModalBody className="bg-white">
            {this.state.selectedDishToHideOrShow? <b>{this.state.selectedDishToHideOrShow.name_i18n[locale]}</b>: null}<br /><br />
            <span style={{color:"blue"}}>
              <FormattedMessage id="MenuItems.Modal.show.dish.body" defaultMessage="This action will publish the above dish in your menu. This means that your customers will be able to see it in the menu and therefore it will be available for ordering." />
            </span>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleModalShowDish}>
              <FormattedMessage id="MenuItems.Modal.show.dish.footer.button.close" defaultMessage="Close" />
            </Button>
            <Button color="primary" onClick={this.showDishInMenu}>
              <FormattedMessage id="MenuItems.Modal.show.dish.footer.button.confirm" defaultMessage="Confirm" />
            </Button>
          </ModalFooter>
        </Modal>
      </div>);
  } // enf of render
} // end of class

function mapStateToProps(store) {
  return {
    isFetching: store.menuitem.isFetching,
    errorMessage: store.menuitem.errorMessage,
    restaurantMenuItems: store.menuitem.restaurantMenuItems,
    updatedMenuItem: store.menuitem.updatedMenuItem,
    userInfo: store.auth.userInfo,
    managedRestaurant: store.auth.managedRestaurant
  };
}

export default injectIntl(withRouter(connect(mapStateToProps)(Menuitems)));
