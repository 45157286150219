import { ORDERS_REQUEST , ORDERS_SUCCESS, ORDERS_FAILURE,
  UPDATE_ORDER_STATUS_REQUEST, UPDATE_ORDER_STATUS_SUCCESS, UPDATE_ORDER_STATUS_FAILURE, COUNT_ORDERS_IN_POS_SUCCESS } from '../actions/orders';

// The menu reducer.
export default function order(state = {
  isFetching: false,
  menus: [],
  errorMessage: '',
  updatedMenu: {},
}, action) {
  switch (action.type) {
    case ORDERS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        errorMessage: ''
      });
    case ORDERS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        restaurantWithOrders: action.restaurantWithOrders,
        errorMessage: '',
      });
    case COUNT_ORDERS_IN_POS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        totalNumberOfOrdersInPoS: action.totalNumberOfOrdersInPoS,
        errorMessage: '',
      });
    case ORDERS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.message,
      });
    case UPDATE_ORDER_STATUS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        errorMessage: ''
      });
    case UPDATE_ORDER_STATUS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        updatedOrder: action.updatedOrder,
        errorMessage: '',
      });
    case UPDATE_ORDER_STATUS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.message,
      });
    default:
      return state;
  }
}
