import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

//import Rating from '../Rating/Rating';

import s from './General.module.scss';

import Foodtypes from '../Foodtypes/Foodtypes';

import RealPictureText from '../../../../components/RealPictureText/RealPictureText';

const General = ({ /*rating,*/ title, subtitle, /*price*/ listOfFoodTypes, locale, isRealPicture }) => (
  <div className={s.general}>
    {/*<div className={s.ratingWrapper}>
      <Rating rating={rating} />
</div>*/}
    <div className={s.dataWrapper}>
      <span className={cx(s.title, 'title')}>{title}</span>
      <RealPictureText isRealPicture={isRealPicture} />
      <Foodtypes foodCategoryLabel={subtitle} listOfFoodtypes={listOfFoodTypes} locale={locale} islistview={false}/>
    </div>
    {/*<span className={s.price}>{price}{this.props.currency}</span>*/}
  </div>
);

General.propTypes = {
  /*rating: PropTypes.number.isRequired,*/
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  listOfFoodTypes: PropTypes.array.isRequired,
  locale: PropTypes.string.isRequired,
  isRealPicture: PropTypes.bool
  /*pricesWithSizes: PropTypes.any.isRequired,
  currency: PropTypes.string.isRequired*/
};

General.defaultProps = {
  isRealPicture: false
};

export default General;
