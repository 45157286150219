/* eslint-disable import/prefer-default-export */
import localeData from "./../../public/locales/data.json";

export const CHANGE_LOCALE_ACTIVE_ITEM = 'CHANGE_LOCALE_ACTIVE_ITEM';
export const REQUEST_LANGUAGE_PACKS = 'REQUEST_LANGUAGE_PACKS';
export const LANGUAGE_PACKS_FAILURE = 'LANGUAGE_PACKS_FAILURE';
export const LANGUAGE_PACKS_SUCCESS = 'LANGUAGE_PACKS_SUCCESS';

export function changeLocaleActiveItem(activeLocaleItem, arrayLocales) {
  // remove the active locale from the array of locales

  // save the active locale to the locale storage
  localStorage.setItem('activeLocaleItem', JSON.stringify(activeLocaleItem));
  const localeCode2Chars = activeLocaleItem.localeCode.toLowerCase().split('_')[0];
  // console.log('localeCode2Chars => ', localeCode2Chars);
  // get the messages that applies to the selected locale
  const messages =
    localeData[localeCode2Chars]; // locale code en_US we take only en
  // filter the list of locales to get the one that are not selected
  const nonActiveLocales = arrayLocales.filter(localeObject => 
      (localeObject.localeCode !== activeLocaleItem.localeCode) && (localeObject.isActive)
    );

  // return action
  return {
    type: CHANGE_LOCALE_ACTIVE_ITEM,
    activeLocaleItem: activeLocaleItem,
    nonActiveLocales,
    messages
  };
}
