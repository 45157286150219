import axios from 'axios';
var moment = require('moment-timezone');
//import runtimeEnv from '@mars/heroku-js-runtime-env';

export async function getListOfOrdersForDayForRestaurant (token, restaurantId, day, restaurantTimezone) {
    // endpoint path
    const currentTimezoneOffset = moment.tz(restaurantTimezone)
    const currentOffsetNumber = currentTimezoneOffset.utcOffset()
    // console.log('currentOffsetNumber => ', currentOffsetNumber)
    const offsetStr = (`0${currentOffsetNumber / 60}`).slice(-2)
    // console.log('offsetStr => ', offsetStr)
    const apiEndpointPath = '/graphql';
    const startDatetimeString = day + 'T00:00:00.000+' + offsetStr
    const endDatetimeString = day + 'T23:59:59.000+' + offsetStr
    const localStartDatetimeString = moment(startDatetimeString).tz(restaurantTimezone).format();
    const localEndDatetimeString = moment(endDatetimeString).tz(restaurantTimezone).format();
    const today = moment().tz(restaurantTimezone).format('YYYY-MM-DD')
    const yesterday = moment().tz(restaurantTimezone).subtract(1, 'days').format('YYYY-MM-DD')
    const isQueryForTodayOrYesterday = (today === day || yesterday === day)
    // console.log('local start datetime => ', localStartDatetimeString)
    // console.log('local end datetime => ', localEndDatetimeString)
    const utcStartDatetimeString = moment(localStartDatetimeString, null).utc().format();
    const utcEndDatetimeString = moment(localEndDatetimeString, null).utc().format();

    // console.log('utc start datetime => ', utcStartDatetimeString)
    // console.log('utc end datetime => ', utcEndDatetimeString)
    // init runtime env var
    //const env = runtimeEnv();
    // Request API.
    // isDineInOrder_eq: true 
    const responseRestaurantWithDineInOrders = await axios
    .post( (/*env.REACT_APP_STRAPI_PROTOCOL || */process.env.REACT_APP_STRAPI_PROTOCOL || 'http') +
    '://' + (/*env.REACT_APP_STRAPI_HOST || */process.env.REACT_APP_STRAPI_HOST || 'localhost') +
     ":" + (/*env.REACT_APP_STRAPI_PORT || */process.env.REACT_APP_STRAPI_PORT || '1337') + apiEndpointPath + '?restaurantId=' + restaurantId + `${isQueryForTodayOrYesterday ? "&nocaching=1" : ""}`, 
           {
            "query": `query getListOfOrdersForDayForRestaurant {  restaurantDetails(slug: "${restaurantId}") {    name  deliverymethods { code  isDineIn  isWithOnlinePayment  description_i18n  isCollectAtLocation shortCode} paymentMethods {  code  isOnlinePaymentMethod } printers{   isActive   }    city {  momentJsTimezoneCode    country {        currency {          symbol    numberOfDecimals    }      }    }    orders (where: {  isWithOnlinePayment: false   status_nin: ["Rejected"]     createdOn_gt: "${utcStartDatetimeString}",      createdOn_lt: "${utcEndDatetimeString}" }    ) {   id   type   createdOn   acceptedOn   readyToBeServedOn  tableLabel  groupCodeForOrder  typeShortCode isDineInOrder  isDeliverToLocation isCollectAtLocation isWithOnlinePayment  orderNumber  status   posSyncStatus  posName  customerEmailForReceipt   customerFirstname  customerLastname   customerPhoneNumberForNotification      totalPrice     uuid   paymentInformation{  statusCode  method  paymentId  checkoutUrl }  orderContent extraInformation }  }}`
           }
           ,{
               headers: { 
                'Authorization':`Bearer ${token}`
                }
            }
        )
    .then(response => {
        // Handle success.
        //console('menu axios response', response);
        if(response.data.errors) return response.data;

        return response.data.data.restaurantDetails;
    });

    const responseRestaurantWithDineOutOrders = await axios
    .post( (/*env.REACT_APP_STRAPI_PROTOCOL || */process.env.REACT_APP_STRAPI_PROTOCOL || 'http') +
    '://' + (/*env.REACT_APP_STRAPI_HOST || */process.env.REACT_APP_STRAPI_HOST || 'localhost') +
     ":" + (/*env.REACT_APP_STRAPI_PORT || */process.env.REACT_APP_STRAPI_PORT || '1337') + apiEndpointPath + '?restaurantId=' + restaurantId + `${isQueryForTodayOrYesterday ? "&nocaching=1" : ""}`, 
           {
            "query": `query getListOfOrdersForDayForRestaurant {  restaurantDetails(slug: "${restaurantId}") {    name  deliverymethods { code  isDineIn  isWithOnlinePayment  description_i18n  isCollectAtLocation  shortCode } paymentMethods {  code  isOnlinePaymentMethod }   printers{   isActive   }   city {  momentJsTimezoneCode    country {       currency {          symbol    numberOfDecimals    }      }    }    orders (where: {       isWithOnlinePayment: true    status_nin: ["Refunded"]      createdOn_gt: "${utcStartDatetimeString}",      createdOn_lt: "${utcEndDatetimeString}"    }    ) {   id  type   createdOn   acceptedOn   readyToBeServedOn  tableLabel  groupCodeForOrder  typeShortCode  isDineInOrder  isDeliverToLocation isCollectAtLocation  isWithOnlinePayment  orderNumber  status   posSyncStatus   posName   customerEmailForReceipt   customerFirstname  customerLastname   customerPhoneNumberForNotification   uuid   totalPrice   paymentInformation{  statusCode  method  paymentId  checkoutUrl}    orderContent  extraInformation  }  }}`
           }
           ,{
               headers: { 
                'Authorization':`Bearer ${token}`
                }
            }
        )
    .then(response => {
        // Handle success.
        //console('menu axios response', response);
        if(response.data.errors) return response.data;

        return response.data.data.restaurantDetails;
    });
    // console.log('responseRestaurantWithDineOutOrders.orders => ', responseRestaurantWithDineOutOrders.orders);

    // retrieve all the paid orders and the non paid one that have payment in cash for example and do filter the orders
    // that has been refunded
    let filterOnlyPaidDineOutOrders = []
    if(responseRestaurantWithDineInOrders && responseRestaurantWithDineOutOrders.orders && responseRestaurantWithDineOutOrders.orders.length > 0) {
        filterOnlyPaidDineOutOrders = responseRestaurantWithDineOutOrders.orders.filter((order) => {
            if (order.paymentInformation && order.paymentInformation.statusCode 
                && order.paymentInformation.statusCode === "paid" && !order.paymentInformation.refundId)
                return true;
    
            const listOfNonOnlinePaymentMethods = responseRestaurantWithDineOutOrders.paymentMethods.filter((pm) => !pm.isOnlinePaymentMethod);
            
            // if we have a collect order but with a payment in cash then we should display it in the admin panel orders dashboard
            if (order.paymentInformation && order.paymentInformation.statusCode && order.paymentInformation.statusCode === "open" &&
                (listOfNonOnlinePaymentMethods.filter((pm) => pm.code === order.paymentInformation.method)).length > 0 ) {
                    return true;
                }
    
            return false;
        });
    }

    // console.log('filterOnlyPaidDineOutOrders => ', filterOnlyPaidDineOutOrders);

    // we get the dine out order that are paid merged with the dine in orders
    if (responseRestaurantWithDineInOrders && responseRestaurantWithDineInOrders.orders && filterOnlyPaidDineOutOrders) {
        responseRestaurantWithDineInOrders.orders = responseRestaurantWithDineInOrders.orders.concat(filterOnlyPaidDineOutOrders)
    }

    // console.log('responseRestaurantWithDineInOrders.orders => ', responseRestaurantWithDineInOrders.orders);

    return responseRestaurantWithDineInOrders;
}

export async function sendPrintOrderCommand (token, orderToPrint) {
    // endpoint path
    const apiEndpointPath = '/sendordertoprinter';

    // Request API.
    return await axios
    .post( (/*env.REACT_APP_STRAPI_PROTOCOL || */process.env.REACT_APP_STRAPI_PROTOCOL || 'http') +
    '://' + (/*env.REACT_APP_STRAPI_HOST || */process.env.REACT_APP_STRAPI_HOST || 'localhost') +
     ":" + (/*env.REACT_APP_STRAPI_PORT || */process.env.REACT_APP_STRAPI_PORT || '1337') + apiEndpointPath + "/" + orderToPrint.uuid, 
           {}
           ,{
               headers: { 
                'Authorization':`Bearer ${token}`
                }
            }
        )
    .then(response => {
        // Handle success.
        //console('menu axios response', response);
        return response.data;
    });
}

export async function sendPoSSyncOrderCommand (token, orderToSyncWithPoS) {
    // endpoint path
    const apiEndpointPath = '/pushordertopos';

    // Request API.
    return await axios
    .post( (/*env.REACT_APP_STRAPI_PROTOCOL || */process.env.REACT_APP_STRAPI_PROTOCOL || 'http') +
    '://' + (/*env.REACT_APP_STRAPI_HOST || */process.env.REACT_APP_STRAPI_HOST || 'localhost') +
     ":" + (/*env.REACT_APP_STRAPI_PORT || */process.env.REACT_APP_STRAPI_PORT || '1337') + apiEndpointPath + "/" + orderToSyncWithPoS.id, 
           {}
           ,{
               headers: { 
                'Authorization':`Bearer ${token}`
                }
            }
        )
    .then(response => {
        // Handle success.
        //console('menu axios response', response);
        return response.data;
    });
}

export async function countOrdersInPoSForSpecificDate (token, restaurantId, day, restaurantTimezone) {
    // endpoint path
    const currentTimezoneOffset = moment.tz(restaurantTimezone)
    const currentOffsetNumber = currentTimezoneOffset.utcOffset()
    // console.log('currentOffsetNumber => ', currentOffsetNumber)
    const offsetStr = (`0${currentOffsetNumber / 60}`).slice(-2)
    // console.log('offsetStr => ', offsetStr)
    const startDatetimeString = day + 'T00:00:00.000+' + offsetStr
    const endDatetimeString = day + 'T23:59:59.000+' + offsetStr
    const localStartDatetimeString = moment(startDatetimeString).tz(restaurantTimezone).format();
    const localEndDatetimeString = moment(endDatetimeString).tz(restaurantTimezone).format();
    // console.log('local start datetime => ', localStartDatetimeString)
    // console.log('local end datetime => ', localEndDatetimeString)
    const utcStartDatetimeString = moment(localStartDatetimeString, null).utc().format();
    const utcEndDatetimeString = moment(localEndDatetimeString, null).utc().format();
    var qs = require('qs');
    const query = qs.stringify({
      _where: [
        { createdOn_gt: utcStartDatetimeString }, 
        { createdOn_lt: utcEndDatetimeString },
        { restaurant: restaurantId },
        { status: 'InPoS'}
      ],
    });
    // endpoint path
    const apiEndpointPath = '/orders/count';

    // Request API.
    return await axios
    .get( (/*env.REACT_APP_STRAPI_PROTOCOL || */process.env.REACT_APP_STRAPI_PROTOCOL || 'http') +
    '://' + (/*env.REACT_APP_STRAPI_HOST || */process.env.REACT_APP_STRAPI_HOST || 'localhost') +
     ":" + (/*env.REACT_APP_STRAPI_PORT || */process.env.REACT_APP_STRAPI_PORT || '1337') + apiEndpointPath + "?" + query,
              {
               headers: { 
                'Authorization':`Bearer ${token}`
                }
            }
        )
    .then(response => {
        // Handle success.
        return response.data;
    });
}

export async function updateOrderStatusProperty (token, order, previousOrderStatus) {
    // endpoint path
    const apiEndpointPath = '/orders';
    // init runtime env var
    //const env = runtimeEnv();
    const options = {"status": order.status};

    if(order.status === "Refunded" || order.status === "Rejected") {
        // console.log('updateOrderStatusProperty order => ', order);
        options.paymentInformation = order.paymentInformation;
    }
    // we only update the order accepted on date when the order is moved
    // from submitted state to accepted state
    if(order.status === "Accepted" && previousOrderStatus === "Submitted") {
        options.acceptedOn = new Date();
    }
    // we only update the order ready to serve on date when the order is moved
    // from accepted state to readyToServe state
    if(order.status === "ReadyToServe" && previousOrderStatus === "Accepted") {
        options.readyToBeServedOn = new Date();
    }
    // Request API.
    return await axios
    .put( (/*env.REACT_APP_STRAPI_PROTOCOL || */process.env.REACT_APP_STRAPI_PROTOCOL || 'http') +
    '://' + (/*env.REACT_APP_STRAPI_HOST || */process.env.REACT_APP_STRAPI_HOST || 'localhost') +
     ":" + (/*env.REACT_APP_STRAPI_PORT || */process.env.REACT_APP_STRAPI_PORT || '1337') + apiEndpointPath + "/" + order.id, 
           options
           ,{
               headers: { 
                'Authorization':`Bearer ${token}`
                }
            }
        )
    .then(response => {
        // Handle success.
        //console('menu axios response', response);
        return response.data;
    });
}