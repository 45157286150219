import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  Dropdown,
  NavItem,
  NavLink,
  //Badge,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  //UncontrolledButtonDropdown,
  //UncontrolledTooltip,
  //InputGroupAddon,
  //InputGroup,
  //Input,
  //Form,
  //FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Select from 'react-select';
import $ from "jquery";
import QRCode from "qrcode.react";
import axios from 'axios';

import { FormattedMessage, injectIntl } from "react-intl";
import LocaleSelector from "../../components/LocaleSelector";
//import Notifications from '../Notifications';
import {
  logoutUser,
  checkTokenExpiration,
  getUserMeInfo,
  switchOutlet
} from "../../actions/user";
import {
  toggleSidebar,
  openSidebar,
  closeSidebar,
  changeActiveSidebarItem,
} from "../../actions/navigation";
import { actionGetCollectionForRestaurantFromServer } from "../../actions/restaurantcollection";

import s from "./Header.module.scss"; // eslint-disable-line css-modules/no-unused-class

import { showErrorMessage } from "../../components/ToastMessenger";

import { PageView, GAEvent } from "../Tracking";

import AlertSound from "../../assets/audiofiles/alert.mp3";

const zeroPad = (num, places) => String(num).padStart(places, '0')

const getMobileOS = () => {
  const ua = navigator.userAgent
  if (/android/i.test(ua)) {
    return "Android"
  }
  else if ((/iPad|iPhone|iPod/.test(ua)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
    return "iOS"
  }
  return "Other"
}

//import runtimeEnv from '@mars/heroku-js-runtime-env';

//const env = runtimeEnv();

class Header extends React.Component {
  static propTypes = {
    sidebarOpened: PropTypes.bool.isRequired,
    sidebarStatic: PropTypes.bool.isRequired,
    chatToggle: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.toggleMenu = this.toggleMenu.bind(this);
    this.switchSidebar = this.switchSidebar.bind(this);
    this.toggleNotifications = this.toggleNotifications.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.doLogout = this.doLogout.bind(this);
    this.updateWindowWidth = this.updateWindowWidth.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.showMobileAppModal = this.showMobileAppModal.bind(this);
    this.getListOfRestaurantsForUsername = this.getListOfRestaurantsForUsername.bind(this);
    this.changeRestaurant = this.changeRestaurant.bind(this)
    this.download = this.download.bind(this);
    this.manualQRCodeUrlChanged = this.manualQRCodeUrlChanged.bind(this);

    this.state = {
      menuOpen: false,
      notificationsOpen: false,
      notificationsTabSelected: 1,
      userInfoObject: localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : {},
      windowWidth: window.innerWidth,
      isOpenMobileAppModal: false,
      listOfRestaurants:  [],
      currentRestaurant: {},
      isOrderAudioNotificationEnabled: false // we use this flag to detect if the browser user agent allows for audio autoplay
    };
  }

  download(elementId) {
    const canvas: any = document.getElementById(elementId).children[0];
    const link = document.createElement("a");
    link.href = canvas.toDataURL();
    link.download = elementId + ".png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  async componentDidMount() {
    $("#search-input").on("blur focus", (e) => {
      $("#search-input")
        .parents(".input-group")[e.type === "focus" ? "addClass" : "removeClass"]("focus");
    });

    // we add an event listener when resizing the window as for the profile selector we want to
    // be able to hide the dropdown arrow in a smaller screen
    window.addEventListener("resize", this.updateWindowWidth);

    // Enabling tracking with google analytics
    PageView();

    // we want to get all user information with restaurant(s) he is managing
    const token = localStorage.getItem("id_token");
    if (token) {
      const { dispatch } = this.props;
      dispatch(checkTokenExpiration());
      dispatch(getUserMeInfo());
    }
    if (this.state.listOfRestaurants.length === 0) {
      const userInfo = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : null;
      if (userInfo) {
        const listOfRestaurants = await this.getListOfRestaurantsForUsername(userInfo.email);
        const restaurantSelectedByDefaultForLoggedInUser = JSON.parse(localStorage.getItem('selectedRestaurant'));
        if (restaurantSelectedByDefaultForLoggedInUser) {
          this.setState({ listOfRestaurants, currentRestaurant: restaurantSelectedByDefaultForLoggedInUser });
        } else {
          this.setState({ listOfRestaurants });
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    // get user from localstorage and check if available which mean we haven't been
    // logged out then we can trigger an automatic logout
    const { dispatch } = this.props;
    const userInfo = localStorage.getItem("userInfo")  ? JSON.parse(localStorage.getItem("userInfo")) : null;
    // const lastLoggedInUsername = localStorage.getItem("lastLoggedInUsername");
    const idToken = localStorage.getItem("id_token");
    if (
      this.props.errorMessage === "Invalid token." &&
      !this.props.isUserFetching &&
      idToken &&
      userInfo // && !lastLoggedInUsername
    ) {
      // if we have an invalid token message then we log out the user
      // console.log("Checking token expiration");
      //this.props.dispatch(logoutUser());
      dispatch(checkTokenExpiration());
    }

    // get the list of restaurant collection in which the current restaurant is part of
    // in order to generate the mobile qr code
    //console.log('this.props.isRestaurantCollectionFetching => ', this.props.isRestaurantCollectionFetching);

    if (
      this.props.managedRestaurant &&
      !this.props.collectionsForRestaurant &&
      !this.props.isRestaurantCollectionFetching
    ) {
      // console.log('Header this.props.managedRestaurant.id => ', this.props.managedRestaurant.id);
      dispatch(
        actionGetCollectionForRestaurantFromServer(
          this.props.managedRestaurant.slug
        )
      );
    }

    /* console.log('this.props.managedRestaurant => ', this.props.managedRestaurant)
    console.log('prevProps.managedRestaurant => ', prevProps.managedRestaurant)
    console.log('this.props.isRestaurantCollectionFetching => ', this.props.isRestaurantCollectionFetching) */
    if (
      this.props.managedRestaurant && !prevProps.managedRestaurant && !this.props.isRestaurantCollectionFetching) {
        dispatch(
          actionGetCollectionForRestaurantFromServer(
            this.props.managedRestaurant.slug
          )
        );
      }

    // if there is any error message then we display it via a notification
    if (
      this.props.errorMessage !== undefined &&
      this.props.errorMessage !== "" &&
      prevProps.errorMessage !== this.props.errorMessage
    ) {
      showErrorMessage(this.props.errorMessage);
    }

    // tracking location path change with google analytics
    if (prevProps.location.pathname !== this.props.location.pathname) {
      PageView();
    }
  }

  updateWindowWidth() {
    this.setState({ windowWidth: window.innerWidth });
  }

  componentWillUnmount() {
    // when component in unmounted then we remove the listener on window resize
    window.removeEventListener("resize", this.updateWindowWidth);
  }

  toggleNotifications() {
    this.setState({
      notificationsOpen: !this.state.notificationsOpen,
    });
  }

  doLogout() {
    this.props.dispatch(logoutUser());
    if (this.props.websocket) {
      this.props.websocket.isForcedToClose = true;
      this.props.websocket.close();
    }
    GAEvent("ADMINPANEL", "Logout", "LOGOUT");
  }

  // collapse/uncolappse
  switchSidebar() {
    if (this.props.sidebarOpened) {
      this.props.dispatch(closeSidebar());
      this.props.dispatch(changeActiveSidebarItem(null));
    } else {
      const paths = this.props.location.pathname.split("/");
      paths.pop();
      this.props.dispatch(openSidebar());
      this.props.dispatch(changeActiveSidebarItem(paths.join("/")));
    }
  }

  // static/non-static
  toggleSidebar() {
    this.props.dispatch(toggleSidebar());
    if (this.props.sidebarStatic) {
      localStorage.setItem("staticSidebar", "false");
      this.props.dispatch(changeActiveSidebarItem(null));
    } else {
      localStorage.setItem("staticSidebar", "true");
      const paths = this.props.location.pathname.split("/");
      paths.pop();
      this.props.dispatch(changeActiveSidebarItem(paths.join("/")));
    }
  }

  toggleModal(id) {
    this.setState((prevState) => ({
      [id]: !prevState[id],
    }));
  }

  showMobileAppModal() {
    this.setState({
      isOpenMobileAppModal: true,
    });
    GAEvent(
      "ADMINPANEL",
      "Show mobile web app modal with QR codes",
      "SHOW_MOBILE_WEB_APP_QR_CODES_MODAL"
    );
  }

  toggleMenu() {
    this.setState({
      menuOpen: !this.state.menuOpen,
    });
  }

  manualQRCodeUrlChanged(event) {
    this.setState({
      manualQRCodeUrl: event.target.value,
    });
  }

  // username is the email
  async getListOfRestaurantsForUsername (userEmail) {
    // endpoint path
    const apiEndpointPath = '/restaurantsofcollaborator/' + userEmail;
    // init runtime env var
    //const env = runtimeEnv();
    // Request API.
    const response = await axios
    .get( (/*env.REACT_APP_STRAPI_PROTOCOL || */process.env.REACT_APP_STRAPI_PROTOCOL || 'http') +
    '://' + (/*env.REACT_APP_STRAPI_HOST || */process.env.REACT_APP_STRAPI_HOST || 'localhost') +
     ":" + (/*env.REACT_APP_STRAPI_PORT || */process.env.REACT_APP_STRAPI_PORT || '1337') + apiEndpointPath);
     return response.data
  }

  changeRestaurant(currentRestaurant, selectedIndex) {
    if (currentRestaurant) {
      localStorage.setItem('selectedRestaurant', JSON.stringify(currentRestaurant));
      this.setState({ currentRestaurant: Object.assign({}, currentRestaurant) });
      const { dispatch } = this.props;
      dispatch(switchOutlet())
    }
  }

  render() {
    const { firstName, lastName } = this.state.userInfoObject;
    const { url } = this.state.userInfoObject.profilePicture
      ? this.state.userInfoObject.profilePicture
      : { url: null };
    const { windowWidth } = this.state;
    const { locale } = this.props.intl;
    const { currentRestaurant, listOfRestaurants } = this.state;
    // console.log('listOfRestaurants => ', listOfRestaurants)

    // getting the Host mobile app url from environment variable
    const urlToHostMobileApp =
      /*env.REACT_APP_MOBILE_WEB_APP_HOST_URL || */ process.env
        .REACT_APP_MOBILE_WEB_APP_HOST_URL || "http://localhost:3001";

    let barCodesForRestaurantCollections = null;
    // generate the mobile app qr code for current restaurant that might be part
    // of a collection of restaurants like food courts
    //console.log('this.props.collectionsForRestaurant => ', this.props.collectionsForRestaurant);
    if (
      this.props.collectionsForRestaurant &&
      this.props.collectionsForRestaurant.length > 0
    ) {
      barCodesForRestaurantCollections = this.props.collectionsForRestaurant.map(
        (rc, index) => {
          return (
            <div key={"restaurantCollectionQRCode-" + rc.id + "-" + index}>
              <hr />
              <h2>{rc.name_i18n[locale]}</h2>
              <a
                href={`${urlToHostMobileApp}/#/app/dineout/restaurantcollection/${rc.slug}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <div id={"alacarte-qrcode-" + rc.slug + "-" + index} 
                    className="d-flex align-items-center justify-content-center my-2 position-relative">
                  <QRCode
                    size={280}
                    value={`${urlToHostMobileApp}/#/app/dineout/restaurantcollection/${rc.slug}`}
                  />
                </div>
              </a>
              <div style={{position: "relative", height: "60px"}}>
                <div style={{
                  margin: "0",
                  position: "absolute",
                  top: "50%",
                  height: "75px",
                  width: "280px",
                  msTransform: "translateY(-50%)",
                  transform: "translateY(-50%)",
                  left: "95px"
                }}>
                  <button style={{
                    height: "40px",
                  }} className="btn-info border-0 rounded w-100 py-2 text-center my-2 d-flex align-items-center justify-content-center align-self-center"
                    onClick={() => this.download("alacarte-qrcode-" + rc.slug + "-" + index)}>
                      Download QR-Code
                  </button>
                </div>
              </div>
            </div>
          );
        }
      );
    }
    //console.log('barCodesForRestaurantCollections => ', barCodesForRestaurantCollections);
    /*if (this.props.managedRestaurant && this.props.managedRestaurant.deliverymethods)
      console.log('this.props.managedRestaurant => ', this.props.managedRestaurant)*/

    // console.log('this.props in header => ', this.props)

    return (
      <div>
        <audio id="newOrderAlertSound" src={AlertSound} autoPlay muted />
        <Navbar className={`${s.root} d-print-none`}>
          <Nav>
            <NavItem>
              <NavLink
                className="d-md-down-none ml-5"
                id="toggleSidebar"
                onClick={this.toggleSidebar}
              >
                <i className="la la-bars" />
              </NavLink>
              {/*<UncontrolledTooltip placement="bottom" target="toggleSidebar">
              Turn on/off<br />sidebar<br />collapsing
            </UncontrolledTooltip>*/}
              <NavLink className="fs-lg d-lg-none" onClick={this.switchSidebar}>
                <span className="rounded rounded-lg bg-gray text-white d-md-none">
                  <i className="la la-bars" />
                </span>
                <i className="la la-bars ml-3 d-sm-down-none" />
              </NavLink>
            </NavItem>
          </Nav>

          {/*
          <Form className="d-sm-down-none ml-5" inline>
            <FormGroup>
              <InputGroup className="input-group-no-border">
                <InputGroupAddon addonType="prepend">
                  <i className="la la-search" />
                </InputGroupAddon>
                <Input id="search-input" placeholder="Search Dashboard" />
              </InputGroup>
            </FormGroup>
          </Form>*/}
          <div className={s.alacarteHeader}>
            <h2>alacarte</h2>
          </div>
          {
            listOfRestaurants.length > 1 ? (
            <div style={{marginLeft: "20px", width: "50%"}}>
              <Select
                className="basic-single"
                classNamePrefix="select"
                autosize={true}
                defaultValue={{label: zeroPad(currentRestaurant.id, 3) + " - " + currentRestaurant.name, value: currentRestaurant}}
                isDisabled={false}
                isLoading={false}
                isClearable={false}
                isRtl={false}
                isSearchable={true}
                name="restaurant"
                options={listOfRestaurants.map((restaurant) => {return {label: zeroPad(restaurant.id, 3) + " - " + restaurant.name, value: restaurant}})}
                onChange={(selectedOption) => this.changeRestaurant(selectedOption.value)}
              />
            </div>
            ) : null
          }
          {
            /*listOfRestaurants.length > 1 ? (
                <div style={{marginLeft: "20px"}}>
                  <UncontrolledButtonDropdown>
                    <DropdownToggle
                      caret color="default"
                      className="dropdown-toggle-split"
                    >
                      {zeroPad(currentRestaurant.id, 3) + " - " + currentRestaurant.name}&nbsp;&nbsp;
                    </DropdownToggle>
                    <DropdownMenu>
                      {listOfRestaurants.map((restaurant, index) =>
                        <DropdownItem key={restaurant.id} onClick={() => this.changeRestaurant(restaurant, index)}>
                          {zeroPad(restaurant.id, 3) + " - " + restaurant.name}
                        </DropdownItem>,
                      )}
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                </div>
            ) : null*/
          }
          {
            !this.state.isOrderAudioNotificationEnabled && getMobileOS() === "iOS" ? 
            (
              <Nav className="ml-auto">
                <Button style={{height: "35px", marginTop: "12px", backgroundColor: "yellow"}} onClick={() => {
                  const audioToPlay = document.getElementById('newOrderAlertSound')
                  audioToPlay.muted = false
                  audioToPlay.play()
                  this.setState({isOrderAudioNotificationEnabled: true})
                }}>Enable Audio Notification for New Orders</Button>
              </Nav>
            ) : null
          }
          <Nav className="ml-auto">
            <Dropdown nav isOpen={this.state.menuOpen} toggle={this.toggleMenu}>
              {/* This dropdown toggle is only displayed on larger screens using classname d-sm-down-none */}
              <DropdownToggle nav caret={windowWidth < 768 ? false : true}>
                <span className={`${s.avatar} thumb-sm float-left mr-2`}>
                  {/*<img className="rounded-circle" src={getProfilePictureThumbnailURL(url)}*/}
                  <img
                    className="rounded-circle"
                    src={url}
                    alt=""
                  />
                </span>
                <span className="small d-sm-down-none">
                  {firstName} <span className="fw-semi-bold">{lastName}</span>
                </span>
                {/*<span className="ml-1 circle bg-warning text-white fw-bold">13</span>*/}
              </DropdownToggle>
              <DropdownMenu
                id="userContextMenu"
                right
                className="super-colors"
                style={{ minWidth: "165px" }}
              >
                <DropdownItem onClick={this.showMobileAppModal}>
                  <i className={s.iconInContextMenu + " la la-qrcode"} />
                  &nbsp;
                  <FormattedMessage
                    id="Header.UserProfile.MobileApp"
                    defaultMessage="Mobile App"
                  />
                </DropdownItem>
                <Link to="/app/profile">
                  <DropdownItem>
                    <i className={s.iconInContextMenu + " la la-user"} />
                    &nbsp;
                    <FormattedMessage
                      id="Header.UserProfile.MyAccount"
                      defaultMessage="My Account"
                    />
                  </DropdownItem>
                </Link>
                <DropdownItem divider />
                <DropdownItem onClick={this.doLogout}>
                  <i className={s.iconInContextMenu + " la la-sign-out"} />
                  &nbsp;
                  <FormattedMessage
                    id="Header.UserProfile.Logout"
                    defaultMessage="Log Out"
                  />
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <div className={s.langSelectorHeader}>
              <LocaleSelector forceRefresh={true} isSSEModeEnabled={false} />
            </div>
          </Nav>
        </Navbar>
        {/* Modal used to display order with barcodes */}
        <Modal
          id="modalToDisplayMobileAppQRCode"
          isOpen={this.state.isOpenMobileAppModal}
          toggle={() => this.toggleModal("isOpenMobileAppModal")}
        >
          <ModalHeader toggle={() => this.toggleModal("isOpenMobileAppModal")}>
            <FormattedMessage
              id="Header.modal.mobileapp.qrcode.title"
              defaultMessage="Mobile app QR code"
            />
          </ModalHeader>
          <ModalBody className="bg-white" style={{ textAlign: "none" }}>
            {
              this.props.managedRestaurant &&
              this.props.managedRestaurant.deliverymethods && 
              this.props.managedRestaurant.deliverymethods.length > 0 ? (
              this.props.managedRestaurant.deliverymethods.map((deliveryMethod, index) => {
                return (
                  <div key={this.props.managedRestaurant.id + '-' + deliveryMethod.code}>
                    <h2>
                      {this.props.managedRestaurant.name}
                      <br />
                      <strong><u>{deliveryMethod.description_i18n[locale]}</u></strong>
                    </h2>
                    <a
                      href={`${urlToHostMobileApp}/#/app/${deliveryMethod.code}/restaurant/${this.props.managedRestaurant.slug}/menus`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                    <div id={"alacarte-qrcode-" + this.props.managedRestaurant.slug + "-" + deliveryMethod.code} 
                    className="d-flex align-items-center justify-content-center my-2 position-relative">
                      <QRCode
                        size={280}
                        value={`${urlToHostMobileApp}/#/app/${deliveryMethod.code}/restaurant/${this.props.managedRestaurant.slug}/menus`}
                      />
                    </div>
                    </a>
                    <div style={{position: "relative", height: "60px"}}>
                      <div style={{
                        margin: "0",
                        position: "absolute",
                        top: "50%",
                        height: "75px",
                        width: "280px",
                        msTransform: "translateY(-50%)",
                        transform: "translateY(-50%)",
                        left: "95px"
                      }}>
                        <button style={{
                          height: "40px",
                        }} className="btn-info border-0 rounded w-100 py-2 text-center my-2 d-flex align-items-center justify-content-center align-self-center"
                          onClick={() => this.download("alacarte-qrcode-" + this.props.managedRestaurant.slug + "-" + deliveryMethod.code)}>
                            Download QR-Code
                        </button>
                      </div>
                    </div>
                    {
                      index < this.props.managedRestaurant.deliverymethods.length - 1 ? (
                        <div><hr /></div>
                      ) : null
                    }
                  </div>
                )
              })
            ) : null}
            {this.props.collectionsForRestaurant &&
            this.props.collectionsForRestaurant.length > 0 ? (
              <div>{barCodesForRestaurantCollections}</div>
            ) : null}
            <div>
              <hr />
              <h2>Custom QR Code generator</h2>
              <div id="manual-qr-code"
                  className="d-flex align-items-center justify-content-center my-2 position-relative">
                <div className="form-group">
                  <textarea className="form-control no-border"
                    value={!this.state.manualQRCodeUrl ? "" : this.state.manualQRCodeUrl} 
                    onChange={this.manualQRCodeUrlChanged} type="text" 
                    placeholder="Type custom URL" />
                    <div>
                      {
                        this.state.manualQRCodeUrl ?
                        (
                          <div>
                            <a  
                            href={this.state.manualQRCodeUrl}
                            rel="noopener noreferrer"
                            target="_blank">
                              <div id={"alacarte-custom-url-qrcode"} 
                                className="d-flex align-items-center justify-content-center my-2 position-relative">
                                <QRCode
                                  size={280}
                                  value={this.state.manualQRCodeUrl}
                                />
                              </div>
                            </a>
                            <div style={{position: "relative", height: "60px"}}>
                              <div style={{
                                margin: "0",
                                position: "absolute",
                                top: "50%",
                                height: "75px",
                                width: "280px",
                                msTransform: "translateY(-50%)",
                                transform: "translateY(-50%)",
                              }}>
                                <button style={{
                                  height: "40px",
                                }} className="btn-info border-0 rounded w-100 py-2 text-center my-2 d-flex align-items-center justify-content-center align-self-center"
                                  onClick={() => this.download("alacarte-custom-url-qrcode")}>
                                    Download QR-Code
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : null
                      }
                    </div>
                </div>
              </div>
            </div> 
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => this.toggleModal("isOpenMobileAppModal")}
            >
              <FormattedMessage
                id="Header.modal.mobileapp.qrcode.close.label"
                defaultMessage="Close"
              />
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(store) {
  const globalErrorMessage =
    store.auth.errorMessage ||
    store.menu.errorMessage ||
    store.menuitem.errorMessage ||
    store.order.errorMessage;
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    sidebarStatic: store.navigation.sidebarStatic,
    isUserFetching: store.auth.isFetching,
    errorMessage: globalErrorMessage,
    managedRestaurant: store.auth.managedRestaurant,
    websocket: store.websocket.socket,
    collectionsForRestaurant:
      store.restaurantCollection.listOfRestaurantCollections,
    isRestaurantCollectionFetching: store.restaurantCollection.isFetching,
  };
}

export default injectIntl(withRouter(connect(mapStateToProps)(Header)));
