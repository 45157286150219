// SSE stands for server side event stream
//import runtimeEnv from '@mars/heroku-js-runtime-env';

export const SSE_CONNECTION_INIT = 'SSE_CONNECTION_INIT';
export const SSE_CONNECTION_SUCCESS = 'SSE_CONNECTION_SUCCESS';
export const SSE_CONNECTION_ERROR = 'SSE_CONNECTION_ERROR';
export const SSE_CONNECTION_CLOSED = 'SSE_CONNECTION_CLOSED';

export function initializeSSE() {
    return (dispatch) => {
      //const env = runtimeEnv();
      // set the socket.io endpoint
      const  endpoint = '//' + (/*env.REACT_APP_STRAPI_HOST || */process.env.REACT_APP_STRAPI_HOST || 'localhost') +
       ":" + (/*env.REACT_APP_STRAPI_PORT || */process.env.REACT_APP_STRAPI_PORT || '1337') +
       (/*env.REACT_APP_STRAPI_EVENTSTREAM_PATH || */process.env.REACT_APP_STRAPI_EVENTSTREAM_PATH || '/eventstream') ;
  
      const evtSource = new EventSource(endpoint);
  
      dispatch(evtSourceConnectionInit(evtSource));
  
      // listen to hello messages
      evtSource.onopen = function() {
        console.log("Welcome to alacarte real-time event stream.");
        dispatch(evtSourceConnectionSuccess());
      };

      evtSource.onerror = function(e) {
        console.log("EventSource failed.");
        //console.log(e);
        // in case the server closes the connection then we close
        // the event source obj on client side too
        //if(e.target) { e.target.close(); }
        dispatch(evtSourceConnectionError());  
      };
    };
  }

  export function closeSSE(eventSource) {
    return (dispatch) => {
      if (eventSource)
        eventSource.close();
      dispatch(evtSourceConnectionClosed());
    }
  }
  
  function evtSourceConnectionInit(evtSource) {
    return {
      type: SSE_CONNECTION_INIT,
      evtSource,
    };
  }
  
  function evtSourceConnectionSuccess() {
    return {
      type: SSE_CONNECTION_SUCCESS,
    };
  }
  
  function evtSourceConnectionError() {
    return {
      type: SSE_CONNECTION_ERROR,
    };
  }
  
  function evtSourceConnectionClosed() {
    return {
      type: SSE_CONNECTION_CLOSED,
    };
  }