import React from 'react';
import s from './ProgressIndicator.module.scss';

class ProgressIndicator extends React.PureComponent {

  render() {

    return (
      <div className={s.spinner}>
        <span>
          <i className="fa fa-spinner fa-5x fa-spin" style={{color: "lightgrey"}}></i>
          <img src="/favicon.png" className={s.alacarteSpinnerLogo} alt="Alacarte" />
        </span>
      </div>
    );
  }
}

export default ProgressIndicator;
