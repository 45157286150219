import React from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Label,
  Badge
} from 'reactstrap';

import {
  BootstrapTable,
  TableHeaderColumn,
} from 'react-bootstrap-table';

import {FormattedMessage} from 'react-intl';

import './AddongroupItemsTable.modules.scss';
import Properties from '../Properties/Properties';

class AddongroupItemsTable extends React.Component {

  static propTypes = {
    addongroupitems: PropTypes.array.isRequired,
    currency: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      addongroupitems: this.props.addongroupitems,
      currency: this.props.currency,
      locale: this.props.locale,
    };

    this.propertiesFormatter = this.propertiesFormatter.bind(this);
    this.pictureFormatter = this.pictureFormatter.bind(this);
  }

  propertiesFormatter (cell, row) {
    // console.log(row);

    const addongroupitem = row;
    const { allergens, foodtypes } = addongroupitem;
    const { locale } = this.props;

    return (
        <div>
          {
            ((allergens && allergens.length > 0) || (foodtypes && foodtypes.length > 0)) && locale ? (
              <Properties listOfAllergens={allergens} listOfFoodtypes={foodtypes} locale={locale} />
            ) : null
          }
        </div>
    );
  }

  pictureFormatter (cell, row) {
    if(row.pictures.length > 0) {
      return (
      <div className="pictureAddonitem" key={"pictureMenuItemSizeCell-" + row.id} >
        <img className="imageAddonitem"
          src={row.pictures[0].formats && row.pictures[0].formats.thumbnail ? row.pictures[0].formats.thumbnail.url : row.pictures[0].url}
          alt={row.name_i18n[this.props.locale]} />
      </div>
      );
    } else {
      return (<div></div>);
    }
  }

  render() {
    const options = {
      sizePerPage: 10,
      paginationSize: 3,
    };

    const {addongroupitems, currency, locale} = this.state;

    function priceFormatter(cell) {
      let price = cell
      if (price > 0) {
        if (currency.numberOfDecimals <= 2) {
          price = parseFloat(price.toFixed(currency.numberOfDecimals))
        } else {
          price = price.toFixed(currency.numberOfDecimals)
        }
      }
      return (
        <Badge color="primary">
          {currency.symbol} {price}
        </Badge>
      );
    }

    function nameFormatter (cell, row) {
      //console.log(row);
      return (
        <span>
          {row.name_i18n[locale]}
        </span>
      );
    }

    function preselectedFormatter(cell) {
      //console.log(cell);

      let inputForSwitcher = <Input type="checkbox" className="ios" disabled defaultChecked />;

      if(!cell)
        inputForSwitcher = <Input type="checkbox" className="ios"  disabled />;

      return (
          <Label className="switch">
            {inputForSwitcher}
            <i />
          </Label>
      );
    }

    return (
      <div>
        <BootstrapTable data={addongroupitems}
                        version="4" 
                        options={options} 
                        tableContainerClass={`table-striped table-hover`}
                        onClickCell={this.displayModalPopup}>
          <TableHeaderColumn className="d-none" columnClassName="d-none" dataField="id" isKey>
            <span className="fs-sm">ID</span>
          </TableHeaderColumn>
          <TableHeaderColumn dataField="name"
                            className="d-none d-md-table-cell colTenPercentWidth"
                            columnClassName="d-none d-md-table-cell colTenPercentWidth"
                            dataFormat={this.pictureFormatter}>
          </TableHeaderColumn>
          <TableHeaderColumn dataField="name" 
                            className="colThirtyPercentWidth"
                            columnClassName="colThirtyPercentWidth"
                            dataFormat={nameFormatter}>
            <span className="fs-sm">
              <FormattedMessage id="AddongroupItemTable.BootstrapTable.TableHeaderColumn.name.label"
                                              defaultMessage="Name" />
            </span>
          </TableHeaderColumn>
          <TableHeaderColumn dataField="name" 
                            className="d-none d-md-table-cell colTwentyFivePercentWidth"
                            columnClassName="d-none d-md-table-cell colTwentyFivePercentWidth"
                            dataFormat={this.propertiesFormatter}>
            <span className="fs-sm">
              <FormattedMessage id="AddongroupItemTable.BootstrapTable.TableHeaderColumn.properties.label"
                                              defaultMessage="Properties" />
            </span>
          </TableHeaderColumn>
          <TableHeaderColumn dataField="price" 
                            className="colThirtyPercentWidth"
                            columnClassName="colThirtyPercentWidth"
                            dataFormat={priceFormatter}>
            <span className="fs-sm">
              <FormattedMessage id="AddongroupItemTable.BootstrapTable.TableHeaderColumn.price.label"
                                                defaultMessage="Price" />
            </span>
          </TableHeaderColumn>
          <TableHeaderColumn dataField="isPreselected"
                            className="colThirtyPercentWidth"
                            columnClassName="colThirtyPercentWidth"
                            dataFormat={preselectedFormatter}>
            <span className="fs-sm">
              <FormattedMessage id="AddongroupItemTable.BootstrapTable.TableHeaderColumn.ispreselected.label"
                                                  defaultMessage="Preselected?" />
            </span>
          </TableHeaderColumn>
          <TableHeaderColumn dataField="posReferenceId"
                            className="colThirtyPercentWidth"
                            columnClassName="colThirtyPercentWidth">
            <span className="fs-sm">
              <FormattedMessage id="AddongroupItemTable.BootstrapTable.TableHeaderColumn.posreferenceid.label"
                                                  defaultMessage="POS ref. ID" />
            </span>
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }

}

export default AddongroupItemsTable;
